import React, { useState } from "react";
import "./Testimonials.scss";
import { Avatar } from 'primereact/avatar';
import { Rating } from 'primereact/rating';
import { Link } from 'react-router-dom';
// import image1 from '../../../Assets/Home/Testimonials/reviewer1.jpg';
// import image2 from '../../../Assets/Home/Testimonials/reviewer2.jpg';
// import image3 from '../../../Assets/Home/Testimonials/reviewer3.jpg';
// import image4 from '../../../Assets/Home/Testimonials/reviewer4.jpg';


const Testimonials = () => {
    const [value, setValue] = useState(null);

    const testimonialsData = [
        {
            name: 'Corey Nakamura',
            role: 'Principal',
            rating: 5,
            title: 'Fantastic Experience',
            
            message: 'This is different, something I never learned in my entire career in education.',
            videoUrl: 'https://www.youtube.com/embed/CGRw_M0uC4A?feature=shared',
            // imageUrl: image1
          },
          {
            name: 'Leila Kobayashi',
            role: 'Principal',
            rating: 5,
            title: 'Very insightful and helpful!',
            message: 'By attending the Conference, I now have a better understanding of Laulama and what we have been doing this past year.',
            videoUrl: 'https://www.youtube.com/embed/KJSD6LFErgE?feature=shared',
            // imageUrl: image2
          },
          {
            name: 'Tayler Kanoho',
            role: 'Teacher',
            rating: 5,
            title: 'The best experience!',
            message: 'I now know what my issue was that caused the results for my class and what I need to change to improve.',
            videoUrl: 'https://www.youtube.com/embed/GZzFoBpNL4k?feature=shared',
            // imageUrl: image3
          },
          {
            name: 'Corey Nakamura',
            role: 'Principal',
            rating: 5,
            title: 'The best experience!',
            message: 'The good thing is that what we learn at one school can be used everywhere, it eliminates duplication.',
            videoUrl: 'https://www.youtube.com/embed/GZzFoBpNL4k?feature=shared',
            // imageUrl: image4
          },
    ];


    return (
        <>
            <div className="lt-testimonials">
                <div className="lt-title-section grid">
                    <div className="col-6 lt-title">
                        <div className="lt-main-title">Testimonials</div>
                    </div>
                </div>
                <div className="lt-reviews grid">
                    {testimonialsData.map(testimonial => (
                        <div className="col-12 md:col-6 lg:col-6 xl:col-3">
                            <div className="lt-review">
                                <div className="flex flex-wrap">
                                    <div>
                                        <Avatar
                                            icon="pi pi-user"
                                            size="large"
                                            shape="circle"
                                        />
                                    </div>
                                    <div>
                                        <div className="lt-s-title">{testimonial.name}</div>
                                        <div className="lt-s-subtitle">{testimonial.role}</div>
                                    </div>
                                </div>
                                <div className="lt-review-title">{testimonial.title}</div>
                                <div className="lt-review-desc">{testimonial.message}</div>
                                <div><Link to="/stories">Read More</Link></div>
                                <div className="r-ratings">
                                    <Rating value={parseFloat(testimonial.rating.toFixed(1))} readOnly stars={5} cancel={false} precision={0.5} className="testimonial-rating" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </>
    );
};

export default Testimonials;
