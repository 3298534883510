import React, { useState, useEffect } from "react";
import "./../student-GR-card/student-GR-card.scss";
import { Card } from 'primereact/card';
import 'react-circular-progressbar/dist/styles.css';
import { Link } from "react-router-dom";
import { Divider } from "primereact/divider";
import { Panel } from 'primereact/panel';

const StudentCard = (props) => {
    const [showGraduation, setShowGraduation] = useState(window.innerWidth <= 1008);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
    }, [showMore]);
    const toggleShowMore = () => {
        setShowMore((prevShowMore) => !prevShowMore);
    };

    useEffect(() => {
        const handleResize = () => {
            setShowGraduation(window.innerWidth <= 1008);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (

        <div className="Student-GR-card-container">
            <div className="pb-0">
                {showGraduation ? (

                    <div>
                        <div className="w-full">
                            <div className="progressbars-section flex align-items-center justify-content-center pb-0">
                                <div className="p-bars ">
                                    <div>
                                        <div className="p-2 px-0 font-bold">You Science Results : </div>

                                        <div className="grid">
                                            <div className="p-2 tab-course-section">
                                                <div className="font-bold sub-heading">Core Drivers & Aptitudes:</div>
                                                {Array.isArray(props.coreDrivers) && props.coreDrivers.length > 0 ? (
                                                    props.coreDrivers.map((driver, index) => (
                                                        <div key={index} className="course py-1">{driver}</div>
                                                    ))
                                                ) : (
                                                    <div className="course py-1">-</div>
                                                )}
                                            </div>
                                        </div>
                                        
                                        <div className="grid">
                                            <div className="p-2 tab-course-section">
                                                <div className="font-bold sub-heading">Strongest Core Drivers & Aptitudes:</div>
                                                <div className="course py-1">{props.aptitudes ? props.aptitudes : "-"}</div>
                                            </div>
                                        </div>

                                        <div className="grid">
                                            <div className="p-2 tab-course-section">
                                                <div className="font-bold sub-heading">RIASEC Results:</div>
                                                <div className="course py-1">{props.riasecResults ? props.riasecResults : "-"}</div>
                                            </div>
                                        </div>
                                    </div>

                                    {showMore && (
                                        <>
                                            <div>
                                                <div className="grid">
                                                    <div className="p-2 tab-course-section">
                                                        <div className="font-bold sub-heading">Career Goal:</div>
                                                        <div className="course py-1">{props.careerGoals ? props.careerGoals : "-"}</div>
                                                    </div>
                                                </div>

                                                <div className="grid">
                                                    <div className="p-2 tab-course-section">
                                                        <div className="font-bold sub-heading">Educational Goal:</div>
                                                        <div className="course py-1">{props.educationalGoal ? props.educationalGoal : "-"}</div>
                                                    </div>
                                                </div>

                                                <div className="grid">
                                                    <div className="p-2 tab-course-section">
                                                        <div className="font-bold sub-heading">Cumulative GPA Goal:</div>
                                                        <div className="course py-1">{props.academiCGPAGoals ? props.academiCGPAGoals : "-"}</div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="pb-0">
                                                <div className="grid">
                                                    <div className="p-2 tab-course-section">
                                                        <div className="font-bold sub-heading">Honors Recognition Certificate: </div>
                                                        <div className="course">{props.honorsRecognitionCertificate ? props.honorsRecognitionCertificate : "-"}</div>
                                                    </div>
                                                </div>

                                                <div className="grid">
                                                    <div className="p-2 tab-course-section ">
                                                        <div className="font-bold sub-heading">After High School Plan:</div>
                                                        <div className="course">{props.highSchoolAcademicGoals ? props.highSchoolAcademicGoals : "-"}</div>
                                                    </div>
                                                </div>

                                                <div className="grid">
                                                    <div className="p-2 tab-course-section">
                                                        <div className="font-bold sub-heading">Academy Pathway:</div>
                                                        <div className="course">{props.academiesCategoryName ? props.academiesCategoryName : "-"}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="view-button">
                            {showMore ? (
                                <Link onClick={toggleShowMore} style={{ color: '#1067B8', fontSize: '16px' }}>View Less</Link>
                            ) : (
                                <Link onClick={toggleShowMore} style={{ color: '#1067B8', fontSize: '16px' }}>View More</Link>
                            )}
                        </div>
                    </div>

                ) : (

                    <Card className="pb-0">
                        <div className="grid pb-0">
                            <div className="progressbars-section col-12 pb-0">
                                <div className="p-bars grid pb-0">

                                    <div className="col-4 pb-0">
                                        <div className="grid">
                                            <div className="col p-2">
                                                <div className="font-bold">You Science Results : </div>
                                                <div className="font-bold">Strongest Core Drivers:</div>
                                                <div className="course py-1">{props.coreDrivers ? props.coreDrivers : "-"}</div>

                                                <div className="font-bold">Strongest Aptitudes:</div>
                                                <div className="course py-1">{props.aptitudes ? props.aptitudes : "-"}</div>
                                            </div>
                                        </div>
                                        <div className="grid">
                                            <div className="col p-2">
                                                <div className="font-bold">RIASEC Results:</div>
                                                <div className="course py-1">{props.riasecResults ? props.riasecResults : "-"}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="grid">
                                            <div className="col p-2">
                                                <div className="font-bold">Career Goal:</div>
                                                <div className="course py-1">{props.careerGoals ? props.careerGoals : "-"}</div>
                                            </div>
                                        </div>
                                        <div className="grid">
                                            <div className="col p-2">
                                                <div className="font-bold">Educational Goal:</div>
                                                <div className="course py-1">{props.educationalGoal ? props.educationalGoal : "-"}</div>
                                            </div>
                                        </div>
                                        <div className="grid">
                                            <div className="col p-2">
                                                <div className="font-bold">Cumulative GPA Goal:</div>
                                                <div className="course py-1">{props.academiCGPAGoals ? props.academiCGPAGoals : "-"}</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-4 pb-0">
                                        <div className="grid">
                                            <div className="col p-2">
                                                <div className="font-bold">Honors Recognition Certificate: </div>
                                                <div className="course py-1">{props.honorsRecognitionCertificate ? props.honorsRecognitionCertificate : "-"}</div>
                                            </div>
                                        </div>
                                        <div className="grid">
                                            <div className="col p-2">
                                                <div className="font-bold">After High School Plan:</div>
                                                <div className="course py-1">{props.highSchoolAcademicGoals ? props.highSchoolAcademicGoals : "-"}</div>
                                            </div>
                                        </div>
                                        <div className="grid">
                                            <div className="col p-2 pb-0">
                                                <div className="font-bold">Academy Pathway:</div>
                                                <div className="course py-1">{props.academiesCategoryName ? props.academiesCategoryName : "-"}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                )}
            </div>
        </div>

    );
}

export default StudentCard;
