import { useRef, useState, useEffect } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import './StackNavBar.scss';
import { FaChevronDown, FaUserCircle } from 'react-icons/fa';
import logoOpen from '../../Assets/Logo/LOGO.svg';
import axios from 'axios';
import { CONTENT_HEADERS, LOGOUT_URL } from '../../Utils/LoginService';
import { Divider } from 'primereact/divider';
import { useNavigate } from 'react-router-dom';
import { faCircleQuestion, faCircleUser, faHouse, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import maleStudentAvatar from '../../Assets/Profile edit/Profile image/Default/Man.png';
import femaleStudentAvatar from '../../Assets/Profile edit/Profile image/Default/Woman.png';
import { GET_STUDENT_DETAILS } from '../../Utils/StudentService';

const NavBar = ({ activeMenuItem, onMenuItemClick, userRole, name }) => {
    const [gender, setGender] = useState();
    const [profileAvatar, setProfileAvatar] = useState();
    const [profileAvatarBackground, setProfileAvatarBackground] = useState('#B9C4E1');
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });
    let menuItems = [];

    useEffect(() => {
        fetchDetails();

    }, []);

    const fetchDetails = () => {
        setOpacityStyle({ opacity: 0.5 });
        axios
            .get(GET_STUDENT_DETAILS, { headers: CONTENT_HEADERS })
            .then((response) => {
                setGender(response?.data?.gender);
                setOpacityStyle({ opacity: 1, background: 'transparent' });
                setProfileAvatar(response?.data?.profileImage);
                setProfileAvatarBackground(response?.data?.imageColourCode);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    switch (userRole) {
        case 'Admin':
            menuItems = [
                { id: 'dashboard', label: 'My Dashboard' },
                { id: 'principals', label: 'Principals' },
                { id: 'teachers', label: 'Teachers' },
                { id: 'groups', label: 'Groups' },
                { id: 'students', label: 'Students' }
            ];
            break;
        case 'Student':
            menuItems = [
                { id: 'dashboard', label: 'My Dashboard', icon: faHouse },
                { id: "profile", label: "My Profile", icon: faUser }
            ];
            break;
        case 'Teacher':
            menuItems = [
                { id: 'dashboard', label: 'My Dashboard' },
                { id: 'students', label: 'My Students' }
            ];
            break;
        case 'Principal':
            menuItems = [
                { id: 'dashboard', label: 'My Dashboard' },
                { id: 'groups', label: 'Groups' },
                { id: 'students', label: 'My Students' },

            ];
            break;
        default:
            break;
    }

    let profileItems = [];

    switch (userRole) {
        case 'Admin':
            profileItems = [
                { label: 'Help', icon: 'pi pi-question-circle', command: () => handleMenuItemClick('help') },
                { label: 'Logout', icon: 'pi pi-sign-out', command: () => handleLogout('logout') },
            ]
            break;
        case 'Student':
            profileItems = [
                { label: 'Help', icon: 'pi pi-question-circle', command: () => handleMenuItemClick('help') },
                // { label: 'ResumeBuilder', icon: 'pi pi-question-circle', command: () => handleMenuItemClick('ResumeBuilder') },
                { label: 'Logout', icon: 'pi pi-sign-out', command: () => handleLogout('logout') },
            ];
            break;
        case 'Teacher':
            profileItems = [
                { label: 'Help', icon: 'pi pi-question-circle', command: () => handleMenuItemClick('help') },
                { label: 'Logout', icon: 'pi pi-sign-out', command: () => handleLogout('logout') },
            ];
            break;
        case 'Principal':
            profileItems = [
                { label: 'Help', icon: 'pi pi-question-circle', command: () => handleMenuItemClick('help') },
                { label: 'Logout', icon: 'pi pi-sign-out', command: () => handleLogout('logout') },
            ];
            break;
        default:
            break;
    }


    const [showMenu, setShowMenu] = useState(false);
    const [showRightContent, setShowRightContent] = useState(window.innerWidth >= 1008);
    const overlayPanelRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const navigate = useNavigate()

    const showProfileMenu = (event) => {
        setShowMenu(!showMenu);
        overlayPanelRef.current.toggle(event);
        // document.body.style.overflow = 'hidden';
    };

    const handleLogout = (item) => {
        overlayPanelRef.current.hide();
    };

    const handleMenuItemClick = (item) => {
        if (item === 'Logout') {
            axios
                .get(LOGOUT_URL, { headers: CONTENT_HEADERS })
                .then(() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.href = '/';
                })
        }
        else if (item === 'Help') {
            switch (userRole) {
                case 'Admin':
                    navigate('/admin/help');
                    break;
                case 'Student':
                    navigate('/student/help/laulamaGuides');
                    break;
                case 'Teacher':
                    navigate('/teacher/help');
                    break;
                case 'Principal':
                    navigate('/principal/help');
                    break;
                default:
                    break;
            }
            overlayPanelRef.current.hide();
        }
        // else if (item === 'ResumeBuilder') {
        //     navigate('/student/resumebuilder');
        //     overlayPanelRef.current.hide();
        // };
    }

    const handleLogoClick = () => {
        let dashboardRoute = '/dashboard';

        // Add condition based on userRole
        switch (userRole) {
            case 'Admin':
                dashboardRoute = '/admin/dashboard';
                break;
            case 'Teacher':
                dashboardRoute = '/teacher/dashboard';
                break;
            case 'Student':
                dashboardRoute = '/student/dashboard';
                break;
            case 'Principal':
                dashboardRoute = '/principal/dashboard';
                break;
            // Add more cases if needed
            default:
                break;
        }

        navigate(dashboardRoute);
    };

    const toggleRightContent = () => {
        setShowRightContent(!showRightContent);
        setMenuOpen(!menuOpen);
    };

    const handleClick = () => {
        setShowRightContent(false);
        setMenuOpen(!menuOpen);
    }

    const handlemoblogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/';
    }

    window.addEventListener('resize', () => {
        setShowRightContent(window.innerWidth >= 1009);
    });

    const getStudentAvatar = () => {
        if (!profileAvatar) {
            if (gender === 'Male' || gender === 'M') {
                return <img src={maleStudentAvatar} alt="Male Avatar" width={40} height={40} style={{ background: '#B9C4E1', borderRadius: '50%', padding: '5px' }} />;
            } else if (gender === 'Female' || gender === 'F') {
                return <img src={femaleStudentAvatar} alt="Female Avatar" width={40} height={40} style={{ background: '#B9C4E1', borderRadius: '50%', padding: '5px' }} />;
            }
        } else {
            return <img src={profileAvatar} alt="Student Avatar" width={40} height={40}
                style={{ background: profileAvatarBackground || '#B9C4E1', borderRadius: '50%', padding: '5px' }} />;
        }
    };

 

    return (
        <div className="container">
            <div className="header ">
                <div className="stack-navbar">
                    <div className="logo" onClick={handleLogoClick}>
                        <img src={logoOpen} alt="" />
                    </div>
                    <div className="menu-container">
                        {window.innerWidth < 1008 && (

                            <div
                                className={`menu-toggle ${showRightContent ? "active" : ""}`}
                                onClick={toggleRightContent}
                            >
                                <div className={`bar ${showRightContent ? "cross" : ""}`} ></div>
                                <div className={`bar ${showRightContent ? "cross" : ""}`}></div>
                                <div className={`bar ${showRightContent ? "cross" : ""}`}></div>
                            </div>
                        )}
                        {showRightContent && (
                            <ul className={`menu-items ${showMenu ? 'show' : ''}`}>
                                {menuItems.map((item) => (
                                    <li
                                        key={item.id}
                                        className={activeMenuItem === item.id ? 'active' : ''}
                                        onClick={() => onMenuItemClick(item.id)}
                                    >
                                        {item.label}
                                    </li>
                                ))}
                                <div>
                                    <div className='profileIcon' onClick={(e) => showProfileMenu(e)}>
                                        {userRole === 'Student' ? (
                                            <div className='flex align-items-center flex-wrap'>
                                                {getStudentAvatar()}
                                                <FaChevronDown className='ml-1' />
                                            </div>
                                        ) : (
                                            <>
                                                <FaUserCircle />
                                                <FaChevronDown />
                                            </>
                                        )}
                                    </div>
                                    <OverlayPanel
                                        ref={overlayPanelRef}
                                        appendTo={document.body}

                                        // style={{ overflow: 'hidden' }}
                                        onHide={() => setShowMenu(false)}
                                    >
                                        <ul className="profile-menu-list">
                                            {profileItems.map((item) => (
                                                <li
                                                    key={item.label}
                                                    onClick={() => handleMenuItemClick(item.label)}
                                                >
                                                    <i className={`pi ${item.icon}`}></i>
                                                    {item.label}
                                                </li>
                                            ))}
                                        </ul>
                                    </OverlayPanel>
                                </div>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
            {showRightContent && (
                <div className="right-section">
                    <div className="right-content">
                        <div className='sec'>
                            <div className='content'>
                                <div className="avatar flex">
                                    <div className="p-3 pl-2">
                                        {userRole === 'Student' ? (
                                            <div className='flex align-items-center flex-wrap'>
                                                {getStudentAvatar()}
                                            </div>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={faCircleUser} style={{ fontSize: "40px" }} />
                                            </>
                                        )}
                                    </div>
                                    <div className=''>
                                        <p className='m-0'> Hello! </p>
                                        <span>{name}</span>
                                    </div>
                                </div>
                                <Divider />

                                <div className='px-3 mobile-menu-items'>
                                    {menuItems.map((item) => (
                                        <>
                                            <li
                                                key={item.id}
                                                className={activeMenuItem === item.id ? 'active' : ''}
                                                onClick={() => onMenuItemClick(item.id) || handleClick()}
                                            >
                                                <FontAwesomeIcon icon={item.icon} />
                                                {item.label}
                                            </li>

                                        </>
                                    ))}

                                    <li onClick={() => handleMenuItemClick('Help')}> <FontAwesomeIcon icon={faCircleQuestion} /> Help </li>

                                    <li onClick={handlemoblogout} ><FontAwesomeIcon icon={faRightFromBracket} /> Log out</li>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NavBar;
