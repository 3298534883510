import React, { useState, useEffect } from "react";
import "./ACT-Scores.scss";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import Illustration from "../../../../../../Assets/Student/tableilustration.svg";
import { Image } from "primereact/image";
import { RadioButton } from "primereact/radiobutton";
import moment from "moment";
import { InputNumber } from "primereact/inputnumber";
import ErrorMessage from "../../../../../../Shared-Component/ErrorMessage/ErrorMessage";

const ACT_Scores = ({ getApiResponse }) => {
  
  const [toggleForm, setToggleForm] = useState(false);
  const [actNewData, setActNewData] = useState([]);
  const [error, setError] = useState("");
  const [editIndex, setEditIndex] = useState(null);
   const actData = {
    date: "",
    composite: "",
    english: "",
    math: "",
    reading: "",
    science: "",
    writing: "",
  };

  const [actformData, setActFormData] = useState(actData);

  const actLocalStorageKey = "ACT_Scores";

  useEffect(() => {
    if (getApiResponse?.actScores?.actScoresVersion.length > 0) {
      const response = getApiResponse?.actScores?.actScoresVersion;
      const latestVersionNumber = ((response.length) - 1);
      const act = getApiResponse?.actScores.actScoresVersion[latestVersionNumber];
      if (act?.actScores.length > 0) {
        const actScores = act.actScores.map((item) => {
          var val = item.date;
          let formattedDate = "-";
          if (val && val !== "Invalid date" && val > 0) {
          const date = new Date(val);
          const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
          const year = date.getFullYear().toString().slice(2);
          formattedDate =  `${month}/${year}`;
          }
          return {
            date: formattedDate,
            composite: item?.composite,
            english: item?.english,
            math: item?.math,
            reading: item?.reading,
            science: item?.science,
            writing: item?.writing,
          };
        });
        setActNewData(actScores);
        localStorage.setItem(actLocalStorageKey, JSON.stringify(actScores));
      }
    }
  }, [getApiResponse]);

  useEffect(() => {
    const actStoredData = localStorage.getItem(actLocalStorageKey);
    if (actStoredData) {
      setActNewData(JSON.parse(actStoredData));
    }
  }, []);

  const [errorComposite, setErrorComposite] = useState("");
  const [errorEnglish, setErrorEnglish] = useState("");
  const [errorMath, setErrorMath] = useState("");
  const [errorReading, setErrorReading] = useState("");
  const [errorScience, setErrorScience] = useState("");
  const [errorWriting, setErrorWriting] = useState("");
  // const [errorActDate, setErrorActDate] = useState("");

  const handleActChange = (event) => {
    const { name, value } = event.target;

    const intValue = parseInt(value, 10);

    switch (name) {
      // case "date":
      //   if (!value) {
      //     setErrorActDate("Date field is required");
      //   } else {
      //     setErrorActDate("");
      //   }
      //   break;
      case "composite":
        if (intValue < 1 || intValue > 36) {
          setErrorComposite("The range must be 1-36");
        } else {
          setErrorComposite("");
        }
        break;
      case "english":
        if (intValue < 1 || intValue > 36) {
          setErrorEnglish("The range must be 1-36");
        } else {
          setErrorEnglish("");
        }
        break;
      case "math":
        if (intValue < 1 || intValue > 36) {
          setErrorMath("The range must be 1-36");
        } else {
          setErrorMath("");
        }
        break;
      case "reading":
        if (intValue < 1 || intValue > 36) {
          setErrorReading("The range must be 1-36");
        } else {
          setErrorReading("");
        }
        break;
      case "science":
        if (intValue < 1 || intValue > 36) {
          setErrorScience("The range must be 1-36");
        } else {
          setErrorScience("");
        }
        break;
      case "writing":
        if (intValue < 1 || intValue > 36) {
          setErrorWriting("The range must be 1-36");
        } else {
          setErrorWriting("");
        }
        break;
      default:
        break;
    }

    setActFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleActSubmit = (e) => {
    e.preventDefault();
    const fieldLabelMap = {
      // date: "Date",
      composite: "Composite",
      english: "English",
      math: "Math",
      reading: "Reading",
      science: "Science",
      writing: "Writing",
    };

    const setError = (field, message) => {
      switch (field) {
        // case "date":
        //   setErrorActDate(message);
        //   break;
        case "composite":
          setErrorComposite(message);
          break;
        case "english":
          setErrorEnglish(message);
          break;
        case "math":
          setErrorMath(message);
          break;
        case "reading":
          setErrorReading(message);
          break;
        case "science":
          setErrorScience(message);
          break;
        case "writing":
          setErrorWriting(message);
          break;
        default:
      }
    };
    const emessage = "This field is required";
    const errors = Object.entries(fieldLabelMap).reduce(
      (acc, [field, label]) => {
        if (!actformData[field]) {
          acc[field] = emessage;
        }
        return acc;
      },
      {}
    );

    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([field, message]) => {
        setError(field, message);
      });
      return;
    }

    if (
      // !actformData.date ||
      !actformData.composite ||
      !actformData.english ||
      !actformData.math ||
      !actformData.reading ||
      !actformData.science ||
      !actformData.writing
    ) {
      setError("");
    } else if (
      // errorActDate ||
      errorComposite ||
      errorEnglish ||
      errorMath ||
      errorReading ||
      errorScience ||
      errorWriting
    ) {
      setError("");
    } else {
      var selected_date = moment.utc(actformData.date).format("MM/YYYY");
      const newActTableData = {
        date: selected_date,
        composite: actformData.composite,
        english: actformData.english,
        math: actformData.math,
        reading: actformData.reading,
        science: actformData.science,
        writing: actformData.writing,
      };

      if (editIndex !== null) {
        const updatedData = [...actNewData];
        var selected_date = moment.utc(actformData.date).format("MM/YYYY");
        const editedActFormData = {
          date: selected_date,
          composite: actformData.composite,
          english: actformData.english,
          math: actformData.math,
          reading: actformData.reading,
          science: actformData.science,
          writing: actformData.writing,
        };
        updatedData[editIndex] = editedActFormData;
        setActNewData(updatedData);
        setEditIndex(null);
        setActFormData(actData);
        localStorage.setItem(actLocalStorageKey, JSON.stringify(updatedData));
      } else {
        const updatedActFormData = [...actNewData, newActTableData];

        setActFormData(actData);

        localStorage.setItem(
          actLocalStorageKey,
          JSON.stringify(updatedActFormData)
        );
      }

      const actStoredData = localStorage.getItem(actLocalStorageKey);
      if (actStoredData) {
        setActNewData(JSON.parse(actStoredData));
      }
      handleClick();
    }
  };

  const handleClick = () => {
    setEditIndex(null);
    setActFormData(actData);
    setToggleForm(!toggleForm);
  };

  const handleEdit = (rowData, index) => {
    setToggleForm(true);
    const dateValue = rowData.date;
    const [month, year] = dateValue.split('/');
    const formattedDate = new Date(`${month}/01/${year}`);
    setActFormData({ ...rowData, date: formattedDate });
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    const updatedData = [...actNewData];
    updatedData.splice(index, 1);
    setActNewData(updatedData);
    setEditIndex(null);
    localStorage.setItem(actLocalStorageKey, JSON.stringify(updatedData));
    setActFormData(actData);
  };

  return (
    <div>
      <div className="extra-activities-section">
        {toggleForm ? (
          <div className="extra-heading">
            <form onSubmit={handleActSubmit}>
              {error && <ErrorMessage message={error} isSuccess={true} />}
              <div className="grid act-container">
                <div className="act-label col-4 flex flex-column gap-2">
                  <label>Date(MM/YYYY)</label>
                  <Calendar
                    name="date"
                    value={actformData.date}
                    view="month"
                    dateFormat="mm/yy"
                    onChange={handleActChange}
                    showIcon
                    className={`w-full md:w-18rem 
                    // $
                    // {
                    //   errorActDate ? "error" : ""
                    // }
                    `}
                  />
                  <span className="date-optional">*Optional</span>
                  {/* {errorActDate && (
                    <span
                      className="error-message flex justify-content-center align-items-center text-base"
                      style={{ color: "#E04A4A" }}
                    >
                      {errorActDate}
                    </span>
                  )} */}
                </div>
                <div className="form-content col-8 flex flex-column gap-2 mt-5">
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act w-full md:w-14rem mr-1">
                      Composite
                    </label>
                    <InputNumber
                      name="composite"
                      value={actformData.composite}
                      onValueChange={handleActChange}
                      keyfilter="num"
                      placeholder="Enter your points: 1-36"
                      className={`w-full md:w-16rem ${
                        errorComposite || (error && !actformData.composite)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorComposite && (
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorComposite}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act md:w-14rem">
                      English
                    </label>
                    <InputNumber
                      name="english"
                      value={actformData.english}
                      onValueChange={handleActChange}
                      keyfilter="num"
                      placeholder="Enter your points: 1-36"
                      className={`w-full md:w-16rem ${
                        errorEnglish || (error && !actformData.english)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorEnglish && (
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorEnglish}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act md:w-14rem">
                      Math
                    </label>
                    <InputNumber
                      name="math"
                      value={actformData.math}
                      onValueChange={handleActChange}
                      keyfilter="num"
                      placeholder="Enter your points: 1-36"
                      className={`w-full md:w-16rem ${
                        errorMath || (error && !actformData.math) ? "error" : ""
                      }`}
                    />
                    {errorMath && (
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorMath}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act md:w-14rem">
                      Reading
                    </label>
                    <InputNumber
                      name="reading"
                      value={actformData.reading}
                      onValueChange={handleActChange}
                      placeholder="Enter your points: 1-36"
                      className={`w-full md:w-16rem ${
                        errorReading || (error && !actformData.reading)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorReading && (
                      <span
                        className="error-message flex justify-content-center align-items-center  text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorReading}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act md:w-14rem">
                      Science
                    </label>
                    <InputNumber
                      name="science"
                      value={actformData.science}
                      onValueChange={handleActChange}
                      keyfilter="num"
                      placeholder="Enter your points: 1-36"
                      className={`w-full md:w-16rem ${
                        errorScience || (error && !actformData.science)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorScience && (
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorScience}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act md:w-14rem">
                      Writing
                    </label>
                    <InputNumber
                      name="writing"
                      value={actformData.writing}
                      onValueChange={handleActChange}
                      keyfilter="num"
                      placeholder="Enter your points: 1-36"
                      className={`w-full md:w-16rem ${
                        errorWriting || (error && !actformData.writing)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorWriting && (
                      <span
                        className="error-message  flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorWriting}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <footer className="Extra-activities-form-footer gap-4">
                <Button
                  className="scores-button"
                  label="Cancel"
                  severity="info"
                  onClick={handleClick}
                />
                <Button
                  type="submit"
                  value="Submit"
                  label={editIndex !== null ? "Update ACT Score" : "Add ACT Score"}
                  severity="secondary"
                />
              </footer>
            </form>
          </div>
        ) : (
          <div>
            <div
              className="flex flex-wrap justify-content-end gap-1 pt-4 pb-4"
              onClick={handleClick}
            >
              <div className="addextra-text">+ Add ACT Score</div>
            </div>

            {actNewData.length === 0 ? (
              <div className="flex flex-column justify-content-center align-items-center">
                <Image src={Illustration} alt="Image" className="extra-image" />
                <p className="ex-p">
                  You will see information here when you add your ACT Scores
                </p>
                <Button className='m-3' label="Add ACT Score" severity="secondary" onClick={handleClick}/>
              </div>
            ) : (
              <div className="extra-activities-tables pt-4 pb-4">
                <DataTable value={actNewData} className="datatable-container">
                  <Column field="date" header="Date" body={(rowData) => rowData.date && rowData.date !== "Invalid date" ? rowData.date : "-"} />
                  <Column field="composite" header="Composite" />
                  <Column field="english" header="English" />
                  <Column field="math" header="Math" />
                  <Column field="reading" header="Reading" />
                  <Column field="science" header="Science" />
                  <Column field="writing" header="Writing" />
                  <Column
                    header="Actions"
                    body={(rowData, column) => (
                      <div className="actions">
                        <div>
                          <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-success mr-1"
                            onClick={() => handleEdit(rowData, column.rowIndex)}
                          />
                          <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-danger ml-1"
                            onClick={() => handleDelete(column.rowIndex)}
                          />
                        </div>
                      </div>
                    )}
                  />
                </DataTable>

                <ul className='hide-on-large-screens'>
                  {actNewData.map((item, index) => (
                    <li key={item.id}>
                      <div>
                        <p className='activity-heading flex justify-content-around align-items-center'>
                          Date <span>{item.date}</span>
                        </p>
                      </div>
                      <div className='px-6 py-4'>
                        <div className='flex justify-content-between align-items-center '>
                          <strong>Composite</strong> {item.composite}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>English</strong> {item.english}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Math</strong> {item.math}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Reading</strong> {item.reading}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Science</strong> {item.science}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Writing</strong> {item.writing}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Actions</strong>
                          <div className="actions">
                            <Button
                              icon="pi pi-pencil"
                              className="p-button-rounded p-button-success mr-1"
                              onClick={() => handleEdit(item, index)}
                            />
                            <Button
                              icon="pi pi-trash"
                              className="p-button-rounded p-button-danger ml-1"
                              onClick={() => handleDelete(index)}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>

              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ACT_Scores;
