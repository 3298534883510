import React, { useState } from 'react';
import '../Login.scss';
import Logo from '../../../Assets/Logo/LOGO.svg';
import Loginimage from '../../../Assets/Loginimage.svg';
import ForgetPassword from '../ForgetPassword/ForgetPassword';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Link, useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import axios from 'axios';
import { UNIQUE_ID_HEADERS, UNIQUE_ID_URL, LOGIN_URL, LOGIN_URL_HEADERS, REFRESH_TOKEN_URL_HEADERS, REFRESH_TOKEN_URL } from '../../../Utils/LoginService';
import { v4 as uuidv4 } from 'uuid';
import JSEncrypt from 'jsencrypt';
import ErrorMessage from "../../../Shared-Component/ErrorMessage/ErrorMessage";
import Spinner from '../../../Shared-Component/Spinner/Gif-Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

const LoginPage = () => {

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: "",
        password: "",
    });

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setError("");
    };

    const [accessToken, setAccessToken] = useState("");
    const [refreshToken, setRefreshToken] = useState("");


    // const header = <div className="font-bold mb-3">Pick a password</div>;
    // const footer = (
    //     <>
    //         <Divider />
    //         <p className="mt-2">Suggestions</p>
    //         <ul className="pl-2 ml-2 mt-0 line-height-3">
    //             <li>Between 6 and 8 characters</li>
    //             <li>At least one number</li>
    //             <li>Al least one upercase letter</li>
    //             <li>At least one lowercase letter</li>
    //         </ul>
    //     </>
    // );
    const isEmailValid = (email) => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailPattern.test(email);
  };

  const handleLogin = () => {
    if (!formData.username && !formData.password) {
      setError("Please provide an email and a password.");
      return;
    }
    if (!formData.username) {
      setError("Please provide an email.");
      return;
    }
    if (!formData.password) {
      setError("Please provide a password.");
      return;
    }
    if (!isEmailValid(formData.username)) {
      setError("Please enter a valid email address.");
      return;
    }
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });


        setTimeout(() => {
            setLoading(false);
            setOpacityStyle({ opacity: 1, background: 'transparent' });
        }, 5000);

    const uniqueId = uuidv4();

        const requestData = {
            uniqueId: uniqueId,
        };

        axios
            .post(UNIQUE_ID_URL, requestData, { headers: UNIQUE_ID_HEADERS })
            .then((response) => {

                const data = {
                    publicKey: response.data.PublicKey,
                }

                const jsEncrypt = new JSEncrypt();
                jsEncrypt.setPublicKey(data.publicKey)

                const combinedString = `${formData.username}:${formData.password}`;
                const encryptedCombinedString = jsEncrypt.encrypt(combinedString);

                const apiPayload = {
                    uniqueId: requestData.uniqueId,
                    encryptedData: encryptedCombinedString,
                };

                axios
                    .post(LOGIN_URL, apiPayload, LOGIN_URL_HEADERS)
                    .then((response) => {
                        setAccessToken(response.data.access_token);
                        setRefreshToken(response.data.refresh_token);

                        // To handle Session Timeout
                        if (response.data.access_token) {
                            localStorage.setItem("userAccessToken", response.data.access_token);
                            localStorage.setItem("userRefreshToken", response.data.refresh_token);
                            localStorage.setItem("email", response.data.email_Id);
                            localStorage.setItem("userId", response.data.user_id);
                            localStorage.setItem("schoolId", response.data.school_id);
                            localStorage.setItem("schoolName", response.data.school_name);

                            let schoolName = response.data.school_name;

                            if (schoolName === "Kapaa High School") {

                              let redirection = response.data.user_type;

                              switch (redirection) {
                                case "Admin":
                                  window.location.href = "/admin/dashboard";
                                  break;
                                case "Teacher":
                                  window.location.href = "/teacher/dashboard";
                                  break;
                                case "Principal":
                                  window.location.href = "/principal/dashboard";
                                  break;
                                case "Student":
                                  window.location.href = "/student/dashboard";
                                  break;
                                default:
                                  break;
                              }
                            } else {

                              let redirection = response.data.user_type;

                              switch (redirection) {
                                case "Admin":
                                  window.location.href = "/admin/dashboard";
                                  break;
                                case "Teacher":
                                  window.location.href = "/teacher/dashboard";
                                  break;
                                case "Principal":
                                  window.location.href = "/principal/dashboard";
                                  break;
                                case "Student":
                                  window.location.href = "/student/dashboard";
                                  break;
                                default:
                                  break;
                              }
                            }
                        }
                    })
                    .catch(error => {
                      if (error?.response?.data?.hasOwnProperty("message")) {
                        const errorMessage = error.response.data.message;
                    
                        switch (errorMessage) {
                            case "Enter the valid EmailId":
                                setError("Your email and/or password do not match our records.");
                                break;
                            case "user isn't confirmed successfully":
                                setError("User has not been verified.");
                                break;
                            default:
                                setError(errorMessage);
                        }
                    } else {
                        setError("Please double-check and try again.");
                    }
                    
                      setLoading(false);
                      setOpacityStyle({opacity : 1, background : 'transparent'});
                    })
                    .catch((error) => {
                        console.error('API Error:', error);
                        setLoading(false);
                    })
            })
    }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className="login-container">
                <div className="spinner-section">
                      {loading ? <Spinner /> : null}
                </div>
      <div className="logo">
        <a href="/"><img src={Logo} alt="" /></a>
      </div>
      <Card className="login-card-section m-7">
        <div className="grid ml-0" style={{ minHeight: '70vh' }}>
          <div className="image-section m-0 col-6  ">
            <img src={Loginimage} alt="Loginimage" />
          </div>
          <div className="login-section m-0 col-6 flex-auto	">
            <div className="login m-5">
              <p>
                <b>Log In</b>
              </p>

              {error && <div className="py-3"><ErrorMessage message={error} isSuccess={true} /></div>}

              <div className="p-fluid">
                <div className="field">
                  <label className="email">Email</label>
                  <InputText
                    name="username"
                    value={formData.username}
                    placeholder="Example Email: 1234567@k12.hi.us"
                    onFocus={(e) => e.target.placeholder = ''}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        e.target.placeholder = 'Example Email: 1234567@k12.hi.us';
                      }
                    }}
                    onChange={handleInputChange}
                    className={error && !formData.email ? "email-error" : ""}
                  />
                  {/* <InputText value={username} placeholder='Enter your email' onChange={(e) => setUsername(e.target.value)} /> */}
                </div>
                <div className="field">
                  <label className="password">Password</label>
                  <Password
                    type="password"
                    name="password"
                    value={formData.password}
                    placeholder="Enter your password"
                    onFocus={(e) => e.target.placeholder = ''}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        e.target.placeholder = 'Enter your password';
                      }
                    }}
                    onChange={handleInputChange}
                    toggleMask={true}
                    feedback={false}
                    className={error && !formData.password ? "error" : ""}
                    minLength={8}
                    maxLength={16}
                    onKeyPress={formData.password ? handleKeyPress : null}
                  />
                </div>
                <div className="button">
                  <Button
                    label="Log In"
                    className="p-button-sm"
                    type="submit"
                    onClick={formData.password ? handleLogin : null}
                  />
                </div>
                <div className="forgetpassword">
                  <Link to="/forgetpassword" element={<ForgetPassword />}>
                    Forgot your Password?
                  </Link>
                </div>
                <div className="help flex align-items-center justify-content-start">
                  <a href="https://staticfile.laulamaschools.com/Student+Dashboard+Guideline.pdf" target='_blank' style={{color: "#3183ff", cursor: "pointer", fontSize:"18px" }} >
                  <FontAwesomeIcon icon={faCircleQuestion} /> Help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default LoginPage;
