import React, { useState, useEffect, useRef } from 'react';
import { Card } from "primereact/card";
import NavBar from '../../Shared-Component/StackNavBar/StackNavBar'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../Shared-Component/Spinner/Gif-Loader';
import axios from 'axios';
import { GET_TEACHER_DETAILS_URL, TEACHER_URL_HEADERS } from '../../Utils/TeacherService';
import { Student_Progress_Report, AUTHORIZATION_HEADERS } from '../../Utils/StudentService';
import DefaultFemaleImage from '../../Assets/Profile edit/Profile image/Default/Woman.png';
import DefaultMaleImage from '../../Assets/Profile edit/Profile image/Default/Man.png';
import './studentProgressReport.scss';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Completed from '../../Assets/ProgressReport/Completed.svg';
import InProgress from '../../Assets/ProgressReport/InProgress.svg';
import NotStarted from '../../Assets/ProgressReport/NotStarted.svg';
import { Link } from "react-router-dom";
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import html2pdf from "html2pdf.js";
import { set } from 'react-hook-form';
import { Avatar } from "primereact/avatar";


const StudentProgressReport = () => {
    const userRole = 'Teacher';
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('students');
    const [name, setName] = useState();
    const [studentsProgress, setStudentsProgress] = useState([]);
    const [teacherDetails, setTeacherDetails] = useState();
    const [teacherName, setTeacherName] = useState();
    const [loading, setLoading] = useState(false);
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('All My Groups');
    const [groups, setGroups] = useState([]);
    const [usersInfo, setUsersInfo] = useState([]);
    const toast = useRef(null);
    const Legends = [
        'Personal Statement',
        'Career Goals',
        'Academic Goals',
        'Academy Pathways',
        'High School Progress',
        'Test Scores',
        'Extra Activities'
    ];

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/teacher/dashboard');
                break;
            case 'students':
                navigate('/teacher/studentsList');
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        fetchTeacherDetails();
    }, []);

    useEffect(() => {
        axios
            .get(Student_Progress_Report, { headers: AUTHORIZATION_HEADERS })
            .then((response) => {
                const studentData = response.data;
                setStudentsProgress(studentData);
                const groupNames = studentData.reduce((acc, curr) => {
                    if (!acc.includes(curr.groupName)) {
                        acc.push(curr.groupName);
                    }
                    return acc;
                }, []);
                setGroups([{ label: 'All My Groups', value: "All My Groups" }, ...groupNames.map(groupName => ({ label: groupName, value: groupName }))]);
                setFilteredStudents(studentData);
            })
            .catch((error) => {
                console.error('API Error:', error);
                if (toast.current) {
                    toast.current.clear();
                    toast.current.show([{ sticky: true, severity: 'error', summary: 'Error', detail: "Something Went Wrong" }]);
                }
            });
    }, []);

    const fetchTeacherDetails = () => {
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });
        const teacherId = localStorage.getItem('userId');
        axios
            .get(GET_TEACHER_DETAILS_URL + teacherId, { headers: TEACHER_URL_HEADERS })
            .then((response) => {
                setTeacherDetails(response.data);
                const teacherFirstNames = response.data?.groupUsersInfo;
                setTeacherName(teacherFirstNames)
                setName(`${response.data.teacher.firstName} ${response.data.teacher.lastName}`);
                setUsersInfo(response.data.groupUsersInfo);
                setLoading(false);
                // setOpacityStyle({ opacity: 1, background: 'transparent' });
            });
    };

    const onGroupChange = (e) => {
        const selectedGroup = e.value;
        setSelectedGroup(selectedGroup);
        if (selectedGroup === 'All My Groups') {
            setFilteredStudents(studentsProgress);
        } else {
            const filteredStudents = studentsProgress.filter(student => student.groupName === selectedGroup);
            setFilteredStudents(filteredStudents);
        }
    };


    const statusImage = (status, field) => {
        const statusToImageMap = {
            'C': Completed,
            'S': InProgress,
            'N': NotStarted
        };

        const statusToTooltipMap = {
            'C': 'Completed',
            'S': 'In Progress',
            'N': 'Not Started',

        };

        return (
            <>
                <img id={`${field}-${status}`} src={statusToImageMap[status]} alt={status} style={{ width: '20px', height: '20px' }} />
                <Tooltip target={`#${field}-${status}`} content={statusToTooltipMap[status]} position="bottom" />
            </>
        );
    };

    const sectionTemplate = (rowData, field) => {
        return statusImage(rowData[field]);
    };

    const nameTemplate = (rowData) => {
        const userInfo = usersInfo.find(user => user.userId === rowData.studentId);
        const profileImage = rowData.profileImage || (userInfo?.gender === 'Female' ? DefaultFemaleImage : DefaultMaleImage);
        const backgroundColor = rowData.imageColourCode || '#B9C4E1';
        return (
            <div className="flex align-items-center">
                <img src={profileImage} alt="profile" style={{ background: backgroundColor, width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px', padding: '4px' }} />
                <span>{rowData.studentName}</span>
            </div>
        );
    };

    const downloadPdf = () => {
        const element = document.getElementById("download-content");
        html2pdf(element, {
            margin: [0.5, 0.1, 0.5, 0.1],
            filename: 'Students PTP Progress Report.pdf',
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { dpi: 192, letterRendering: true },
            jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        });
    };


    return (
        <div className='student-progressreport'>
            <div className="spinner-section">
                {loading ? <Spinner /> : null}
            </div>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
                name={name}
            />
            <div className="toast-overlay">
                <Toast ref={toast} className="toast" />
            </div>
            <div className="student-progress_report-container">
                <div>
                    <Button label="< Back to my Students" className="spr-back-button" severity='info' onClick={() => navigate('/teacher/studentsList')} />
                </div>
                <div className='spr-header flex justify-content-between flex-wrap'>
                    <div>
                        <h2>Student Progress Report</h2>
                    </div>
                    <div>
                        <Button label="Download Report" severity="primary" onClick={() => { downloadPdf(); }} />
                    </div>
                </div>
                <div className='spr-data'>
                    <Card className="card-field m-5">
                        <div className="flex justify-content-end flex-wrap m-1 mr-4">
                            <Dropdown
                                value={selectedGroup}
                                options={groups}
                                onChange={onGroupChange}
                                placeholder="Select a group"
                                style={{ width: '250px' }}
                            />
                        </div>
                        <div id="download-content" className="grid mt-2 mb-3 download-content">
                            <div className='legend-section col-12 md:col-3'>
                                <div className="legend">
                                    <div className="l-name">Legend</div>
                                    <ol className="">
                                        {Legends.map((item, index) => (
                                            <li className="l-list" key={index}>{item}</li>
                                        ))}
                                    </ol>
                                </div>
                            </div>

                            <div className="spr-datatable col-12 md:col-9 ">
                                <DataTable value={filteredStudents} removableSort>
                                    <Column field="studentName" header="Name" body={nameTemplate} sortable />
                                    <Column field="personalStatement" header="1" body={(rowData) => sectionTemplate(rowData, 'personalStatement')} />
                                    <Column field="careerGoals" header="2" body={(rowData) => sectionTemplate(rowData, 'careerGoals')} />
                                    <Column field="academicGoals" header="3" body={(rowData) => sectionTemplate(rowData, 'academicGoals')} />
                                    <Column field="academiesPathways" header="4" body={(rowData) => sectionTemplate(rowData, 'academiesPathways')} />
                                    <Column field="highSchoolProgress" header="5" body={(rowData) => sectionTemplate(rowData, 'highSchoolProgress')} />
                                    <Column field="testScores" header="6" body={(rowData) => sectionTemplate(rowData, 'testScores')} />
                                    <Column field="extraActivities" header="7" body={(rowData) => sectionTemplate(rowData, 'extraActivities')} />
                                    <Column
                                        className="view-details"
                                        field=""
                                        header="Actions"
                                        body={(rowData) => (
                                            <Link
                                                onClick={() => {
                                                    const userId = rowData?.studentId;
                                                    localStorage.setItem('rowStudentId', userId);
                                                }}
                                                style={{ textDecoration: "none", color: "#1575e5" }}
                                                to={'/' + sessionStorage.userType + '/studentinfo'}
                                            >
                                                View PTP
                                            </Link>
                                        )}
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </Card>
                </div>

            </div>
        </div>
    );
};

export default StudentProgressReport;