import React, { useState, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import "../../CheckList-Progress-Form/Graduation-Requirements/Graduation-Requirements.scss";
import Electives from '../Electives/Electives';
import { Button } from 'primereact/button';
import Spinner from '../../../../../../Shared-Component/Spinner/Gif-Loader';
import { Divider } from 'primereact/divider';
import Illustration from '../../../../../../Assets/Student/tableilustration.svg';
import { Image } from 'primereact/image';
import TreeTableComponent from '../HighSchool-GR-Section/HighSchool-GR-Section';

const HighSchoolProgressCourses = ({ getApiResponse, onStatusChange, onSubmit, getCredits }) => {

    const [courses, setCourses] = useState(false);
    const [coursesList, setCoursesList] = useState([]);
    const [highCourseId, setHighCourseId] = useState([]);

    const [englishLanguageArts1StatusCompleted, setEnglishLanguageArts1StatusCompleted] = useState([]);
    const [englishLanguageArts2StatusCompleted, setEnglishLanguageArts2StatusCompleted] = useState([]);
    const [englishLanguageArts3StatusCompleted, setEnglishLanguageArts3StatusCompleted] = useState([]);
    const [englishLanguageArts4StatusCompleted, setEnglishLanguageArts4StatusCompleted] = useState([]);
    const [apLanguageCompositionStatusCompleted, setApLanguageCompositionStatusCompleted] = useState([]);
    const [apLiteratureCompositionStatusCompleted, setApLiteratureCompositionStatusCompleted] = useState([]);

    const [participationInDemocracyStatusCompleted, setParticipationInDemocracyStatusCompleted] = useState([]);
    const [modernHistoryOfHawaiiStatusCompleted, setModernHistoryOfHawaiiStatusCompleted] = useState([]);
    const [worldHistoryStatusCompleted, setWorldHistoryStatusCompleted] = useState([]);
    const [usHistoryStatusCompleted, setUsHistoryStatusCompleted] = useState([]);
    const [economicsStatusCompleted, setEconomicsStatusCompleted] = useState([]);
    const [apUsHistoryStatusCompleted, setApUsHistoryStatusCompleted] = useState([]);
    const [hawaiianStudiesStatusCompleted, setHawaiianStudiesStatusCompleted] = useState([]);
    const [geographyStatusCompleted, setGeographyStatusCompleted] = useState([]);
    const [psychologyStatusCompleted, setPsychologyStatusCompleted] = useState([]);
    const [apPsychologyStatusCompleted, setApPsychologyStatusCompleted] = useState([]);

    const [geometryCompleted, setGeometryCompleted] = useState([]);
    const [algebra1Completed, setAlgebra1Completed] = useState([]);
    const [algebra2Completed, setAlgebra2Completed] = useState([]);
    const [introductionToCollegeMathematicsCompleted, setIntroductionToCollegeMathematicsCompleted] = useState([]);
    const [precalculusCompleted, setPrecalculusCompleted] = useState([]);
    const [trigonometryCompleted, setTrigonometryCompleted] = useState([]);
    const [apComputerSciencePrinciplesCompleted, setApComputerSciencePrinciplesCompleted] = useState([]);

    const [biologyCompleted, setBiologyCompleted] = useState([]);
    const [earthSystemScienceCompleted, setEarthSystemScienceCompleted] = useState([]);
    const [marineScienceCompleted, setMarineScienceCompleted] = useState([]);
    const [humanPhysiologyCompleted, setHumanPhysiologyCompleted] = useState([]);
    const [chemistryCompleted, setChemistryCompleted] = useState([]);
    const [honorChemistryCompleted, setHonorChemistryCompleted] = useState([]);
    const [physicsCompleted, setPhysicsCompleted] = useState([]);
    const [apBiologyCompleted, setApBiologyCompleted] = useState([]);

    const [physicalEducationLifetimeFitnessCompleted, setPhysicalEducationLifetimeFitnessCompleted] = useState([]);
    const [bodyConditioningCompleted, setBodyConditioningCompleted] = useState([]);
    const [teamSports1Completed, setTeamSports1Completed] = useState([]);
    const [teamSports2Completed, setTeamSports2Completed] = useState([]);
    const [weightResistance1ACompleted, setWeightResistance1ACompleted] = useState([]);
    const [weightResistance1BCompleted, setWeightResistance1BCompleted] = useState([]);
    const [weightResistance2ACompleted, setWeightResistance2ACompleted] = useState([]);
    const [weightResistance2BCompleted, setWeightResistance2BCompleted] = useState([]);
    const [recreationalLeadershipCompleted, setRecreationalLeadershipCompleted] = useState([]);

    const [healthCompleted, setHealthCompleted] = useState([]);
    const [ptpCompleted, setPtpCompleted] = useState([]);

    // Local storage items

    const englishLocalStorageKey = 'English';
    const englishElectiveLocalStorageKey = 'English Elective';
    const socialLocalStorageKey = 'Social Studies';
    const socialElectiveLocalStorageKey = 'Social Studies Elective';
    const mathLocalStorageKey = 'Math';
    const mathElectiveLocalStorageKey = 'Math Elective';
    const scienceLocalStorageKey = 'Science';
    const scienceElectiveLocalStorageKey = 'Science Elective';
    const peLocalStorageKey = 'PE';
    const peElectiveLocalStorageKey = 'PE Elective';
    const healthLocalStorageKey = 'Health';
    const ptpLocalStorageKey = 'PTP';

    const [isOpen, setOpen] = useState(false);
    const [activeButton, setActiveButton] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const isTabOpen = (index) => activeIndex === index;

    const [english, setEnglish] = useState([]);
    const [englishElective, setEnglishElective] = useState([]);
    const [socialStudies, setSocialStudies] = useState([]);
    const [socialStudiesElective, setSocialStudiesElective] = useState([]);
    const [math, setMath] = useState([]);
    const [mathElective, setMathElectiveSelectedCategory] = useState([]);
    const [science, setScience] = useState([]);
    const [scienceElective, setScienceElective] = useState([]);
    const [physicalEducation, setPhysicalEducation] = useState([]);
    const [physicalEducationElective, setPhysicalEducationElective] = useState([]);
    const [health, setHealth] = useState([]);
    const [ptp, setPtp] = useState([]);

    const [loading, setLoading] = useState(false);

    const handleCourses = () => {
        setCourses(true);
    }

    const [triggerAPI, setTriggerAPI] = useState();

    const showCoursesDetails = (newData) => {
        setTriggerAPI(true);
        onSubmit();
        setCourses(false);
        setLoading(true);
        onStatusChange();
        setTimeout(() => {
            setTriggerAPI(false);
            setLoading(false);
        }, 5000);
    }

    const handleCancel = () => {
        setCourses(false);
    }

    useEffect(() => {

        if (coursesList.length > 0) {
            setCoursesList(false)
        }

        const highCourseId = getApiResponse.highCourseId;

        setHighCourseId(highCourseId);

        const extractedDetails = getApiResponse && (getApiResponse.subjects || getApiResponse.electiveCourses) ?
            (getApiResponse.subjects || []).flatMap(subject => {
                if (subject?.courses) {
                    const courseDetails = subject.courses.map(course => ({
                        area: course?.area || "",
                        course: course?.course || "",
                        creditValue: course?.creditValue || "",
                        status: course?.status || ""
                    }));
                    return courseDetails;
                }
                return [];
            }).concat(
                (getApiResponse.subjects || []).flatMap(subject => {
                    if (subject.electiveCategories) {
                        return subject.electiveCategories.flatMap(category => {
                            return category.courses.map(course => ({
                                area: course.area || "",
                                course: course.course || "",
                                creditValue: course.creditValue || "",
                                status: course.status || ""
                            }));
                        });
                    }
                    return [];
                })
            ).concat(
                (getApiResponse.electiveCourses || []).flatMap(category => {
                    return category.courses.map(course => ({
                        area: course.area || "",
                        course: course.course || "",
                        creditValue: course.creditValue || "",
                        status: course.status || ""
                    }));
                })
            ) : [];

        setCoursesList(extractedDetails);

        const englishData = getApiResponse && getApiResponse.subjects ? getApiResponse?.subjects.filter(subject => subject.category === "English") : [];

        setEnglishLanguageArts1StatusCompleted(false);
        setApLanguageCompositionStatusCompleted(false);
        setApLiteratureCompositionStatusCompleted(false);

        if (englishData && englishData?.length > 0) {

            const completedCourses = englishData
                .map(entry => {
                    const completed = entry.courses.filter(course => course.status === 'Completed');
                    const completedElectives = entry.electiveCategories.map(cat => cat.courses.filter(course => course.status === 'Completed')).flat();
                    return completed.concat(completedElectives);
                })
                .flat();

            if (completedCourses.some(course => course.course === 'English Language Arts 1')) {
                setEnglishLanguageArts1StatusCompleted(true);
                setEnglishLanguageArts2StatusCompleted(false);
            }

            if (completedCourses.some(course => course.course === 'English Language Arts 2')) {
                setEnglishLanguageArts1StatusCompleted(true);
                setEnglishLanguageArts2StatusCompleted(true);
                setEnglishLanguageArts3StatusCompleted(false);
                // setApLanguageCompositionStatusCompleted(false);
            }

            if (completedCourses.some(course => course.course === 'English Language Arts 3')) {
                setEnglishLanguageArts3StatusCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'English Language Arts 4')) {
                setEnglishLanguageArts4StatusCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'AP Language & Composition')) {
                setApLanguageCompositionStatusCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'AP Literature & Composition')) {
                setApLiteratureCompositionStatusCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'English Language Arts 3') || completedCourses.some(course => course.course === 'AP Language & Composition')) {
                setEnglishLanguageArts4StatusCompleted(false);
                // setApLiteratureCompositionStatusCompleted(false);
            }

            const englishCourses = englishData.map(entry => entry.courses.map(course => course.course)).flat();
            setEnglish(englishCourses);
            localStorage.setItem(englishLocalStorageKey, JSON.stringify(englishCourses));

            const englishElectiveItems = englishData.flatMap(entry => entry.electiveCategories.map(category => category.courses.map(course => course.course))).flat();
            setEnglishElective(englishElectiveItems);
        }

        const socialData = getApiResponse && getApiResponse.subjects ? getApiResponse?.subjects.filter(subject => subject.category === "Social Studies") : [];

        setParticipationInDemocracyStatusCompleted(false);
        setModernHistoryOfHawaiiStatusCompleted(false);
        setApUsHistoryStatusCompleted(false);
        setApPsychologyStatusCompleted(false);

        if (socialData && socialData?.length > 0) {
            const completedCourses = socialData
                .map(entry => {
                    const completed = entry.courses.filter(course => course.status === 'Completed');
                    const completedElectives = entry.electiveCategories.map(cat => cat.courses.filter(course => course.status === 'Completed')).flat();
                    return completed.concat(completedElectives);
                })
                .flat();

            if (completedCourses.some(course => course.course === 'Participation In Democracy')) {
                setParticipationInDemocracyStatusCompleted(true);
            }


            if (completedCourses.some(course => course.course === 'Modern History Of Hawai`i')) {
                setModernHistoryOfHawaiiStatusCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Participation In Democracy') || completedCourses.some(course => course.course === 'Modern History Of Hawai`i')) {
                setWorldHistoryStatusCompleted(false);
            }

            if (completedCourses.some(course => course.course === 'World History')) {
                setWorldHistoryStatusCompleted(true);
                setUsHistoryStatusCompleted(false);
                // setApUsHistoryStatusCompleted(false);
            }

            if (completedCourses.some(course => course.course === 'US History') || completedCourses.some(course => course.course === 'AP US History')) {
                setEconomicsStatusCompleted(false);
                setHawaiianStudiesStatusCompleted(false);
                setGeographyStatusCompleted(false);
                setPsychologyStatusCompleted(false);
                // setApPsychologyStatusCompleted(false);
            }

            if (completedCourses.some(course => course.course === 'US History')) {
                setUsHistoryStatusCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Economics')) {
                setEconomicsStatusCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'AP US History')) {
                setApUsHistoryStatusCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Hawaiian Studies')) {
                setHawaiianStudiesStatusCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Geography')) {
                setGeographyStatusCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Psychology')) {
                setPsychologyStatusCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'AP Psychology I/II')) {
                setApPsychologyStatusCompleted(true);
            }

            const socialCourses = socialData.map(entry => entry.courses.map(course => course.course)).flat();
            setSocialStudies(socialCourses);
            localStorage.setItem(socialLocalStorageKey, JSON.stringify(socialCourses));

            const socialElectiveItems = socialData.flatMap(entry => entry.electiveCategories.map(category => category.courses.map(course => course.course))).flat();
            setSocialStudiesElective(socialElectiveItems);
        }

        const mathData = getApiResponse && getApiResponse.subjects ? getApiResponse?.subjects.filter(subject => subject.category === "Math") : [];

        setGeometryCompleted(false);
        setAlgebra1Completed(false);
        setApComputerSciencePrinciplesCompleted(false);

        if (mathData && mathData?.length > 0) {

            const completedCourses = mathData
                .map(entry => {
                    const completed = entry.courses.filter(course => course.status === 'Completed');
                    const completedElectives = entry.electiveCategories.map(cat => cat.courses.filter(course => course.status === 'Completed')).flat();
                    return completed.concat(completedElectives);
                })
                .flat();

            if (completedCourses.some(course => course.course === 'Geometry')) {
                setGeometryCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Algebra 1')) {
                setAlgebra1Completed(true);
            }

            if (completedCourses.some(course => course.course === 'Geometry') && completedCourses.some(course => course.course === 'Algebra 1')) {
                setAlgebra2Completed(false);
                setIntroductionToCollegeMathematicsCompleted(false);
            }

            if (completedCourses.some(course => course.course === 'Algebra 2')) {
                setAlgebra2Completed(true);
                setPrecalculusCompleted(false);
                setTrigonometryCompleted(false);
                setChemistryCompleted(false);
                setHonorChemistryCompleted(false);
                setPhysicsCompleted(false);
            }

            if (completedCourses.some(course => course.course === 'Introduction To College Mathematics')) {
                setIntroductionToCollegeMathematicsCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Precalculus')) {
                setPrecalculusCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Trigonometry')) {
                setTrigonometryCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'AP Computer Science Principles')) {
                setApComputerSciencePrinciplesCompleted(true);
            }

            const mathCourses = mathData.map(entry => entry.courses.map(course => course.course)).flat();
            setMath(mathCourses);
            localStorage.setItem(mathLocalStorageKey, JSON.stringify(mathCourses));

            const mathElectiveItems = mathData.flatMap(entry => entry.electiveCategories.map(category => category.courses.map(course => course.course))).flat();
            setMathElectiveSelectedCategory(mathElectiveItems);
        }

        const scienceData = getApiResponse && getApiResponse.subjects ? getApiResponse?.subjects.filter(subject => subject.category === "Science") : [];

        setBiologyCompleted(false);
        setApBiologyCompleted(false);

        if (scienceData && scienceData?.length > 0) {
            const completedCourses = scienceData
                .map(entry => {
                    const completed = entry.courses.filter(course => course.status === 'Completed');
                    const completedElectives = entry.electiveCategories.map(cat => cat.courses.filter(course => course.status === 'Completed')).flat();
                    return completed.concat(completedElectives);
                })
                .flat();

            if (completedCourses.some(course => course.course === 'Biology')) {
                setBiologyCompleted(true);
                setEarthSystemScienceCompleted(false);
                setMarineScienceCompleted(false);
                setHumanPhysiologyCompleted(false);

            }

            if (completedCourses.some(course => course.course === 'Earth System Science')) {
                setEarthSystemScienceCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Marine Science')) {
                setMarineScienceCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Human Physiology')) {
                setHumanPhysiologyCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Chemistry')) {
                setChemistryCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Honor Chemistry')) {
                setHonorChemistryCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Physics')) {
                setPhysicsCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Biology') && completedCourses.some(course => course.course === 'Chemistry') && algebra2Completed) {
                setApBiologyCompleted(false);
            }

            if (completedCourses.some(course => course.course === 'AP Biology')) {
                setApBiologyCompleted(true);
            }

            const scienceCourses = scienceData.map(entry => entry.courses.map(course => course.course)).flat();
            setScience(scienceCourses);
            localStorage.setItem(scienceLocalStorageKey, JSON.stringify(scienceCourses));

            const scienceElectiveItems = scienceData.flatMap(entry => entry.electiveCategories.map(category => category.courses.map(course => course.course))).flat();
            setScienceElective(scienceElectiveItems);
        }

        const peData = getApiResponse && getApiResponse.subjects ? getApiResponse?.subjects.filter(subject => subject.category === "PE") : [];

        setPhysicalEducationLifetimeFitnessCompleted(false);
        setBodyConditioningCompleted(false);
        setRecreationalLeadershipCompleted(false);

        if (peData && peData?.length > 0) {

            const completedCourses = peData
                .map(entry => {
                    const completed = entry.courses.filter(course => course.status === 'Completed');
                    const completedElectives = entry.electiveCategories.map(cat => cat.courses.filter(course => course.status === 'Completed')).flat();
                    return completed.concat(completedElectives);
                })
                .flat();

            if (completedCourses.some(course => course.course === 'Physical Education Lifetime Fitness')) {
                setPhysicalEducationLifetimeFitnessCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Body Conditioning')) {
                setBodyConditioningCompleted(true);
                setTeamSports1Completed(false);
            }

            if (completedCourses.some(course => course.course === 'Team Sports 1')) {
                setTeamSports1Completed(true);
                setTeamSports2Completed(false);
            }
            if (completedCourses.some(course => course.course === 'Team Sports 2')) {
                setTeamSports2Completed(true);
                setWeightResistance1ACompleted(false);
            }

            if (completedCourses.some(course => course.course === 'Weight / Resistance 1A')) {
                setWeightResistance1ACompleted(true);
                setWeightResistance1BCompleted(false);
            }

            if (completedCourses.some(course => course.course === 'Weight / Resistance 1B')) {
                setWeightResistance1BCompleted(true);
                setWeightResistance2ACompleted(false);
            }

            if (completedCourses.some(course => course.course === 'Weight / Resistance 2A')) {
                setWeightResistance2ACompleted(true);
                setWeightResistance2BCompleted(false);
            }

            if (completedCourses.some(course => course.course === 'Weight / Resistance 2B')) {
                setWeightResistance2BCompleted(true);
            }

            if (completedCourses.some(course => course.course === 'Recreational Leadership')) {
                setRecreationalLeadershipCompleted(true);
            }

            const peCourses = peData.map(entry => entry.courses.map(course => course.course)).flat();
            setPhysicalEducation(peCourses);
            localStorage.setItem(peLocalStorageKey, JSON.stringify(peCourses));

            const peElectiveItems = peData.flatMap(entry => entry.electiveCategories.map(category => category.courses.map(course => course.course))).flat();
            setPhysicalEducationElective(peElectiveItems);
        }

        const healthData = getApiResponse && getApiResponse.subjects ? getApiResponse?.subjects.filter(subject => subject.category === "Health") : [];

        setHealthCompleted(false);

        if (healthData && healthData?.length > 0) {

            const completedCourses = healthData.map(entry => entry.courses)
                .flat()
                .filter(course => course.status === 'Completed')
                .map(course => course.course);

            if (completedCourses.includes('Health Today/ Tomorrow')) {
                setHealthCompleted(true);
            }

            const healthCourses = healthData.map(entry => entry.courses.map(course => course.course)).flat();
            setHealth(healthCourses);
            localStorage.setItem(healthLocalStorageKey, JSON.stringify(healthCourses));
        }

        const ptpData = getApiResponse && getApiResponse.subjects ? getApiResponse?.subjects.filter(subject => subject.category === "PTP") : [];

        setPtpCompleted(false);

        if (ptpData && ptpData?.length > 0) {

            const completedCourses = ptpData.map(entry => entry.courses)
                .flat()
                .filter(course => course.status === 'Completed')
                .map(course => course.course);

            if (completedCourses.includes('Personal Transition Plan')) {
                setPtpCompleted(true);
            }

            const ptpCourses = ptpData.map(entry => entry.courses.map(course => course.course)).flat();
            setPtp(ptpCourses);
            localStorage.setItem(ptpLocalStorageKey, JSON.stringify(ptpCourses));
        }

    }, [getApiResponse]);

    useEffect(() => {
        const englishStoredData = localStorage.getItem(englishLocalStorageKey);
        if (englishStoredData) {
            setEnglish(JSON.parse(englishStoredData));
        }

        const englishElectiveStoredData = localStorage.getItem(englishElectiveLocalStorageKey);
        if (englishElectiveStoredData) {
            const parsedData = JSON.parse(englishElectiveStoredData);
            const selectedEnglishElective = Object.keys(parsedData).filter(key => parsedData[key]);
            setEnglishElective(selectedEnglishElective);
        }

        const socialStoredData = localStorage.getItem(socialLocalStorageKey);
        if (socialStoredData) {
            setSocialStudies(JSON.parse(socialStoredData));
        }

        const socialElectiveStoredData = localStorage.getItem(socialElectiveLocalStorageKey);
        if (socialElectiveStoredData) {
            const parsedData = JSON.parse(socialElectiveStoredData);
            const selectedSocialElective = Object.keys(parsedData).filter(key => parsedData[key]);
            setSocialStudiesElective(selectedSocialElective);
        }

        const mathStoredData = localStorage.getItem(mathLocalStorageKey);
        if (mathStoredData) {
            setMath(JSON.parse(mathStoredData));
        }

        const mathElectiveStoredData = localStorage.getItem(mathElectiveLocalStorageKey);
        if (mathElectiveStoredData) {
            const parsedData = JSON.parse(mathElectiveStoredData);
            const selectedMathElective = Object.keys(parsedData).filter(key => parsedData[key]);
            setMathElectiveSelectedCategory(selectedMathElective);
        }

        const scienceStoredData = localStorage.getItem(scienceLocalStorageKey);
        if (scienceStoredData) {
            setScience(JSON.parse(scienceStoredData));
        }

        const scienceElectiveStoredData = localStorage.getItem(scienceElectiveLocalStorageKey);
        if (scienceElectiveStoredData) {
            const parsedData = JSON.parse(scienceElectiveStoredData);
            const selectedScienceElective = Object.keys(parsedData).filter(key => parsedData[key]);
            setScienceElective(selectedScienceElective);
        }

        const PeStoredData = localStorage.getItem(peLocalStorageKey);
        if (PeStoredData) {
            setPhysicalEducation(JSON.parse(PeStoredData));
        }

        const peElectiveStoredData = localStorage.getItem(peElectiveLocalStorageKey);
        if (peElectiveStoredData) {
            const parsedData = JSON.parse(peElectiveStoredData);
            const selectedPeElective = Object.keys(parsedData).filter(key => parsedData[key]);
            setPhysicalEducationElective(selectedPeElective);
        }

        const HealthStoredData = localStorage.getItem(healthLocalStorageKey);
        if (HealthStoredData) {
            setHealth(JSON.parse(HealthStoredData));
        }

        //ptp add
    }, []);

    const graduationRequirements = {
        'English': {
            categories: [
                { label: 'English Language Arts 1', key: 'English Language Arts 1' },
                { label: 'English Language Arts 2', key: 'English Language Arts 2' },
                { label: 'English Language Arts 3', key: 'English Language Arts 3' },
                { label: 'English Language Arts 4', key: 'English Language Arts 4' }
            ],
            electiveSubjects: [
                { label: 'AP Language & Composition', key: 'AP Language & Composition' },
                { label: 'AP Literature & Composition', key: 'AP Literature & Composition' }
            ]
        },
        'Social Studies': {
            categories: [
                { label: 'Participation In Democracy', key: 'Participation In Democracy' },
                { label: 'Modern History Of Hawai`i', key: 'Modern History Of Hawai`i' },
                { label: 'World History', key: 'World History' },
                { label: 'US History', key: 'US History' },
                { label: 'Economics', key: 'Economics' }
            ],
            electiveSubjects: [
                { label: 'AP US History', key: 'AP US History' },
                { label: 'Hawaiian Studies', key: 'Hawaiian Studies' },
                { label: 'Geography', key: 'Geography' },
                { label: 'Psychology', key: 'Psychology' },
                { label: 'AP Psychology I/II', key: 'AP Psychology I/II' }
            ]
        },
        'Math': {
            categories: [
                { label: 'Geometry', key: 'Geometry' },
                { label: 'Algebra 1', key: 'Algebra 1' }
            ],
            electiveSubjects: [
                { label: 'Algebra 2', key: 'Algebra 2' },
                { label: 'Introduction To College Mathematics', key: 'Introduction To College Mathematics' },
                { label: 'Precalculus', key: 'Precalculus' },
                { label: 'Trigonometry', key: 'Trigonometry' },
                { label: 'AP Computer Science Principles', key: 'AP Computer Science Principles' }
            ]
        },
        'Science': {
            categories: [
                { label: 'Biology', key: 'Biology' }
            ],
            electiveSubjects: [
                { label: 'Earth System Science', key: 'Earth System Science' },
                { label: 'Marine Science', key: 'Marine Science' },
                { label: 'Human Physiology', key: 'Human Physiology' },
                { label: 'Chemistry', key: 'Chemistry' },
                { label: 'Honor Chemistry', key: 'Honor Chemistry' },
                { label: 'Physics', key: 'Physics' },
                { label: 'AP Biology', key: 'AP Biology' }
            ]
        },
        'Physical Education': {
            categories: [
                { label: 'Physical Education Lifetime Fitness', key: 'Physical Education Lifetime Fitness' }
            ],
            electiveSubjects: [
                { label: 'Body Conditioning', key: 'Body Conditioning' },
                { label: 'Team Sports 1', key: 'Team Sports 1' },
                { label: 'Team Sports 2', key: 'Team Sports 2' },
                { label: 'Weight / Resistance 1A', key: 'Weight / Resistance 1A' },
                { label: 'Weight / Resistance 1B', key: 'Weight / Resistance 1B' },
                { label: 'Weight / Resistance 2A', key: 'Weight / Resistance 2A' },
                { label: 'Weight / Resistance 2B', key: 'Weight / Resistance 2B' },
                { label: 'Recreational Leadership', key: 'Recreational Leadership' }
            ]
        },
        'Health': {
            categories: [
                { label: 'Health Today/ Tomorrow', key: 'Health Today/ Tomorrow' }
            ]
        },
        'Personal Transition Plan': {
            categories: [
                { label: 'Personal Transition Plan', key: 'Personal Transition Plan' }
            ]
        }
    };

    const handleButtonClick = (label) => {
        setActiveButton(label);
        setActiveIndex(null);
    };

    const toggle = () => {
        setOpen(!isOpen);
    };

    const onEnglishChange = (e) => {
        let _english = [...english];

        if (e.checked)
            _english.push(e.value);
        else
            _english.splice(_english.indexOf(e.value), 1);

        setEnglish(_english);
        localStorage.setItem(englishLocalStorageKey, JSON.stringify(_english));
    }

    const onEnglishElectiveChange = (e) => {
        let _englishElective = [...englishElective];

        if (e.checked)
            _englishElective.push(e.value);
        else
            _englishElective.splice(_englishElective.indexOf(e.value), 1);

        setEnglishElective(_englishElective);

        const englishElectiveData = _englishElective.reduce((acc, currentValue) => {
            acc[currentValue] = true;
            return acc;
        }, {});

        localStorage.setItem(englishElectiveLocalStorageKey, JSON.stringify(englishElectiveData));
    }

    const onSocialStudiesChange = (e) => {
        let _socialStudies = [...socialStudies];

        if (e.checked)
            _socialStudies.push(e.value);
        else
            _socialStudies.splice(_socialStudies.indexOf(e.value), 1);

        setSocialStudies(_socialStudies);
        localStorage.setItem(socialLocalStorageKey, JSON.stringify(_socialStudies));
    }

    const onSocialStudiesElectiveChange = (e) => {
        let _socialStudiesElective = [...socialStudiesElective];

        if (e.checked)
            _socialStudiesElective.push(e.value);
        else
            _socialStudiesElective.splice(_socialStudiesElective.indexOf(e.value), 1);

        setSocialStudiesElective(_socialStudiesElective);

        const socialElectiveData = _socialStudiesElective.reduce((acc, current) => {
            acc[current] = true;
            return acc;
        }, {})

        localStorage.setItem(socialElectiveLocalStorageKey, JSON.stringify(socialElectiveData));
    }

    const onMathChange = (e) => {
        let _math = [...math];

        if (e.checked)
            _math.push(e.value);
        else
            _math.splice(_math.indexOf(e.value), 1);

        setMath(_math);
        localStorage.setItem(mathLocalStorageKey, JSON.stringify(_math));
    }

    const onMathElectiveChange = (e) => {
        let _mathElectiveSelectedCategory = [...mathElective];

        if (e.checked)
            _mathElectiveSelectedCategory.push(e.value);
        else
            _mathElectiveSelectedCategory.splice(_mathElectiveSelectedCategory.indexOf(e.value), 1);

        setMathElectiveSelectedCategory(_mathElectiveSelectedCategory);

        const mathlElectiveData = _mathElectiveSelectedCategory.reduce((acc, current) => {
            acc[current] = true;
            return acc;
        }, {})

        localStorage.setItem(mathElectiveLocalStorageKey, JSON.stringify(mathlElectiveData));
    };

    const onScienceChange = (e) => {
        let _science = [...science];

        if (e.checked)
            _science.push(e.value);
        else
            _science.splice(_science.indexOf(e.value), 1);

        setScience(_science);
        localStorage.setItem(scienceLocalStorageKey, JSON.stringify(_science));
    }

    const onScienceElectiveChange = (e) => {
        let _scienceElective = [...scienceElective];

        if (e.checked)
            _scienceElective.push(e.value);
        else
            _scienceElective.splice(_scienceElective.indexOf(e.value), 1);

        setScienceElective(_scienceElective);
        const scienceElectiveData = _scienceElective.reduce((acc, currentValue) => {
            acc[currentValue] = true;
            return acc;
        }, {});
        localStorage.setItem(scienceElectiveLocalStorageKey, JSON.stringify(scienceElectiveData));
    }

    const onPhysicalEducationChange = (e) => {
        let _pe = [...physicalEducation];

        if (e.checked)
            _pe.push(e.value);
        else
            _pe.splice(_pe.indexOf(e.value), 1);

        setPhysicalEducation(_pe);
        localStorage.setItem(peLocalStorageKey, JSON.stringify(_pe));
    }

    const onPhysicalEducationElectiveChange = (e) => {
        let _peElective = [...physicalEducationElective];

        if (e.checked)
            _peElective.push(e.value);
        else
            _peElective.splice(_peElective.indexOf(e.value), 1);

        setPhysicalEducationElective(_peElective);
        const peElectiveData = _peElective.reduce((acc, currentValue) => {
            acc[currentValue] = true;
            return acc;
        }, {});
        localStorage.setItem(peElectiveLocalStorageKey, JSON.stringify(peElectiveData));
    };

    const onHealthChange = (e) => {
        let _health = [...health];

        if (e.checked)
            _health.push(e.value);
        else
            _health.splice(_health.indexOf(e.value), 1);

        setHealth(_health);
        localStorage.setItem(healthLocalStorageKey, JSON.stringify(_health));
    }

    const onPTPChange = (e) => {
        let _ptp = [...ptp];

        if (e.checked)
            _ptp.push(e.value);
        else
            _ptp.splice(_ptp.indexOf(e.value), 1);

        setPtp(_ptp);
        localStorage.setItem(ptpLocalStorageKey, JSON.stringify(_ptp));
    }

    return (
        <div className="card graduation-container">

            <div className='hs-datatable'>
                <TreeTableComponent getCredits={getCredits} triggerAPI={triggerAPI} onStatusChange={onStatusChange} />
            </div>

            <div className="hide-on-large-screens">
                <TreeTableComponent getCredits={getCredits} triggerAPI={triggerAPI} onStatusChange={onStatusChange} />
            </div>


            <div className='my-3 gap-2 note-alignment'>
                <div>
                    <p className='m-0'>Provide your high school courses. To know more information about the course go to <a href='/student/help/courses' style={{ color: "#1575e5", cursor: "pointer" }}>Courses List.</a> </p>
                    <p>To select certain courses you have to mark the previous course as completed. </p>
                    {!courses ?
                        <div className='addextra-text flex justify-content-end' onClick={handleCourses}>+ Add  high school courses</div>
                        : ""
                    }
                </div>
            </div>

            {courses ?
                <>
                    <div className="GR-form">
                        <div className="top-section-buttons flex flex-row flex-wrap gap-4">
                            {Object.keys(graduationRequirements).map((label) => (
                                <div className='button-section'>
                                    <div key={label} className={label === activeButton ? "category-btn-active" : "category-btn"}
                                        onClick={() => handleButtonClick(label)}>
                                        {label}
                                    </div>

                                </div>
                            ))}
                        </div>

                        <div className="flex justify-content-left mt-4">
                            <div className="flex flex-row flex-wrap gap-3">
                                {activeButton === "English" && (
                                    <div>
                                        <div className="categories">
                                            <div className="flex align-items-center">
                                                <Checkbox inputId="English Language Arts 1" name="English Language Arts 1" value="English Language Arts 1" onChange={onEnglishChange} checked={english.includes('English Language Arts 1')} disabled={englishLanguageArts1StatusCompleted} />
                                                <label htmlFor="English Language Arts 1" className="ml-2">English Language Arts 1</label>
                                            </div>

                                            <div className="flex align-items-center">
                                                <Checkbox inputId="English Language Arts 2" name="English Language Arts 2" value="English Language Arts 2" onChange={onEnglishChange} checked={english.includes('English Language Arts 2')} disabled={englishLanguageArts2StatusCompleted} />
                                                <label htmlFor="English Language Arts 2" className="ml-2">English Language Arts 2</label>
                                            </div>

                                            <div className="flex align-items-center">
                                                <Checkbox inputId="English Language Arts 3" name="English Language Arts 3" value="English Language Arts 3" onChange={onEnglishChange} checked={english.includes('English Language Arts 3')} disabled={englishLanguageArts3StatusCompleted} />
                                                <label htmlFor="English Language Arts 3" className="ml-2">English Language Arts 3</label>
                                            </div>

                                            <div className="flex align-items-center">
                                                <Checkbox inputId="English Language Arts 4" name="English Language Arts 4" value="English Language Arts 4" onChange={onEnglishChange} checked={english.includes('English Language Arts 4')} disabled={englishLanguageArts4StatusCompleted} />
                                                <label htmlFor="English Language Arts 4" className="ml-2">English Language Arts 4</label>
                                            </div>
                                        </div>
                                        <Divider />
                                        <div>
                                            <h3>Electives</h3>
                                            <div className="categories">
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="AP Language & Composition" name="AP Language & Composition" value="AP Language & Composition" onChange={onEnglishElectiveChange} checked={englishElective.includes('AP Language & Composition')} disabled={apLanguageCompositionStatusCompleted} />
                                                    <label htmlFor="AP Language & Composition" className="ml-2">AP Language & Composition</label>
                                                </div>

                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="AP Literature & Composition" name="AP Literature & Composition" value="AP Literature & Composition" onChange={onEnglishElectiveChange} checked={englishElective.includes('AP Literature & Composition')} disabled={apLiteratureCompositionStatusCompleted} />
                                                    <label htmlFor="AP Literature & Composition" className="ml-2">AP Literature & Composition</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {activeButton === "Social Studies" && (
                                    <div>
                                        <div className="categories">
                                            <div className="flex align-items-center">
                                                <Checkbox inputId="Participation In Democracy" name="Participation In Democracy" value="Participation In Democracy" onChange={onSocialStudiesChange} checked={socialStudies.includes('Participation In Democracy')} disabled={participationInDemocracyStatusCompleted} />
                                                <label htmlFor="Participation In Democracy" className="ml-2">Participation In Democracy</label>
                                            </div>
                                            <div className="flex align-items-center">
                                                <Checkbox inputId="Modern History Of Hawai`i" name="Modern History Of Hawai`i" value="Modern History Of Hawai`i" onChange={onSocialStudiesChange} checked={socialStudies.includes('Modern History Of Hawai`i')} disabled={modernHistoryOfHawaiiStatusCompleted} />
                                                <label htmlFor="Modern History Of Hawai`i" className="ml-2">Modern History Of Hawai`i</label>
                                            </div>
                                            <div className="flex align-items-center">
                                                <Checkbox inputId="World History" name="World History" value="World History" onChange={onSocialStudiesChange} checked={socialStudies.includes('World History')} disabled={worldHistoryStatusCompleted} />
                                                <label htmlFor="World History" className="ml-2">World History</label>
                                            </div>
                                            <div className="flex align-items-center">
                                                <Checkbox inputId="US History" name="US History" value="US History" onChange={onSocialStudiesChange} checked={socialStudies.includes('US History')} disabled={usHistoryStatusCompleted} />
                                                <label htmlFor="US History" className="ml-2">US History</label>
                                            </div>
                                            <div className="flex align-items-center">
                                                <Checkbox inputId="Economics" name="Economics" value="Economics" onChange={onSocialStudiesChange} checked={socialStudies.includes('Economics')} disabled={economicsStatusCompleted} />
                                                <label htmlFor="Economics" className="ml-2">Economics</label>
                                            </div>
                                        </div>
                                        <Divider />
                                        <div>
                                            <h3>Electives</h3>
                                            <div className="categories">
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="AP US History" name="AP US History" value="AP US History" onChange={onSocialStudiesElectiveChange} checked={socialStudiesElective.includes('AP US History')} disabled={apUsHistoryStatusCompleted} />
                                                    <label htmlFor="AP US History" className="ml-2">AP US History</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Hawaiian Studies" name="Hawaiian Studies" value="Hawaiian Studies" onChange={onSocialStudiesElectiveChange} checked={socialStudiesElective.includes('Hawaiian Studies')} disabled={hawaiianStudiesStatusCompleted} />
                                                    <label htmlFor="Hawaiian Studies" className="ml-2">Hawaiian Studies</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Geography" name="Geography" value="Geography" onChange={onSocialStudiesElectiveChange} checked={socialStudiesElective.includes('Geography')} disabled={geographyStatusCompleted} />
                                                    <label htmlFor="Geography" className="ml-2">Geography</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Psychology" name="Psychology" value="Psychology" onChange={onSocialStudiesElectiveChange} checked={socialStudiesElective.includes('Psychology')} disabled={psychologyStatusCompleted} />
                                                    <label htmlFor="Psychology" className="ml-2">Psychology</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="AP Psychology I/II" name="AP Psychology I/II" value="AP Psychology I/II" onChange={onSocialStudiesElectiveChange} checked={socialStudiesElective.includes('AP Psychology I/II')} disabled={apPsychologyStatusCompleted} />
                                                    <label htmlFor="AP Psychology I/II" className="ml-2">AP Psychology I/II</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {activeButton === "Math" && (
                                    <div>
                                        <div className="categories">
                                            <div className="flex align-items-center">
                                                <Checkbox inputId="Geometry" name="Geometry" value="Geometry" onChange={onMathChange} checked={math.includes('Geometry')} disabled={geometryCompleted} />
                                                <label htmlFor="Geometry" className="ml-2">Geometry</label>
                                            </div>
                                            <div className="flex align-items-center">
                                                <Checkbox inputId="Algebra 1" name="Algebra 1" value="Algebra 1" onChange={onMathChange} checked={math.includes('Algebra 1')} disabled={algebra1Completed} />
                                                <label htmlFor="Algebra 1" className="ml-2">Algebra 1</label>
                                            </div>
                                        </div>
                                        <Divider />
                                        <div>
                                            <h3>Electives</h3>
                                            <div className="categories">
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Algebra 2" name="Algebra 2" value="Algebra 2" onChange={onMathElectiveChange} checked={mathElective.includes('Algebra 2')} disabled={algebra2Completed} />
                                                    <label htmlFor="Algebra 2" className="ml-2">Algebra 2</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Introduction To College Mathematics" name="Introduction To College Mathematics" value="Introduction To College Mathematics" onChange={onMathElectiveChange} checked={mathElective.includes('Introduction To College Mathematics')} disabled={introductionToCollegeMathematicsCompleted} />
                                                    <label htmlFor="Introduction To College Mathematics" className="ml-2">Introduction To College Mathematics</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Precalculus" name="Precalculus" value="Precalculus" onChange={onMathElectiveChange} checked={mathElective.includes('Precalculus')} disabled={precalculusCompleted} />
                                                    <label htmlFor="Precalculus" className="ml-2">Precalculus</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Trigonometry" name="Trigonometry" value="Trigonometry" onChange={onMathElectiveChange} checked={mathElective.includes('Trigonometry')} disabled={trigonometryCompleted} />
                                                    <label htmlFor="Trigonometry" className="ml-2">Trigonometry</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="AP Computer Science Principles" name="AP Computer Science Principles" value="AP Computer Science Principles" onChange={onMathElectiveChange} checked={mathElective.includes('AP Computer Science Principles')} disabled={apComputerSciencePrinciplesCompleted} />
                                                    <label htmlFor="AP Computer Science Principles" className="ml-2">AP Computer Science Principles</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {activeButton === "Science" && (
                                    <div>
                                        <div className="categories">
                                            <div className="flex align-items-center">
                                                <Checkbox inputId="Biology" name="Biology" value="Biology" onChange={onScienceChange} checked={science.includes('Biology')} disabled={biologyCompleted} />
                                                <label htmlFor="Biology" className="ml-2">Biology</label>
                                            </div>
                                        </div>
                                        <Divider />
                                        <div>
                                            <h3>Electives</h3>
                                            <div className="categories">
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Earth System Science" name="Earth System Science" value="Earth System Science" onChange={onScienceElectiveChange} checked={scienceElective.includes('Earth System Science')} disabled={earthSystemScienceCompleted} />
                                                    <label htmlFor="Earth System Science" className="ml-2">Earth System Science</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Marine Science" name="Marine Science" value="Marine Science" onChange={onScienceElectiveChange} checked={scienceElective.includes('Marine Science')} disabled={marineScienceCompleted} />
                                                    <label htmlFor="Marine Science" className="ml-2">Marine Science</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Human Physiology" name="Human Physiology" value="Human Physiology" onChange={onScienceElectiveChange} checked={scienceElective.includes('Human Physiology')} disabled={humanPhysiologyCompleted} />
                                                    <label htmlFor="Human Physiology" className="ml-2">Human Physiology</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Chemistry" name="Chemistry" value="Chemistry" onChange={onScienceElectiveChange} checked={scienceElective.includes('Chemistry')} disabled={chemistryCompleted} />
                                                    <label htmlFor="Chemistry" className="ml-2">Chemistry</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Honor Chemistry" name="Honor Chemistry" value="Honor Chemistry" onChange={onScienceElectiveChange} checked={scienceElective.includes('Honor Chemistry')} disabled={honorChemistryCompleted} />
                                                    <label htmlFor="Honor Chemistry" className="ml-2">Honor Chemistry</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Physics" name="Physics" value="Physics" onChange={onScienceElectiveChange} checked={scienceElective.includes('Physics')} disabled={physicsCompleted} />
                                                    <label htmlFor="Physics" className="ml-2">Physics</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="AP Biology" name="AP Biology" value="AP Biology" onChange={onScienceElectiveChange} checked={scienceElective.includes('AP Biology')} disabled={apBiologyCompleted} />
                                                    <label htmlFor="AP Biology" className="ml-2">AP Biology</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {activeButton === "Physical Education" && (
                                    <div>
                                        <div className="categories">
                                            <div className="flex align-items-center">
                                                <Checkbox inputId="Physical Education Lifetime Fitness" name="Physical Education Lifetime Fitness" value="Physical Education Lifetime Fitness" onChange={onPhysicalEducationChange} checked={physicalEducation.includes('Physical Education Lifetime Fitness')} disabled={physicalEducationLifetimeFitnessCompleted} />
                                                <label htmlFor="Physical Education Lifetime Fitness" className="ml-2">Physical Education Lifetime Fitness</label>
                                            </div>
                                        </div>
                                        <Divider />
                                        <div>
                                            <h3>Electives</h3>
                                            <div className="categories">
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Body Conditioning" name="Body Conditioning" value="Body Conditioning" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Body Conditioning')} disabled={bodyConditioningCompleted} />
                                                    <label htmlFor="Body Conditioning" className="ml-2">Body Conditioning</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Team Sports 1" name="Team Sports 1" value="Team Sports 1" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Team Sports 1')} disabled={teamSports1Completed} />
                                                    <label htmlFor="Team Sports 1" className="ml-2">Team Sports 1</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Team Sports 2" name="Team Sports 2" value="Team Sports 2" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Team Sports 2')} disabled={teamSports2Completed} />
                                                    <label htmlFor="Team Sports 2" className="ml-2">Team Sports 2</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Weight / Resistance 1A" name="Weight / Resistance 1A" value="Weight / Resistance 1A" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Weight / Resistance 1A')} disabled={weightResistance1ACompleted} />
                                                    <label htmlFor="Weight / Resistance 1A" className="ml-2">Weight / Resistance 1A</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Weight / Resistance 1B" name="Weight / Resistance 1B" value="Weight / Resistance 1B" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Weight / Resistance 1B')} disabled={weightResistance1BCompleted} />
                                                    <label htmlFor="Weight / Resistance 1B" className="ml-2">Weight / Resistance 1B</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Weight / Resistance 2A" name="Weight / Resistance 2A" value="Weight / Resistance 2A" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Weight / Resistance 2A')} disabled={weightResistance2ACompleted} />
                                                    <label htmlFor="Weight / Resistance 2A" className="ml-2">Weight / Resistance 2A</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Weight / Resistance 2B" name="Weight / Resistance 2B" value="Weight / Resistance 2B" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Weight / Resistance 2B')} disabled={weightResistance2BCompleted} />
                                                    <label htmlFor="Weight / Resistance 2B" className="ml-2">Weight / Resistance 2B</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Recreational Leadership" name="Recreational Leadership" value="Recreational Leadership" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Recreational Leadership')} disabled={recreationalLeadershipCompleted} />
                                                    <label htmlFor="Recreational Leadership" className="ml-2">Recreational Leadership</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {activeButton === "Health" && (
                                    <div className="categories">
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="Health Today/ Tomorrow" name="Health Today/ Tomorrow" value="Health Today/ Tomorrow" onChange={onHealthChange} checked={health.includes('Health Today/ Tomorrow')} disabled={healthCompleted} />
                                            <label htmlFor="Health Today/ Tomorrow" className="ml-2">Health Today/ Tomorrow</label>
                                        </div>
                                    </div>
                                )}

                                {activeButton === "Personal Transition Plan" && (
                                    <div className="categories">
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="Personal Transition Plan" name="Personal Transition Plan" value="Personal Transition Plan" onChange={onPTPChange} checked={ptp.includes('Personal Transition Plan')} disabled={ptpCompleted} />
                                            <label htmlFor="Personal Transition Plan" className="ml-2">Personal Transition Plan</label>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>

                        <Electives getApiResponse={getApiResponse} />

                    </div>

                    {window.innerWidth < 1008 && (
                        <>
                            <div className="mob-GR-form">
                                <div className="mob-top-section-buttons flex flex-row flex-wrap gap-4">
                                    {Object.keys(graduationRequirements).map((label) => (
                                        <div className="mob-button-section text-center">
                                            <div
                                                key={label}
                                                className={
                                                    label === activeButton
                                                        ? "category-btn-active"
                                                        : "category-btn"
                                                }
                                                onClick={() => handleButtonClick(label)}
                                            >
                                                {label}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="mob-electives-section flex mt-4">
                                    <div className="mob-electives-display flex-row flex-wrap gap-3">
                                        {activeButton === "English" && (
                                            <>
                                                <div className="mob-categories">
                                                    <div className="flex align-items-center">
                                                        <Checkbox inputId="English Language Arts 1" name="English Language Arts 1" value="English Language Arts 1" onChange={onEnglishChange} checked={english.includes("English Language Arts 1")} disabled={englishLanguageArts1StatusCompleted} />
                                                        <label htmlFor="English Language Arts 1" className="ml-2">  English Language Arts 1 </label>
                                                    </div>
                                                    <div className="flex align-items-center">
                                                        <Checkbox inputId="English Language Arts 2" name="English Language Arts 2" value="English Language Arts 2" onChange={onEnglishChange} checked={english.includes("English Language Arts 2")} disabled={englishLanguageArts2StatusCompleted} />
                                                        <label htmlFor="English Language Arts 2" className="ml-2"> English Language Arts 2 </label>
                                                    </div>
                                                    <div className="flex align-items-center">
                                                        <Checkbox inputId="English Language Arts 3" name="English Language Arts 3" value="English Language Arts 3" onChange={onEnglishChange} checked={english.includes("English Language Arts 3")} disabled={englishLanguageArts3StatusCompleted} />
                                                        <label htmlFor="English Language Arts 3" className="ml-2"> English Language Arts 3 </label>
                                                    </div>
                                                    <div className="flex align-items-center">
                                                        <Checkbox inputId="English Language Arts 4" name="English Language Arts 4" value="English Language Arts 4" onChange={onEnglishChange} checked={english.includes("English Language Arts 4")} disabled={englishLanguageArts4StatusCompleted} />
                                                        <label htmlFor="English Language Arts 4" className="ml-2">  English Language Arts 4 </label>
                                                    </div>
                                                </div>
                                                <div className='science-list '>
                                                    <div className="dividee">
                                                        <h3>Electives</h3>
                                                    </div>
                                                    <div className="mob-categories mt-4">
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="AP Language & Composition" name="AP Language & Composition" value="AP Language & Composition" onChange={onEnglishElectiveChange} checked={englishElective.includes("AP Language & Composition")} disabled={apLanguageCompositionStatusCompleted} />
                                                            <label htmlFor="AP Language & Composition" className="ml-2" > AP Language & Composition</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="AP Literature & Composition" name="AP Literature & Composition" value="AP Literature & Composition" onChange={onEnglishElectiveChange} checked={englishElective.includes("AP Literature & Composition")} disabled={apLiteratureCompositionStatusCompleted} />
                                                            <label htmlFor="AP Literature & Composition" className="ml-2"  > AP Literature & Composition </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {activeButton === "Social Studies" && (
                                            <>
                                                <div className="mob-categories">
                                                    <div className="flex align-items-center">
                                                        <Checkbox inputId="Participation In Democracy" name="Participation In Democracy" value="Participation In Democracy" onChange={onSocialStudiesChange} checked={socialStudies.includes("Participation In Democracy")} disabled={participationInDemocracyStatusCompleted} />
                                                        <label htmlFor="Participation In Democracy" className="ml-2"> Participation In Democracy</label>
                                                    </div>
                                                    <div className="flex align-items-center">
                                                        <Checkbox inputId="Modern History Of Hawai`i" name="Modern History Of Hawai`i" value="Modern History Of Hawai`i" onChange={onSocialStudiesChange} checked={socialStudies.includes("Modern History Of Hawai`i")} disabled={modernHistoryOfHawaiiStatusCompleted} />
                                                        <label htmlFor="Modern History Of Hawai`i" className="ml-2"> Modern History Of Hawai`i </label>
                                                    </div>
                                                    <div className="flex align-items-center">
                                                        <Checkbox inputId="World History" name="World History" value="World History" onChange={onSocialStudiesChange} checked={socialStudies.includes("World History")} disabled={worldHistoryStatusCompleted} />
                                                        <label htmlFor="World History" className="ml-2"> World History </label>
                                                    </div>
                                                    <div className="flex align-items-center">
                                                        <Checkbox inputId="US History" name="US History" value="US History" onChange={onSocialStudiesChange} checked={socialStudies.includes("US History")} disabled={usHistoryStatusCompleted} />
                                                        <label htmlFor="US History" className="ml-2"> US History </label>
                                                    </div>
                                                    <div className="flex align-items-center">
                                                        <Checkbox inputId="Economics" name="Economics" value="Economics" onChange={onSocialStudiesChange} checked={socialStudies.includes("Economics")} disabled={economicsStatusCompleted} />
                                                        <label htmlFor="Economics" className="ml-2"> Economics </label>
                                                    </div>
                                                </div>
                                                <div className='science-list '>
                                                    <div className="dividee">
                                                        <h3>Electives</h3>
                                                    </div>
                                                    <div className="mob-categories mt-4">
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="AP US History" name="AP US History" value="AP US History" onChange={onSocialStudiesElectiveChange} checked={socialStudiesElective.includes("AP US History")} disabled={apUsHistoryStatusCompleted} />
                                                            <label htmlFor="AP US History" className="ml-2">AP US History</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Hawaiian Studies" name="Hawaiian Studies" value="Hawaiian Studies" onChange={onSocialStudiesElectiveChange} checked={socialStudiesElective.includes('Hawaiian Studies')} disabled={hawaiianStudiesStatusCompleted} />
                                                            <label htmlFor="Hawaiian Studies" className="ml-2">Hawaiian Studies</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Geography" name="Geography" value="Geography" onChange={onSocialStudiesElectiveChange} checked={socialStudiesElective.includes('Geography')} disabled={geographyStatusCompleted} />
                                                            <label htmlFor="Geography" className="ml-2">Geography</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Psychology" name="Psychology" value="Psychology" onChange={onSocialStudiesElectiveChange} checked={socialStudiesElective.includes('Psychology')} disabled={psychologyStatusCompleted} />
                                                            <label htmlFor="Psychology" className="ml-2">Psychology</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="AP Psychology I/II" name="AP Psychology I/II" value="AP Psychology I/II" onChange={onSocialStudiesElectiveChange} checked={socialStudiesElective.includes('AP Psychology I/II')} disabled={apPsychologyStatusCompleted} />
                                                            <label htmlFor="AP Psychology I/II" className="ml-2">AP Psychology I/II</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {activeButton === "Math" && (
                                            <div>
                                                <div className="mob-categories">
                                                    {/* <div className="math-head">
                                                        <span>
                                                            You must complete{" "}
                                                            <span className="cre">3 credits</span> at{" "}
                                                            <span className="cre">Math</span> to get your High
                                                            School Diploma
                                                        </span>
                                                    </div> */}
                                                    <div className="flex align-items-center">
                                                        <Checkbox inputId="Geometry" name="Geometry" value="Geometry" onChange={onMathChange} checked={math.includes('Geometry')} disabled={geometryCompleted} />
                                                        <label htmlFor="Geometry" className="ml-2">Geometry</label>
                                                    </div>
                                                    <div className="flex align-items-center">
                                                        <Checkbox inputId="Algebra 1" name="Algebra 1" value="Algebra 1" onChange={onMathChange} checked={math.includes('Algebra 1')} disabled={algebra1Completed} />
                                                        <label htmlFor="Algebra 1" className="ml-2">Algebra 1</label>
                                                    </div>
                                                </div>
                                                <div className="elective-subjects">
                                                    <div className='science-list '>
                                                        <div className="dividee">
                                                            <h3>Electives</h3>
                                                        </div>
                                                        <div className="mob-categories mt-4">
                                                            <div className="flex align-items-center">
                                                                <Checkbox inputId="Algebra 2" name="Algebra 2" value="Algebra 2" onChange={onMathElectiveChange} checked={mathElective.includes('Algebra 2')} disabled={algebra2Completed} />
                                                                <label htmlFor="Algebra 2" className="ml-2">Algebra 2</label>
                                                            </div>
                                                            <div className="flex align-items-center">
                                                                <Checkbox inputId="Introduction To College Mathematics" name="Introduction To College Mathematics" value="Introduction To College Mathematics" onChange={onMathElectiveChange} checked={mathElective.includes('Introduction To College Mathematics')} disabled={introductionToCollegeMathematicsCompleted} />
                                                                <label htmlFor="Introduction To College Mathematics" className="ml-2">Introduction To College Mathematics</label>
                                                            </div>
                                                            <div className="flex align-items-center">
                                                                <Checkbox inputId="Precalculus" name="Precalculus" value="Precalculus" onChange={onMathElectiveChange} checked={mathElective.includes('Precalculus')} disabled={precalculusCompleted} />
                                                                <label htmlFor="Precalculus" className="ml-2">Precalculus</label>
                                                            </div>
                                                            <div className="flex align-items-center">
                                                                <Checkbox inputId="Trigonometry" name="Trigonometry" value="Trigonometry" onChange={onMathElectiveChange} checked={mathElective.includes('Trigonometry')} disabled={trigonometryCompleted} />
                                                                <label htmlFor="Trigonometry" className="ml-2">Trigonometry</label>
                                                            </div>
                                                            <div className="flex align-items-center">
                                                                <Checkbox inputId="AP Computer Science Principles" name="AP Computer Science Principles" value="AP Computer Science Principles" onChange={onMathElectiveChange} checked={mathElective.includes('AP Computer Science Principles')} disabled={apComputerSciencePrinciplesCompleted} />
                                                                <label htmlFor="AP Computer Science Principles" className="ml-2">AP Computer Science Principles</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {activeButton === "Science" && (
                                            <div>
                                                <div className="mob-categories">
                                                    <div className="flex align-items-center">
                                                        <Checkbox inputId="Biology" name="Biology" value="Biology" onChange={onScienceChange} checked={science.includes('Biology')} disabled={biologyCompleted} />
                                                        <label htmlFor="Biology" className="ml-2">Biology</label>
                                                    </div>
                                                </div>

                                                <div className='science-list '>
                                                    <div className="dividee">
                                                        <h3>Electives</h3>
                                                    </div>
                                                    <div className="mob-categories mt-4">
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Earth System Science" name="Earth System Science" value="Earth System Science" onChange={onScienceElectiveChange} checked={scienceElective.includes('Earth System Science')} disabled={earthSystemScienceCompleted} />
                                                            <label htmlFor="Earth System Science" className="ml-2">Earth System Science</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Marine Science" name="Marine Science" value="Marine Science" onChange={onScienceElectiveChange} checked={scienceElective.includes('Marine Science')} disabled={marineScienceCompleted} />
                                                            <label htmlFor="Marine Science" className="ml-2">Marine Science</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Human Physiology" name="Human Physiology" value="Human Physiology" onChange={onScienceElectiveChange} checked={scienceElective.includes('Human Physiology')} disabled={humanPhysiologyCompleted} />
                                                            <label htmlFor="Human Physiology" className="ml-2">Human Physiology</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Chemistry" name="Chemistry" value="Chemistry" onChange={onScienceElectiveChange} checked={scienceElective.includes('Chemistry')} disabled={chemistryCompleted} />
                                                            <label htmlFor="Chemistry" className="ml-2">Chemistry</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Honor Chemistry" name="Honor Chemistry" value="Honor Chemistry" onChange={onScienceElectiveChange} checked={scienceElective.includes('Honor Chemistry')} disabled={honorChemistryCompleted} />
                                                            <label htmlFor="Honor Chemistry" className="ml-2">Honor Chemistry</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Physics" name="Physics" value="Physics" onChange={onScienceElectiveChange} checked={scienceElective.includes('Physics')} disabled={physicsCompleted} />
                                                            <label htmlFor="Physics" className="ml-2">Physics</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="AP Biology" name="AP Biology" value="AP Biology" onChange={onScienceElectiveChange} checked={scienceElective.includes('AP Biology')} disabled={apBiologyCompleted} />
                                                            <label htmlFor="AP Biology" className="ml-2">AP Biology</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {activeButton === "Physical Education" && (
                                            <div>
                                                <div className="mob-categories">
                                                    <div className="flex align-items-center">
                                                        <Checkbox inputId="Physical Education Lifetime Fitness" name="Physical Education Lifetime Fitness" value="Physical Education Lifetime Fitness" onChange={onPhysicalEducationChange} checked={physicalEducation.includes('Physical Education Lifetime Fitness')} disabled={physicalEducationLifetimeFitnessCompleted} />
                                                        <label htmlFor="Physical Education Lifetime Fitness" className="ml-2">Physical Education Lifetime Fitness</label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="dividee">
                                                        {" "}
                                                        <h3>Electives</h3>
                                                    </div>

                                                    <div className="mob-categories mt-4">
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Body Conditioning" name="Body Conditioning" value="Body Conditioning" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Body Conditioning')} disabled={bodyConditioningCompleted} />
                                                            <label htmlFor="Body Conditioning" className="ml-2">Body Conditioning</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Team Sports 1" name="Team Sports 1" value="Team Sports 1" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Team Sports 1')} disabled={teamSports1Completed} />
                                                            <label htmlFor="Team Sports 1" className="ml-2">Team Sports 1</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Team Sports 2" name="Team Sports 2" value="Team Sports 2" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Team Sports 2')} disabled={teamSports2Completed} />
                                                            <label htmlFor="Team Sports 2" className="ml-2">Team Sports 2</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Weight / Resistance 1A" name="Weight / Resistance 1A" value="Weight / Resistance 1A" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Weight / Resistance 1A')} disabled={weightResistance1ACompleted} />
                                                            <label htmlFor="Weight / Resistance 1A" className="ml-2">Weight / Resistance 1A</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Weight / Resistance 1B" name="Weight / Resistance 1B" value="Weight / Resistance 1B" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Weight / Resistance 1B')} disabled={weightResistance1BCompleted} />
                                                            <label htmlFor="Weight / Resistance 1B" className="ml-2">Weight / Resistance 1B</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Weight / Resistance 2A" name="Weight / Resistance 2A" value="Weight / Resistance 2A" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Weight / Resistance 2A')} disabled={weightResistance2ACompleted} />
                                                            <label htmlFor="Weight / Resistance 2A" className="ml-2">Weight / Resistance 2A</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Weight / Resistance 2B" name="Weight / Resistance 2B" value="Weight / Resistance 2B" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Weight / Resistance 2B')} disabled={weightResistance2BCompleted} />
                                                            <label htmlFor="Weight / Resistance 2B" className="ml-2">Weight / Resistance 2B</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Recreational Leadership" name="Recreational Leadership" value="Recreational Leadership" onChange={onPhysicalEducationElectiveChange} checked={physicalEducationElective.includes('Recreational Leadership')} disabled={recreationalLeadershipCompleted} />
                                                            <label htmlFor="Recreational Leadership" className="ml-2">Recreational Leadership</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {activeButton === "Health" && (
                                            <div className="mob-categories">
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Health Today/ Tomorrow" name="Health Today/ Tomorrow" value="Health Today/ Tomorrow" onChange={onHealthChange} checked={health.includes('Health Today/ Tomorrow')} disabled={healthCompleted} />
                                                    <label htmlFor="Health Today/ Tomorrow" className="ml-2">Health Today/ Tomorrow</label>
                                                </div>
                                            </div>
                                        )}

                                        {activeButton === "Personal Transition Plan" && (
                                            <div className="categories">
                                                <div className="flex align-items-center">
                                                    <Checkbox inputId="Personal Transition Plan" name="Personal Transition Plan" value="Personal Transition Plan" onChange={onPTPChange} checked={ptp.includes('Personal Transition Plan')} disabled={ptpCompleted} />
                                                    <label htmlFor="Personal Transition Plan" className="ml-2">Personal Transition Plan</label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Electives getApiResponse={getApiResponse} />
                            </div>
                        </>
                    )}

                    <footer className="Extra-activities-form-footer gap-4">
                        <Button
                            className="course-button"
                            label="Cancel"
                            severity="info"
                            onClick={handleCancel}
                        />
                        <Button
                            type="submit"
                            value="Submit"
                            label="Add Course(s)"
                            severity="secondary"
                            onClick={showCoursesDetails}
                        />
                    </footer>
                </>
                :
                ""
            }
            {
                coursesList.length > 0 ? (
                    <>
                        <div className="spinner-section">
                            {loading ? <Spinner /> : null}
                        </div>
                    </>
                ) : (
                    !courses && (
                        <div className="course-complete-img flex flex-column justify-content-center align-items-center">
                            <Image src={Illustration} alt="Image" width="550px" />
                            <p>
                                You will see information here when you provide your high school courses
                            </p>

                            <Button className='m-3' label="Add high school courses" severity="secondary" onClick={handleCourses} />

                        </div>
                    )
                )
            }
        </div >
    );
};

export default HighSchoolProgressCourses;