// Base Url
export const BASE_URL = `${process.env.REACT_APP_URL}`;

// export const BASE_URL = 'https://backend.devl.laulamaschools.com/laulama';

// Headers
export const AUTHORIZATION_HEADERS = {
    'Authorization': 'Bearer ' + localStorage.getItem('userAccessToken'),
};

export const CONTENT_HEADERS = {
    "content-type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('userAccessToken'),
};


export const CONTENT_MULTIPART_HEADERS = {
    'Content-Type':'multipart/form-data',
    'Authorization': 'Bearer ' + localStorage.getItem('userAccessToken'),
};


// API Endpoints

// Student Details

export const GET_STUDENT_DETAILS = `${BASE_URL}/users/${localStorage.getItem('userId')}`;

//  Graduation Requirements

export const GET_GRADUATION_REQUIREMENTS = `${BASE_URL}/checklist/credits/${localStorage.getItem('userId')}`;

export const GET_GRADUATION_REQUIREMENTS_DETAILS = `${BASE_URL}/checklist/credits/`;

// Personal Statement

export const PERSONAL_STATEMENT = `${BASE_URL}/personal_statement`;

export const GET_PERSONAL_STATEMENT = `${BASE_URL}/personal_statement/${localStorage.getItem('userId')}`;

export const UPDATE_PERSONAL_STATEMENT = `${BASE_URL}/personal_statement`;

// Career Goals endpoints

export const CAREER_GOALS_URL = `${BASE_URL}/careergoal`;

export const CAREER_GOALS_URL_HEADERS = {
    'Authorization': 'Bearer ' + sessionStorage.getItem('userAccessToken'),
};

export const GET_CAREER_GOALS_URL = `${BASE_URL}/careergoal/`;

// Academic Goals endpoints

export const ACADEMIC_GOALS = `${BASE_URL}/academic_goals`;

export const GET_ACADEMIC_GOALS = `${BASE_URL}/academic_goals/${localStorage.getItem('userId')}`;


// Academic Pathway endpoints

export const ACADEMIC_PATHWAYS = `${BASE_URL}/academies-pathways`;

export const GET_ACADEMIC_PATHWAYS = `${BASE_URL}/academies-pathways/student/${localStorage.getItem('userId')}`;

export const UPDATE_ACADEMIC_PATHWAYS = `${BASE_URL}/student/${localStorage.getItem('userId')}`;

// CheckList Program endpoints

export const CHECKLIST_PROGRESS = `${BASE_URL}/checklist`;

export const GET_CHECKLIST_PROGRESS = `${BASE_URL}/checklist/user/${localStorage.getItem('userId')}`;

export const UPDATE_CHECKLIST_PROGRESS = `${BASE_URL}/checklist/user/${localStorage.getItem('userId')}`;

// High School Progress endpoints

export const GET_HIGH_SCHOOL_PROGRESS = `${BASE_URL}/highSchool_progress/user/${localStorage.getItem('userId')}`;

export const HIGH_SCHOOL_PROGRESS = `${BASE_URL}/highSchool_progress`;

export const UPDATE_HIGH_SCHOOL_PROGRESS = `${BASE_URL}/highSchool_progress/updateCourse/`;

export const GET_HIGH_SCHOOL_CREDITS = `${BASE_URL}/highSchool_progress/credits/${localStorage.getItem('userId')}`;

// Test Scores endpoints

export const TEST_SCORES = `${BASE_URL}/test_scores`;

export const GET_TEST_SCORES = `${BASE_URL}/test_scores/user/${localStorage.getItem('userId')}`;

// Extra Activities endpoints

export const EXTRA_ACTIVITIES = `${BASE_URL}/extra_activities`;

export const GET_EXTRA_ACTIVITIES = `${BASE_URL}/extra_activities/${localStorage.getItem('userId')}`;



export const STUDENT_INFO =`${BASE_URL}/users/`;

export const STUDENT_INFO_HIGH_SCHOOL_CREDITS = `${BASE_URL}/highSchool_progress/credits/`;

//profile endpoints

export const UPDATE_PROFILE_URL = `${BASE_URL}/users/student/update/${localStorage.getItem('userId')}`;

export const GET_RESUME_URL = `${BASE_URL}/users/resume/`;

// Student progress report

export const Student_Progress_Report = `${BASE_URL}/student-progress/${localStorage.getItem('userId')}`;