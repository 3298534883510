import React, { useState, useEffect } from 'react'
import "./extra-activities.scss";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import Illustration from '../../../../../../Assets/Student/tableilustration.svg';
import { Image } from 'primereact/image';
import moment from 'moment';


const ExtraActivities = ({getApiResponse}) => {

   
    const [toggleForm, setToggleForm] = useState(false);
    const [newData, setNewData] = useState([]);
    const localStorageKey = 'ExtraActivities';
    const [editIndex, setEditIndex] = useState(null);

    const data = {
        currentActivity: false,
        extraCurricularActivity: '',
        positionHeld: '',
        startDate: '',
        endDate: null,
        description: '',
    };

    const [formData, setFormData] = useState(data);

    useEffect(() => {
        const storedData = localStorage.getItem(localStorageKey);
        if (storedData) {
            setNewData(JSON.parse(storedData));
        }
    }, []);

    useEffect(() => {
        if (getApiResponse?.extraCurricularActivities?.length > 0) {
          const response = getApiResponse?.achievements;
          const latestVersionNumber = ((response.length) - 1);
          const latestExtraCurricularActivities = getApiResponse?.extraCurricularActivities[latestVersionNumber];
          if (latestExtraCurricularActivities?.activities.length > 0) {
            const getExtraCurricularActivities = latestExtraCurricularActivities.activities.map((item) => {
                var startDateVal = item.startDate;
                const date = new Date(startDateVal);
                const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
                const year = date.getUTCFullYear();
                const formattedStartDate =  `${month}/${year}`;
                if(item.currentActivity) {
                    var formattedEndDate = null; 
                 } else {
                var endDateVal = item.endDate;
                const edate = new Date(endDateVal);
                const emonth = (edate.getUTCMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
                const eyear = edate.getUTCFullYear();
                var formattedEndDate =  `${emonth}/${eyear}`;
                 }
              return {
                currentActivity: item?.currentActivity,
                extraCurricularActivity: item?.extraCurricularActivity,
                positionHeld: item?.positionHeld,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                description: item?.description,
                
              };
            });
            setNewData(getExtraCurricularActivities);
            localStorage.setItem(localStorageKey, JSON.stringify(getExtraCurricularActivities));
          }
        }
      }, [getApiResponse]);

      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      };

      const handleCheckboxChange = (e) => {
        setFormData({
          ...formData,
          currentActivity: e.checked,
        });
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        var selected_startdate = moment.utc(formData.startDate).format('MM/YYYY');
        if(formData.currentActivity) {
            var selected_enddate = null;
        } else {
            var selected_enddate = moment.utc(formData.endDate).format('MM/YYYY');
        }
        const newTableData = {
            currentActivity: formData.currentActivity,
            extraCurricularActivity: formData.extraCurricularActivity,
            positionHeld: formData.positionHeld,
            startDate: selected_startdate,
            endDate: selected_enddate,
            description: formData.description,
        };

        if (editIndex !== null) {
            const updatedData = [...newData];
            var selected_startdate = moment.utc(formData.startDate).format('MM/YYYY');
            if(formData.currentActivity) {
                var selected_enddate = null;
            } else {
                var selected_enddate = moment.utc(formData.endDate).format('MM/YYYY');
            }
                const editedData = {
                    currentActivity: formData.currentActivity,
                    extraCurricularActivity: formData.extraCurricularActivity,
                    positionHeld: formData.positionHeld,
                    startDate: selected_startdate,
                    endDate: selected_enddate,
                    description: formData.description,
                };
            updatedData[editIndex] = editedData;
            setNewData(updatedData);
            setEditIndex(null);
            setFormData(data);
            localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
          } else {

        const updatedFormData = [...newData, newTableData];
        
        setFormData(data);

        localStorage.setItem(localStorageKey, JSON.stringify(updatedFormData));
          }
        const storedData = localStorage.getItem(localStorageKey);
        if (storedData) {
            setNewData(JSON.parse(storedData));
        }

        handleClick();
      };

      const handleClick = () => {
        setEditIndex(null);
        setFormData(data);
        setToggleForm(!toggleForm);
      };
    
      const handleEdit = (rowData, index) => {
        setToggleForm(true);
        const startDateValue = rowData.startDate;
        const [month, year] = startDateValue.split('/');
        const formattedStartDate = new Date(`${month}/01/${year}`);
        if(rowData.currentActivity) {
            var formattedEndDate = null;
        } else {
            const endDateValue = rowData.endDate;
            const [emonth, eyear] = endDateValue.split('/');
            var formattedEndDate = new Date(`${emonth}/01/${eyear}`);
        }
        setFormData({ ...rowData, startDate: formattedStartDate, endDate: formattedEndDate });
        setEditIndex(index);
      };
    
      const handleDelete = (index) => {
        const updatedData = [...newData];
        updatedData.splice(index, 1);
        setNewData(updatedData);
        setEditIndex(null);
        localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
        setFormData(data);
      };

      const renderCell = (rowData, field) => {
        const value = rowData[field];
        return value !== null ? value : "Present Activity";
      };
    

    return (
        <div className='extra-activities-section'>

            {toggleForm ?
                <div className='extra-heading'>
                    <div className='pt-1 pb-2'>Involve clubs, sports, activities and learning experiences that complement what is learned in school</div>
                    <form onSubmit={handleSubmit}>

                    <div className="flex flex-row align-items-center gap-2 pt-3 pb-3">
                        <Checkbox inputId="currentActivity" checked={formData.currentActivity} onChange={handleCheckboxChange} />
                        <label htmlFor="currentActivity">Current Activity</label>
                    </div>

                    <div className="flex flex-column gap-2 pt-2">
                        <label>Name of the Extra Activity</label>
                        <InputText name="extraCurricularActivity" value={formData.extraCurricularActivity} onChange={handleChange} required />
                    </div>

                    <div className="grid pt-4 mobile-extraactivities-field">
                        <div className="col lg:col-4 flex flex-column date">
                            <label>Position</label>
                            <InputText name="positionHeld" value={formData.positionHeld} onChange={handleChange} required/>
                        </div>
                        <div className="col lg:col-4 flex flex-column date">
                            <label>Start Date(MM/YYYY)</label>
                            <Calendar name="startDate" value={formData.startDate} onChange={handleChange} view="month" dateFormat="mm/yy" showIcon  required/>
                        </div>
                        <div className="col lg:col-4 flex date flex-column">
                            <label>End Date(MM/YYYY)</label>
                            <Calendar name="endDate" value={formData.endDate} onChange={handleChange} view="month" dateFormat="mm/yy" disabled={formData.currentActivity} showIcon  required/>
                        </div>
                    </div>

                        <div className="flex flex-wrap justify-content-between gap-3 pt-4 pb-4">
                            <div className="flex flex-column gap-2 w-full">
                                <label>Description</label>
                                <InputTextarea name="description" className='description w-full' value={formData.description} onChange={handleChange} rows={5} cols={120} required />
                            </div>
                        </div>

                    <footer className="Extra-activities-form-footer gap-4">
                    <Button label="Cancel" severity="info" onClick={handleClick} />
                    <Button type="submit" value="Submit" label={editIndex !== null ? "Update Extra Activity" : "Add Extra Activity"} severity="secondary" />
                    </footer>
                    </form>
                </div>
                :
                <div>
                    <div className="grid pt-4 pb-4 notes-alignment" onClick={handleClick}>
                        <div className='col lg:col-9'>Involve clubs, sports, activities and learning experiences that complement what is learned in school.</div>
                        <div className="col lg:col-3 addextra-text">+ Add Extra Activities</div>
                    </div>


                    {newData.length === 0 ? (
                        <div className="flex flex-column justify-content-center align-items-center">
                            <Image src={Illustration} alt="Image" className='extra-image' />
                            <p className='ex-p'>You will see information here when you add your Extracurricular Activities</p>
                            <Button className='m-3' label="Add Extra Activities" severity="secondary" onClick={handleClick}/>
                        </div>
                    ) : (
                        <div className="extra-activities-tables pt-4 pb-4">
                            <DataTable value={newData} tableStyle={{ minWidth: '50rem' }} className='datatable-container'>
                                <Column field="extraCurricularActivity" header="Name of the Activity" />
                                <Column field="positionHeld" header="Position" />
                                <Column field="startDate" header="Start Date" />
                                <Column field="endDate" header="End Date" body={(rowData) => renderCell(rowData, 'endDate')} />
                                <Column field="description" header="Description" />
                                <Column
                                    header="Actions"
                                    body={(rowData, column) => (
                                    <div className="actions">
                                        <div>
                                        <Button
                                            icon="pi pi-pencil"
                                            className="p-button-rounded p-button-success mr-1"
                                            onClick={() => handleEdit(rowData, column.rowIndex)}
                                        />
                                        <Button
                                            icon="pi pi-trash"
                                            className="p-button-rounded p-button-danger ml-1"
                                            onClick={() => handleDelete(column.rowIndex)}
                                        />
                                        </div>
                                    </div>
                                    )}
                                />
                            </DataTable>

                            <ul className='hide-on-large-screens'>
                                {newData.map((item, index) => (
                                    <li key={item.id}>
                                        <p className='activity-heading'>{item.extraCurricularActivity}</p>
                                        <div className='px-4 py-4'>
                                            <div className='flex justify-content-evenly align-items-center'> 
                                            <strong >Position Held:</strong> {item.positionHeld}
                                            </div>
                                            <div className='flex justify-content-evenly align-items-center'> 
                                            <strong >Start Year:</strong>{item.startDate}
                                            </div>
                                            <div className='flex justify-content-evenly align-items-center'> 
                                            <strong >End Year:</strong> {renderCell(item, 'endDate')}
                                            </div>
                                            <div className='flex justify-content-between align-items-center'> 
                                            <strong >Description:</strong> {item.description}
                                            </div>
                                            <div className="actions p-4 flex justify-content-around align-items-center">
                                                <Button
                                                    icon="pi pi-pencil"
                                                    className="p-button-rounded p-button-success mr-1"
                                                    onClick={() => handleEdit(item, index)}
                                                />
                                                <Button
                                                    icon="pi pi-trash"
                                                    className="p-button-rounded p-button-danger ml-1"
                                                    onClick={() => handleDelete(index)}
                                                />
                                            </div>
                                        </div>
                                        
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

export default ExtraActivities