import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Avatar } from "primereact/avatar";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import "../ViewLists/ViewCard.scss";
import CustomFilter from "./CustomFilter/CustomFilter";
import axios from "axios";
import { GET_TEACHER_DETAILS_URL } from "../../Utils/TeacherService";
import { AUTHORIZATION_HEADERS } from "../../Utils/LoginService";
import { OverlayPanel } from "primereact/overlaypanel";
import FilterChips from "../../Module/Admin/Principals/FilterChips";
import { Dropdown } from "primereact/dropdown";
import { set } from "react-hook-form";
import { Button } from "primereact/button";
import { useNavigate } from 'react-router-dom';


const StudentDataTable = () => {

    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [datass, setDatass] = useState([]);

    const [checkedItems, setCheckedItems] = useState({});
    const [groupCheckboxes, setGroupCheckboxes] = useState([]);

    const [initialData, setInitialData] = useState([]);

    const [displayedGroup, setDisplayedGroup] = useState([]);

    const overlayPanelRef = useRef(null);

    const [overlayPanelVisible, setOverlayPanelVisible] = useState(false);
    const [userData, setUserData] = useState([]);
    const [lastUpdateDates, setLastUpdateDates] = useState({});

    const showListofGroups = (event, rowData) => {
        const groupNames = rowData.groups

        setDisplayedGroup(groupNames);
        overlayPanelRef.current.toggle(event);
        setOverlayPanelVisible(true);
        document.body.style.overflow = 'hidden';
    };

    const hideOverlayPanel = () => {
        overlayPanelRef.current.hide();
        setOverlayPanelVisible(false);
        document.body.style.overflow = '';
    };


    useEffect(() => {
        const access = localStorage.getItem("userId");
        localStorage.removeItem('rowStudentId');
        sessionStorage.setItem('userType', 'teacher');

        axios
            .get(GET_TEACHER_DETAILS_URL + access, {
                headers: AUTHORIZATION_HEADERS
            })
            .then((res) => {
                const groupUsersInfoArray = res.data?.groupUsersInfo;
                if (groupUsersInfoArray) {
                    const mappedData = groupUsersInfoArray.map(user => {


                        const latestAcademicGoals = user.academicGoals?.academicGoals?.[user.academicGoals?.academicGoals.length - 1];

                        const firstName = user.firstName || "-";
                        const gender = user.gender || "-";
                        const groups = user.groups.map(group => group.groupName) || "-";
                        const studentUserId = user.userId || "-";
                        const status = user.status;
                        const tenYearPlan = user.tenYearPlan;

                        const educationalgoal = latestAcademicGoals?.goals.find(goal => goal.academicGoalsCategoryName === "Educational Goal")?.goalOptions || "-";
                        const certificategoal = latestAcademicGoals?.goals.find(goal => goal.academicGoalsCategoryName === "Honors Recognition Certificate Goal")?.goalOptions || "-";
                        const highschoolplan = latestAcademicGoals?.goals.find(goal => goal.academicGoalsCategoryName === "After High School Plan")?.goalOptions || "-";

                        const latestVersion = ((user.academiesPathways?.academiesPathwayVersion?.academiesPathway.length) - 1);
                        const latestAcademyPathway = user?.academiesPathways?.academiesPathwayVersion?.academiesPathway[latestVersion];
                        const academicCategory = latestAcademyPathway?.academiesPathwayCategory?.find(category => category?.academiesPathWay.some(pathway => pathway !== null));
                        const categoryName = academicCategory ? academicCategory?.academiesCategoryName : null;

                        const lastUpdated = user.updatedOn || user.createdOn;
                        const lastUpdatedDate = lastUpdated ? new Date(lastUpdated).toLocaleDateString() : "-";
                        setLastUpdateDates(prevState => ({ ...prevState, [user.userId]: lastUpdatedDate }));
                        
                     
                        return {
                            firstName,
                            gender,
                            groups,
                            studentUserId: user.userId,
                            status,
                            tenYearPlan,
                            educationalgoal,
                            certificategoal,
                            highschoolplan,
                            academy: categoryName?.length > 0 ? categoryName : "-",
                            profileAvatar: user.profileImage,
                            profileAvatarBackground: user.imageColourCode
                        };
                    });

                    setDatass(mappedData);
                    setInitialData(mappedData);

                    const uniqueGroups = [...new Set(mappedData.flatMap(item => item.groups))];
                    const checkboxes = uniqueGroups.map(groups => ({ label: groups, value: groups }));

                    setGroupCheckboxes(checkboxes);
                } else {
                    console.error("Error");
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []);

    const listTemplate = (list) => {
        return (
            <div>
                {list.profileAvatar === null || list.profileAvatar === undefined || list.profileAvatar === "" ? (
                    <div className="flex align-items-center gap-2">
                        {list.gender === "Male" || list.gender === "M" ? (
                            <Avatar
                                image={require(`../../Assets/Profile edit/Profile image/Default/Man.png`)}
                                style={{ width: 60, height: 60, backgroundColor: "#B9C4E1", padding: '6px' }}
                                size="large"
                                shape="circle"
                            />
                        ) : list.gender === "Female" || list.gender === "F" ? (
                            <Avatar
                                image={require(`../../Assets/Profile edit/Profile image/Default/Woman.png`)}
                                style={{ width: 60, height: 60, backgroundColor: "#B9C4E1", padding: '6px' }}
                                size="large"
                                shape="circle"
                            />
                        ) : (
                            <Avatar icon="pi pi-user" size="xlarge" shape="circle" />
                        )}
                        <span>{list.firstName}</span>
                    </div>
                ) : (
                    <div className="flex align-items-center gap-2">
                        <Avatar
                            image={list.profileAvatar}
                            style={{ width: 60, height: 60, backgroundColor: list.profileAvatarBackground, padding: '6px' }}
                            size="large"
                            shape="circle"
                        />
                        <span>{list.firstName}</span>
                    </div>
                )}
            </div>
        );
    };

    const [selectedCareerGoalsFilter, setSelectedCareerGoalsFilter] = useState([]);
    const [selectedEducationalGoalsFilter, setSelectedEducationalGoalsFilter] = useState([]);
    const [selectedCertificateGoalsFilter, setSelectedCertificateGoalsFilter] = useState([]);
    const [selectedHighSchoolPlanFilter, setSelectedHighSchoolPlanFilter] = useState([]);
    const [selectedAcademyFilter, setSelectedAcademyFilter] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);


    const handleCheckboxChange = (item, filterCategory) => {
        const updatedCheckedItems = {
            ...checkedItems,
            [item]: !checkedItems[item],
        };
        setCheckedItems(updatedCheckedItems);

        if (filterCategory === "careerGoals") {
            setSelectedCareerGoalsFilter([...selectedCareerGoalsFilter, item]);
        } else if (filterCategory === "educationalgoal") {
            setSelectedEducationalGoalsFilter([...selectedEducationalGoalsFilter, item]);
        } else if (filterCategory === "certificategoal") {
            setSelectedCertificateGoalsFilter([...selectedCertificateGoalsFilter, item]);
        } else if (filterCategory === "highschoolplan") {
            setSelectedHighSchoolPlanFilter([...selectedHighSchoolPlanFilter, item]);
        } else if (filterCategory === "academy") {
            setSelectedAcademyFilter([...selectedAcademyFilter, item]);
        }

        const selectedFieldValues = Object.keys(updatedCheckedItems).filter(
            (key) => updatedCheckedItems[key]
        );

        setSelectedFilters(selectedFieldValues);
    };

    const [selectedField, setSelectedField] = useState([]);

    const applyFilter = (field) => {
        const selectedFieldValues = Object.keys(checkedItems).filter(
            (key) => checkedItems[key]
        );

        setSelectedField(selectedFieldValues)

        const selectedCareerGoals = selectedCareerGoalsFilter.filter(
            (key) => checkedItems[key]
        );
        const selectedEducationalGoals = selectedEducationalGoalsFilter.filter(
            (key) => checkedItems[key]
        );
        const selectedCertificateGoals = selectedCertificateGoalsFilter.filter(
            (key) => checkedItems[key]
        );
        const selectedHighSchoolPlan = selectedHighSchoolPlanFilter.filter(
            (key) => checkedItems[key]
        );
        const selectedAcademy = selectedAcademyFilter.filter(
            (key) => checkedItems[key]
        );

        if (selectedFieldValues.length === 0 &&
            selectedCareerGoals.length === 0 &&
            selectedEducationalGoals.length === 0 &&
            selectedCertificateGoals.length === 0 &&
            selectedHighSchoolPlan.length === 0 &&
            selectedAcademy.length === 0
        ) {
            setDatass(initialData);
            const overlayElement = document.querySelector(".p-column-filter-overlay");
            if (overlayElement) {
                overlayElement.style.display = "none";
            }
            return;
        }
        const filteredData = initialData.filter((item) => {
            const fieldFilter =
                selectedFieldValues.length === 0 ||
                (field === "groups" &&
                    selectedFieldValues.some((selectedValue) =>
                        item.groups.includes(selectedValue)
                    )) ||
                selectedFieldValues.includes(item[field]);

            const careerGoalsFilter =
                selectedCareerGoals.length === 0 ||
                selectedCareerGoals.some((selectedValue) =>
                    item.careerGoals.includes(selectedValue)
                );

            const educationalGoalsFilter =
                selectedEducationalGoals.length === 0 ||
                selectedEducationalGoals.some((selectedValue) =>
                    item.educationalgoal.includes(selectedValue)
                );

            const certificateGoalsFilter =
                selectedCertificateGoals.length === 0 ||
                selectedCertificateGoals.some((selectedValue) =>
                    item.certificategoal.includes(selectedValue)
                );

            const selectedHighSchoolPlanFilter =
                selectedHighSchoolPlan.length === 0 ||
                selectedHighSchoolPlan.some((selectedValue) =>
                    item.highschoolplan.includes(selectedValue)
                );

            const academyFilter =
                selectedAcademy.length === 0 ||
                selectedAcademy.some((selectedValue) =>
                    item.academy.includes(selectedValue)
                );

            return fieldFilter && careerGoalsFilter && educationalGoalsFilter && certificateGoalsFilter && selectedHighSchoolPlanFilter && academyFilter;
        });
        setDatass(filteredData);
        const overlayElement = document.querySelector(".p-column-filter-overlay");
        if (overlayElement) {
            overlayElement.style.display = "none";
        }
    };

    const handleClearAllFilters = () => {

        setCheckedItems({});
        setSelectedCareerGoalsFilter([]);
        setSelectedEducationalGoalsFilter([]);
        setSelectedCertificateGoalsFilter([]);
        setSelectedHighSchoolPlanFilter([]);
        setSelectedAcademyFilter([]);
        setSelectedField([])

        setSelectedFilters([]);

        setDatass(initialData);

        const overlayElement = document.querySelector('.p-column-filter-overlay');
        if (overlayElement) {
            overlayElement.style.display = 'none';
        }

    };

    const [selectedFilter, setSelectedFilter] = useState(null);
    const [selectedFilterValue, setSelectedFilterValue] = useState(null);
    const [selectedTenYearPlanFilter, setSelectedTenYearPlanFilter] = useState(null);

    const filterOptions = [
        { label: 'All', value: 'all' },
        { label: 'Registered Account', value: 'registered' },
        { label: 'Not Registered Account', value: 'notRegistered' },
        { label: 'Personal Pathway Started', value: 'personalPathwayStarted' },
    ];

    const handleFilter = (e) => {

        setSelectedFilterValue(e.value)

        const availableStatuses = [...new Set(datass.map((item) => item.status))];
        const availablePersonalPathwayPlans = [...new Set(datass.map((item) => item.tenYearPlan))];

        if (e.value === 'all') {
            setSelectedFilter(null);
            setSelectedTenYearPlanFilter(null)
            handleClearAllFilters();
        } else if (e.value === 'registered') {
            const completedStatusExists = availableStatuses.includes("Completed");
            setSelectedFilter(completedStatusExists ? "Completed" : null);
            setSelectedTenYearPlanFilter(null)
        } else if (e.value === 'notRegistered') {
            const unInvitedStatusExists = availableStatuses.includes("Pending");
            setSelectedFilter(unInvitedStatusExists ? "Pending" : null);
            setSelectedTenYearPlanFilter(null);
        } else if (e.value === 'personalPathwayStarted') {
            const personalPathwayExists = availablePersonalPathwayPlans.includes("true");
            setSelectedTenYearPlanFilter(personalPathwayExists ? "true" : null);
            setSelectedFilter(null);
        } else {
            const pendingStatusExists = availableStatuses.includes("Uninvited");
            setSelectedFilter(pendingStatusExists ? "Uninvited" : null);
        }
    };

    const filterData = () => {
        return datass.filter((item) => {
            const nameFilter = item.firstName.toLowerCase().includes(searchQuery.toLowerCase());
            const statusFilter = !selectedFilter || item.status === selectedFilter;
            const tenYearPlanFilter = !selectedTenYearPlanFilter || item.tenYearPlan === selectedTenYearPlanFilter;

            return nameFilter && statusFilter && tenYearPlanFilter;
        });
    };

    
    const handleStudentProgressReport = () => {
        navigate('/teacher/studentsProgressReport');
    }

    return (

        <div className="Student-datatable">
            <div className="student-dt">
                <div className="search-field flex justify-content-between align-items-center m-5">
                    <h3>My Students</h3>
                    <div className="flex">
                        <div className="mr-3">
                            <Button label="Student Progress Report" severity="secondary" onClick={handleStudentProgressReport}/>
                        </div>
                        <div className="p-input-icon-right mr-4">
                            <InputText placeholder="Search Student" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                            <i className="pi pi-search ml-1" />
                        </div>
                    </div>

                </div>
                <Card className="card-field m-5">
                    <div className="datatable-card-section">
                        <DataTable
                            value={datass.filter((list) =>
                                list.firstName.toLowerCase().includes(searchQuery.toLowerCase())
                            )}
                            stripedRows
                            tableStyle={{ minWidth: "50rem" }}
                            paginator rows={10}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                        >
                            {datass.firstName}
                            <Column field="firstName" header="Name" body={listTemplate} style={{ textAlign: "center" }}></Column>
                            <Column
                                field="groups"
                                header="Group"
                                style={{ textAlign: "center" }}
                                filter
                                filterClear
                                filterApply={
                                    <CustomFilter
                                        checkboxes={groupCheckboxes}
                                        checkedItems={checkedItems}
                                        onCheckboxChange={handleCheckboxChange}
                                        onApplyFilter={() => applyFilter("groups")}
                                    />
                                }
                                body={(rowData) => {
                                    const groupNames = rowData.groups;
                                    const displayGroupNames = groupNames.length > 1 ? groupNames.slice(0, 1).join(", ") + "..." : groupNames;

                                    return (
                                        <span
                                            onClick={(e) => showListofGroups(e, rowData)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {displayGroupNames}
                                        </span>
                                    );
                                }}
                            ></Column>
                            <Column
                                field="educationalgoal"
                                header="Educational Goal"
                                style={{ textAlign: "center" }}
                                filter
                                filterClear
                                filterApply={
                                    <CustomFilter
                                        checkboxes={[
                                            { label: "Technical or vocational certificate", value: "Technical or vocational certificate" },
                                            { label: "Two-year college degree (AA/AS)", value: "Two-year college degree (AA/AS)" },
                                            { label: "Technical School", value: "Technical School" },
                                            { label: "High school diploma", value: "High school diploma" },
                                            { label: "Four-year college degree (BA/BS)", value: "Four-year college degree (BA/BS)" },
                                            { label: "Graduate degree (MD, MA, JD, PhD, MBA)", value: "Graduate degree (MD, MA, JD, PhD, MBA)" },
                                            { label: "I'm not sure", value: "I'm not sure" },
                                        ]}
                                        checkedItems={checkedItems}
                                        onCheckboxChange={(item) => handleCheckboxChange(item, "educationalgoal")}
                                        onApplyFilter={() => applyFilter("educationalgoal")}
                                    />
                                }
                            ></Column>
                            <Column
                                field="certificategoal"
                                header="Certificate Goal"
                                filter
                                filterClear
                                style={{ textAlign: "center" }}
                                filterApply={
                                    <CustomFilter
                                        checkboxes={[
                                            { label: "Academic Honors", value: "Academic Honors" },
                                            { label: "STEM Honors", value: "STEM Honors" },
                                            { label: "CTE Honors", value: "CTE Honors" },
                                            { label: "I'm not sure", value: "I'm not sure" },
                                        ]}
                                        checkedItems={checkedItems}
                                        onCheckboxChange={(item) => handleCheckboxChange(item, "certificategoal")}
                                        onApplyFilter={() => applyFilter("certificategoal")}
                                    />
                                }
                            ></Column>
                            <Column
                                field="highschoolplan"
                                header="After HS plan"
                                filter
                                filterClear
                                style={{ textAlign: "center" }}
                                filterApply={
                                    <CustomFilter
                                        checkboxes={[
                                            { label: "Go to a vocational or trade school", value: "Go to a vocational or trade school" },
                                            { label: "Go to a four-year college/University", value: "Go to a four-year college/University" },
                                            { label: "Find a Job", value: "Find a Job" },
                                            { label: "Go to a two-year college/Community College", value: "Go to a two-year college/Community College" },
                                            { label: "Join the military", value: "Join the military" },
                                            { label: "I'm not sure", value: "I'm not sure" },
                                        ]}
                                        checkedItems={checkedItems}
                                        onCheckboxChange={(item) => handleCheckboxChange(item, "highschoolplan")}
                                        onApplyFilter={() => applyFilter("highschoolplan")}
                                    />
                                }
                            ></Column>

                            <Column
                                field="academy"
                                header="CTE Pathway"
                                filter
                                filterClear
                                style={{ textAlign: "center" }}
                                filterApply={
                                    <CustomFilter
                                    checkboxes={[
                                        { label: "Academy of Human Services", value: "Academy of Human Services" },
                                        { label: "Academy of Innovation and Design", value: "Academy of Innovation and Design" },
                                    ]}
                                        checkedItems={checkedItems}
                                        onCheckboxChange={(item) => handleCheckboxChange(item, "academy")}
                                        onApplyFilter={() => applyFilter("academy")}
                                    />
                                }
                            ></Column>
                             <Column
                                header="Last Update"
                                style={{ textAlign: "center" }}
                                body={(row) => <span>{lastUpdateDates[row.studentUserId]}</span>}
                            ></Column>
                            <Column
                                className="view-details"
                                field=""
                                header="Actions"
                                style={{ textAlign: "center" }}
                                body={(rowData) => (
                                    <Link
                                        onClick={() => {
                                            const userId = rowData?.studentUserId;
                                            localStorage.setItem('rowStudentId', userId);
                                        }}
                                        style={{ textDecoration: "none", color: "#1575e5" }}
                                        to={'/' + sessionStorage.userType + '/studentinfo'}
                                    >
                                        View PTP
                                    </Link>
                                )}
                            ></Column>
                        </DataTable>
                        <OverlayPanel
                            ref={overlayPanelRef}
                            appendTo={document.body}
                            onHide={hideOverlayPanel}
                        >
                            <ul className="profile-menu-list">
                                {Array.isArray(displayedGroup) ? (
                                    displayedGroup.map((group, index) => (
                                        <li key={index}>{group}</li>
                                    ))
                                ) : (
                                    <li>{displayedGroup}</li>
                                )}
                            </ul>
                        </OverlayPanel>
                    </div>
                </Card>
            </div >
        </div >


    );
};

export default StudentDataTable;
