import React, { useState, useEffect, useRef } from 'react';
import NavBar from '../../../Shared-Component/StackNavBar/StackNavBar';
import { Divider } from 'primereact/divider';
import ProfileCard from '../../../Shared-Component/ProfileCard/profileCard';
import StudentForm from './StudentForm/StudentForm';
import { GET_STUDENT_DETAILS, GET_GRADUATION_REQUIREMENTS, CONTENT_HEADERS } from '../../../Utils/StudentService';
import axios from 'axios';
import './Dashboard.scss';
import { Link } from 'react-router-dom';
import Spinner from '../../../Shared-Component/Spinner/Gif-Loader';
import { useNavigate } from 'react-router-dom';
import StudentCard from '../../../Shared-Component/Student-PersonalPathway-Card/Student-PersonalPathway-Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Toast } from 'primereact/toast';

const Dashboard = () => {
  const userRole = 'Student';
  const [activeMenuItem, setActiveMenuItem] = useState('dashboard');

  const navigate = useNavigate();
  const [studentDetails, setStudentDetails] = useState();
  const [graduationRequirements, setGraduationRequirements] = useState();
  const [gpa, setGpa] = useState();
  const [name, setName] = useState();
  const [careerGoals, setCareerGoals] = useState();
  const [educationalGoal, setEducationalGoal] = useState();
  const [academicCGPAGoals, setAcademicCGPAGoals] = useState();
  const [honorsRecognitionCertificate, setHonorsRecognitionCertificate] = useState();
  const [academiesCategoryName, setAcademiesCategoryName] = useState();
  const [highSchoolAcademicGoals, setHighSchoolAcademicGoals] = useState();
  const [aptitudes, setAptitudes] = useState();
  const [coreDrivers, setCoreDrivers] = useState();
  const [riasecResults, setRiasecResults] = useState();
  const [elective, setElective] = useState();
  const [profileAvatar, setProfileAvatar] = useState();
  const [profileAvatarBackground, setProfileAvatarBackground] = useState('#B9C4E1');

  const [loading, setLoading] = useState(false);
  const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });

  const handleMenuItemClick = (item) => {
    setActiveMenuItem(item);
    switch (item) {
      case "dashboard":
        navigate("/student/dashboard");
        break;
      case "profile":
        navigate("/student/profile");
        break;
      default:
        break;
    }
  };

  const toast = useRef(null);

  useEffect(() => {
    fetchDetails();

  }, []);


  const fetchDetails = () => {
    setLoading(true);
    setOpacityStyle({ opacity: 0.5 });



    axios
      .get(GET_STUDENT_DETAILS, { headers: CONTENT_HEADERS })
      .then((response) => {
        setStudentDetails(response.data);
        setName(`${response.data.firstName} ${response.data.lastName}`);
        setGpa(response?.data?.highSchoolGap)
        setLoading(false);
        setOpacityStyle({ opacity: 1, background: 'transparent' });
        setProfileAvatar(response?.data?.profileImage);
        setProfileAvatarBackground(response?.data?.imageColourCode);
        const userData = response.data;
  
        if (userData?.careerGoals?.youScienceResults?.length > 0) {
          const latestVersion = userData?.careerGoals?.youScienceResults?.length - 1;
          const latestYouScience = userData?.careerGoals?.youScienceResults[latestVersion];
          const coreDrivers = latestYouScience?.youScience?.filter(talent => talent?.strongestTalent).map(talent => talent?.talentCategory) || "-";
          const aptitudes = latestYouScience?.youScience?.filter(talent => talent?.strongestTalent).map(talent => talent?.talentName) || "-";
          setCoreDrivers(coreDrivers?.length > 0 ? coreDrivers : "-");
        setAptitudes(aptitudes?.length > 0 ? aptitudes : "-");
      }else{
          setCoreDrivers("-");
          setAptitudes("-");
        }
  
        if (userData?.careerGoals?.riasecResults?.length > 0) {
          const latestVersion = userData.careerGoals.riasecResults.length - 1;
          const latestRiasec = userData.careerGoals.riasecResults[latestVersion];
          setRiasecResults(latestRiasec.result[0]);
        }
  
        if (userData?.careerGoals?.careerGoalCategories?.length > 0) {
          const latestVersion = userData.careerGoals?.careerGoalCategories?.length - 1;
          const latestCareerGoals = userData.careerGoals?.careerGoalCategories[latestVersion];
          if (latestCareerGoals?.notDecided) {
            setCareerGoals("Not Decided");
          } else {
            setCareerGoals(latestCareerGoals?.categories?.[0]?.categoryName);
          }
        }
  
        if (userData?.academicGoals?.academicGoals?.length > 0) {
          const latestVersion = userData.academicGoals.academicGoals.length - 1;
          const latestAcademicGoals = userData.academicGoals.academicGoals[latestVersion];
  
          const educationalGoalsOptions = latestAcademicGoals.goals
            .filter(goal => goal.academicGoalsCategoryName === "Educational Goal")
            .map(goal => goal.goalOptions);
          setEducationalGoal(educationalGoalsOptions?.length ? educationalGoalsOptions : "-");
  
          const filteredAcademicCGPAGoal = latestAcademicGoals.goals
            .filter(goal => goal.academicGoalsCategoryName === "Cumulative GPA Goal")
            .map(goal => goal.goalOptions);
          setAcademicCGPAGoals(filteredAcademicCGPAGoal?.length ? filteredAcademicCGPAGoal : "-");
  
          const filteredHonorRecognitionCertificateGoal = latestAcademicGoals.goals
            .filter(goal => goal.academicGoalsCategoryName === "Honors Recognition Certificate Goal")
            .map(goal => goal.goalOptions);
          setHonorsRecognitionCertificate(filteredHonorRecognitionCertificateGoal?.length ? filteredHonorRecognitionCertificateGoal : "-");
  
          const filteredAfterHighSchoolGoal = latestAcademicGoals.goals
            .filter(goal => goal.academicGoalsCategoryName === "After High School Plan")
            .map(goal => goal.goalOptions);
          setHighSchoolAcademicGoals(filteredAfterHighSchoolGoal?.length ? filteredAfterHighSchoolGoal : "-");
        }
  
        if (userData?.academiesPathways?.academiesPathwayVersion?.academiesPathway?.length > 0) {
          const latestVersion = userData.academiesPathways.academiesPathwayVersion.academiesPathway.length - 1;
          const latestAcademyPathway = userData.academiesPathways.academiesPathwayVersion.academiesPathway[latestVersion];
          const academicCategory = latestAcademyPathway.academiesPathwayCategory.find(category => category.academiesPathWay.some(pathway => pathway !== null));
          const categoryName = academicCategory ? academicCategory.academiesCategoryName : null;
          setAcademiesCategoryName(categoryName);
        }
      })
      .catch((error) => {
        setLoading(true);
        console.log(error);
        if (toast.current) {
          toast.current.clear();
          toast.current.show([{ sticky: true, severity: 'error', summary: 'Error', detail: error.response.data.message }]);
        }
      });
  };

  const onCareerGoalsUpdate = (updatedData) => {
    const strongestCoreDriver = updatedData?.youSciences?.find(item => item.strongestTalent)?.talentCategory;
    const strongestAptitude = updatedData?.youSciences?.find(item => item.strongestTalent)?.talentName;
    setCoreDrivers([strongestCoreDriver]); 
    setAptitudes([strongestAptitude]); 
    if (updatedData?.notDecided === false) {
      console.log("Career Goals Decided");
      setCareerGoals(updatedData?.careerGoalCategories[0]?.categoryName);
    } else if (updatedData?.notDecided === true) {
      console.log("Career Goals Not Decided");
      setCareerGoals("Not Decided");
    }
  };
  
 
  const onAcademicGoalsUpdate = (updatedData) => {
    const educationalGoalsOptions = updatedData && updatedData
      .filter(goal => goal?.academicGoalsCategoryName === "Educational Goal")
      .map(goal => goal?.goalOptions);
    setEducationalGoal(educationalGoalsOptions?.length ? educationalGoalsOptions : "-");

    const filteredAcademicCGPAGoal = updatedData && updatedData
      .filter(goal => goal?.academicGoalsCategoryName === "Cumulative GPA Goal")
      .map(goal => goal.goalOptions);
    setAcademicCGPAGoals(filteredAcademicCGPAGoal?.length ? filteredAcademicCGPAGoal : "-");

    const filteredHonorRecognitionCertificateGoal = updatedData && updatedData
      .filter(goal => goal?.academicGoalsCategoryName === "Honors Recognition Certificate Goal")
      .map(goal => goal.goalOptions);
    setHonorsRecognitionCertificate(filteredHonorRecognitionCertificateGoal?.length ? filteredHonorRecognitionCertificateGoal : "-");

    const filteredAfterHighSchoolGoal = updatedData && updatedData
      .filter(goal => goal?.academicGoalsCategoryName === "After High School Plan")
      .map(goal => goal.goalOptions);
    setHighSchoolAcademicGoals(filteredAfterHighSchoolGoal?.length ? filteredAfterHighSchoolGoal : "-");
  }

  const onAcademicPathwaysUpdate = (updatedData) => {
    updatedData && updatedData.forEach(item => {
      if (item.academiesPathWay[0]) {
        setAcademiesCategoryName(item.academiesCategoryName)
      }
    });
  }


  return (
    <div className="student-dashboard">
      <div className="spinner-section">
        {loading ? <Spinner /> : null}
      </div>
      <NavBar
        activeMenuItem={activeMenuItem}
        onMenuItemClick={handleMenuItemClick}
        userRole={userRole}
        name={name}
      />
      <div className="toast-overlay">
        <Toast ref={toast} className="toast" />
      </div>
      <div className="student">
        <div className="grid">
          <div className='col-4'>
            <ProfileCard
              userRole={userRole}
              name={studentDetails?.firstName + ' ' + studentDetails?.lastName}
              schoolId={studentDetails?.registrationId}
              grade={studentDetails?.groups[0]?.grade}
              // year={moment.utc(studentDetails?.groups[0]?.graduationYear).format('YYYY')}
              gpa={gpa}
              gender={studentDetails?.gender}
              profileAvatar={profileAvatar}
              profileAvatarBackground={profileAvatarBackground}
              className="student-profilecard"
            />
          </div>
          <div className='col-8 desktop'>
            <StudentCard
              coreDrivers={coreDrivers}
              aptitudes={aptitudes}
              riasecResults={riasecResults}
              careerGoals={careerGoals}
              educationalGoal={educationalGoal}
              academiCGPAGoals={academicCGPAGoals}
              honorsRecognitionCertificate={honorsRecognitionCertificate}
              highSchoolAcademicGoals={highSchoolAcademicGoals}
              academiesCategoryName={academiesCategoryName}
            />
          </div>
        </div>

        <Divider />
        <div className=' desktop'>
          <StudentForm
            onCareerGoalsUpdate={onCareerGoalsUpdate}
            onAcademicGoalsUpdate={onAcademicGoalsUpdate}
            onAcademicPathwaysUpdate={onAcademicPathwaysUpdate}
          />
        </div>
      </div>




      <div className="student  hide-on-large-screens   tab-mob">
        <div className="grid">
          <div className='mx-2'>
            <h3> My Dashboard</h3>
          </div>

          <div className='col-8 tab-mob-List'>
            <div>
              <div>
                <h3 className='m-0'>My Personal Pathway</h3>
                <div className="flex align-items-center justify-content-center  tab-mob-student-form">
                  <ul>
                    <Link to="/student/personalstatement">  <li> Personal Statement <FontAwesomeIcon icon={faChevronRight} /></li></Link>
                    <Link to="/student/careergoals">  <li> Career Goals <FontAwesomeIcon icon={faChevronRight} /></li></Link>
                    <Link to="/student/academicgoals"> <li> Academic Goals <FontAwesomeIcon icon={faChevronRight} /></li></Link>
                    <Link to="/student/academypathway"><li> Academies Pathways <FontAwesomeIcon icon={faChevronRight} /></li></Link>
                    <Link to="/student/highSchoolProgress"><li> High School Progress <FontAwesomeIcon icon={faChevronRight} /></li></Link>
                    <Link to="/student/testScore"><li> Test Scores <FontAwesomeIcon icon={faChevronRight} /></li></Link>
                    <Link to="/student/extraactivities"> <li> Extra Activities <FontAwesomeIcon icon={faChevronRight} /></li></Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Dashboard;
