import React, { useState, useEffect, useRef } from 'react';
import NavBar from '../../../Shared-Component/StackNavBar/StackNavBar';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'primereact/divider';
import ProfileCard from '../../../Shared-Component/ProfileCard/profileCard';
import StatusCard from '../../../Shared-Component/Status-Card/status-card';
import './Dashboard.scss'
import axios from 'axios';
import { GET_TOTAL_NUMBER_OF_USERS_URL, CONTENT_HEADERS } from '../../../Utils/AdminService';
import { Card } from 'primereact/card';
import PieChart from '../Admin';
import Spinner from '../../../Shared-Component/Spinner/Gif-Loader';
import { Toast } from 'primereact/toast';

function Dashboard() {
    const userRole = "Admin";
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState("dashboard");

    const [schoolDetails, setSchoolDetails] = useState();
    const [name, setName] = useState();

    const [loading, setLoading] = useState(false);
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });

    const toast = useRef(null);

    useEffect(() => {
        fetchDetails();
    }, []);

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case "dashboard":
                navigate("/admin/dashboard");
                break;
            case "principals":
                navigate("/admin/principals");
                break;
            case "teachers":
                navigate("/admin/teachers");
                break;
            case "groups":
                navigate("/admin/groups");
                break;
            case "students":
                navigate("/admin/students");
                break;
            default:
                break;
        }
    };

    const fetchDetails = () => {
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });
        const schoolId = localStorage.getItem('schoolId');

        axios
            .get(GET_TOTAL_NUMBER_OF_USERS_URL + schoolId, { headers: CONTENT_HEADERS })
            .then((response) => {
                localStorage.setItem('schoolId', response.data.schools.schoolId);
                setSchoolDetails(response.data);
                setName(`${response.data.userDetails.firstName} ${response.data.userDetails.lastName}`);
                setLoading(false);
                setOpacityStyle({ opacity: 1, background: 'transparent' });
            })
            .catch((error) => {
                setLoading(true);
                console.log(error);
                if (toast.current) {
                    toast.current.clear();
                    toast.current.show([{sticky: true, severity: 'error', summary: 'Error', detail: error.response.data.message }]);
                }
            });
    }

    return (
        <div className='admin-dashboard' >
            <div className="spinner-section">
                {loading ? <Spinner /> : null}
            </div>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
                name={name}
            />
            <div className="toast-overlay">
                <Toast ref={toast} className="toast" />
            </div>
            <div className="grid cards admin-section">
                <div className="col-4">
                    <ProfileCard
                        userRole={userRole}
                        name="Admin"
                        schoolId={schoolDetails?.schools?.schoolRegistrationId}
                        roleDescription={schoolDetails?.schools?.schoolName}
                    ></ProfileCard>
                </div>

                <div className="col-8">
                    <StatusCard
                        userRole={userRole}
                        title={`${schoolDetails?.schools?.schoolName} Registration`}
                        totalStudents={schoolDetails?.totalUserCount}
                        notStarted={schoolDetails?.totalPendingCount}
                        studentsStarted={schoolDetails?.totalCompletedCount}
                    ></StatusCard>
                </div>
                {/* </div> */}
                <Divider />

                <div className="col-4 admin-img-section">
                    <Card className='flex images justify-content-center items-center h-full '>
                        <PieChart schoolDetails={schoolDetails} ></PieChart>
                    </Card>
                </div>

                <div className="col-8 admin-statuscard-section">
                    <StatusCard
                        userRole={userRole}
                        title="Principal Registration"
                        totalStudents={schoolDetails?.totalPrincipalCount}
                        notStarted={schoolDetails?.principalPendingCount}
                        studentsStarted={schoolDetails?.principalCompletedCount}
                    ></StatusCard>

                    <StatusCard
                        userRole={userRole}
                        title="Teacher Registration"
                        totalStudents={schoolDetails?.totalTeacherCount}
                        notStarted={schoolDetails?.teacherPendingCount}
                        studentsStarted={schoolDetails?.teacherCompletedCount}
                    ></StatusCard>

                    <StatusCard
                        userRole={userRole}
                        title="Student Registration"
                        totalStudents={schoolDetails?.totalStudentCount}
                        notStarted={schoolDetails?.studentPendingCount}
                        studentsStarted={schoolDetails?.studentCompletedCount}
                    ></StatusCard>
                </div>
            </div>

        </div>
    );
};

export default Dashboard;
