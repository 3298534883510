import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Avatar } from 'primereact/avatar';
import { Dropdown } from 'primereact/dropdown';
import './Studentprofile.scss';
import axios from 'axios';
import NavBar from '../../../Shared-Component/StackNavBar/StackNavBar';
import { useNavigate } from 'react-router-dom';
import { InputMask } from 'primereact/inputmask';
import { GET_STUDENT_DETAILS, CONTENT_HEADERS, CONTENT_MULTIPART_HEADERS, UPDATE_PROFILE_URL, GET_RESUME_URL, AUTHORIZATION_HEADERS } from '../../../Utils/StudentService';
import Spinner from '../../../Shared-Component/Spinner/Gif-Loader';
import { FaRegTrashAlt } from 'react-icons/fa';
import defaultManAvatar from "../../../Assets/Profile edit/Profile image/Default/Man.png";
import defaultWomenAvatar from "../../../Assets/Profile edit/Profile image/Default/Woman.png";
import { Accordion, AccordionTab } from 'primereact/accordion';
import MobilePTPGoals from '../MobilePTPGoals';
import { Dialog } from 'primereact/dialog';
import { ColorPicker } from 'primereact/colorpicker';
import coverImage1 from '../../../Assets/Profile edit/Background/beautiful-tropical-beach.png';
import coverImage2 from '../../../Assets/Profile edit/Background/closeup-green-sea.png';
import coverImage3 from '../../../Assets/Profile edit/Background/plant-leaves.png';
import coverImage4 from '../../../Assets/Profile edit/Background/texture-background.png';
import coverImage5 from '../../../Assets/Profile edit/Background/tropical-hawaii.png';
import coverImage6 from '../../../Assets/Profile edit/Background/vertical-shot-palm.png';
import coverImageDefault from '../../../Assets/Profile edit/Background/Laulama.png';
import profileAnimalImage1 from '../../../Assets/Profile edit/Profile image/Animals/Bunny.png';
import profileAnimalImage2 from '../../../Assets/Profile edit/Profile image/Animals//Cat.png';
import profileAnimalImage3 from '../../../Assets/Profile edit/Profile image/Animals/Dog.png';
import profileAnimalImage4 from '../../../Assets/Profile edit/Profile image/Animals/Fox.png';
import profileAnimalImage5 from '../../../Assets/Profile edit/Profile image/Animals/Gorilla.png';
import profileAnimalImage6 from '../../../Assets/Profile edit/Profile image/Animals/Koala.png';
import profileAnimalImage7 from '../../../Assets/Profile edit/Profile image/Animals/Lion.png';
import profileAnimalImage8 from '../../../Assets/Profile edit/Profile image/Animals/Tiger.png';
import profilePeopleImage1 from '../../../Assets/Profile edit/Profile image/People/Man 1.png';
import profilePeopleImage2 from '../../../Assets/Profile edit/Profile image/People/Man 2.png';
import profilePeopleImage3 from '../../../Assets/Profile edit/Profile image/People/Man 3.png';
import profilePeopleImage4 from '../../../Assets/Profile edit/Profile image/People/Man 4.png';
import profilePeopleImage5 from '../../../Assets/Profile edit/Profile image/People/Man 5.png';
import profilePeopleImage6 from '../../../Assets/Profile edit/Profile image/People/Man 6.png';
import profilePeopleImage7 from '../../../Assets/Profile edit/Profile image/People/Woman 1.png';
import profilePeopleImage8 from '../../../Assets/Profile edit/Profile image/People/Woman 2.png';
import profilePeopleImage9 from '../../../Assets/Profile edit/Profile image/People/Woman 3.png';
import profilePeopleImage10 from '../../../Assets/Profile edit/Profile image/People//Woman 4.png';
import profilePeopleImage11 from '../../../Assets/Profile edit/Profile image/People/Woman 5.png';
import profilePeopleImage12 from '../../../Assets/Profile edit/Profile image/People/Woman 6.png';




import { CgColorPicker } from "react-icons/cg";


const StudentProfile = () => {
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('profile');
    const [studentDetails, setStudentDetails] = useState({
        email: '',
        phone: '',
        dob: '',
        schoolName: '',
        grade: null,
        gpa: '',
        street: '',
        city: '',
        zipcode: ''
    });
    const gradeOptions = [
        { label: "Grade 9", value: "Grade 9" },
        { label: "Grade 10", value: "Grade 10" },
        { label: "Grade 11", value: "Grade 11" },
        { label: "Grade 12", value: "Grade 12" }
    ];
    const [editMode, setEditMode] = useState(false);
    const [resumeName, setResumeName] = useState('');

    const [loading, setLoading] = useState(false);
    const [getResume, setGetResume] = useState(null);
    const [showUploader, setShowUploader] = useState(false);

    const [visible, setVisible] = useState(false);
    const [profileImageEdit, setProfileImageEdit] = useState(false);
    const [headerBackground, setHeaderBackground] = useState(null); // Default background color
    const [bannerBackground, setBannerBackground] = useState(null); // State to store the selected background
    const [profileImageBackground, setProfileImageBackground] = useState(null);
    const [profilebackground, setProfileBackground] = useState(null);
    const [avatarImage, setAvatarImage] = useState(null); // Initial avatar image
    const [finalAvatarImage, setFinalAvatarImage] = useState(null); // Initial avatar image
    const [profileColorPicker, setProfileColorPicker] = useState('#FFFFFF');
    const [backgroundColorPicker, setBackgroundColorPicker] = useState('#FFFFFF');
    const colorPickerRef = useRef(null);

    const predefinedColors = ['#1067B8', '#FC5145', '#EBB448', '#91BF82', '#1E3050', '#A6CFFF', '#F600ED'];
    const backgroundImages = [coverImageDefault, coverImage1, coverImage2, coverImage3, coverImage4, coverImage5, coverImage6];

    const profileAnimalImages = [profileAnimalImage1, profileAnimalImage2, profileAnimalImage3, profileAnimalImage4, profileAnimalImage5, profileAnimalImage6, profileAnimalImage7, profileAnimalImage8];
    const profilePeopleImages = [profilePeopleImage1, profilePeopleImage2, profilePeopleImage3, profilePeopleImage4, profilePeopleImage5, profilePeopleImage6, profilePeopleImage7, profilePeopleImage8, profilePeopleImage9, profilePeopleImage10, profilePeopleImage11, profilePeopleImage12]


    // useEffect(() => {
    //     getAvatarForGender();
    // }, [studentDetails]);

    useEffect(() => {
        fetchDetails();
        fetchResume();
    }, []);

    const fetchDetails = () => {
        setLoading(true);
        axios.get(GET_STUDENT_DETAILS, { headers: CONTENT_HEADERS })
            .then(response => {
                const data = response.data;
                const date = new Date(data?.dob);
                setStudentDetails({
                    email: data.emailId,
                    phoneNumber: data.phoneNumber,
                    dob: date,
                    schoolName: data.schools.schoolName,
                    grade: data.groups[0]?.grade,
                    gpa: data.highSchoolGap,
                    street: data.address?.addressLine1,
                    city: data.address?.city,
                    zipcode: data.address?.postalCode,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    registrationId: data.registrationId,
                    gender: data.gender,

                });
                setLoading(false);
                if (data?.profileImage === null || data?.profileImage === undefined || data?.profileImage === "") {
                    if (data?.gender === "Male" || data?.gender === "M") {
                        setFinalAvatarImage(defaultManAvatar);
                        setAvatarImage(defaultManAvatar);
                    } else if (data?.gender === "Female" || data?.gender === "F") {
                        setFinalAvatarImage(defaultWomenAvatar);
                        setAvatarImage(defaultWomenAvatar);
                    } else {
                        return <Avatar icon="pi pi-user" size="xlarge" shape="circle" />;
                    }
                }
                else {
                    setFinalAvatarImage(data?.profileImage);
                    setAvatarImage(data?.profileImage);
                }

                if(data?.imageColourCode === null || data?.imageColourCode === undefined || data?.imageColourCode === ""){
                    setProfileImageBackground('#B9C4E1');
                    setProfileBackground('#B9C4E1');
                } else {
                setProfileImageBackground(data?.imageColourCode);
                setProfileBackground(data?.imageColourCode);
                }

                if(data?.backgroundImage === null || data?.backgroundImage === undefined || data?.backgroundImage === ""){
                    setHeaderBackground(`url(${coverImageDefault})`);
                    setBannerBackground(`url(${coverImageDefault})`);
                } else {
                setHeaderBackground(data?.backgroundImage);
                setBannerBackground(data?.backgroundImage);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    useEffect(() => {
        const handlePickColor = (event) => {
            if (colorPickerRef.current) {
                const rect = colorPickerRef.current.getBoundingClientRect();
                console.log(rect);
                // Perform other actions with rect
            }
        };

        const element = colorPickerRef.current;
        if (element) {
            element.addEventListener('click', handlePickColor); // Change 'click' to the appropriate event if needed
        }

        return () => {
            if (element) {
                element.removeEventListener('click', handlePickColor); // Ensure cleanup
            }
        };
    }, []);


    const getAvatarForGender = () => {
        if (studentDetails?.gender === "Male" || studentDetails?.gender === "M") {
            setFinalAvatarImage(defaultManAvatar);
            setAvatarImage(defaultManAvatar);
        } else if (studentDetails?.gender === "Female" || studentDetails?.gender === "F") {
            setFinalAvatarImage(defaultWomenAvatar);
            setAvatarImage(defaultWomenAvatar);
        } else {
            return <Avatar icon="pi pi-user" size="xlarge" shape="circle" />;
        }
    };


    const fetchResume = () => {
        const userId = localStorage.getItem('userId');
        axios
            .get(GET_RESUME_URL + userId, { headers: AUTHORIZATION_HEADERS })
            .then((response) => {
                if (response.data !== "resume not found !") {
                    setGetResume(response.data);
                }
            })
    }

    const handleInputChange = (name, value) => {
        setStudentDetails(prevDetails => {
            const updatedDetails = { ...prevDetails, [name]: value };
            return updatedDetails;
        });
    };

    const handleSubmit = () => {
        setEditMode(false);
        setLoading(true);

        const dob = studentDetails.dob;
        const dobDate = new Date(dob);
        const day = dobDate.getDate();
        const month = (dobDate.getMonth() + 1).toString().padStart(2, '0');
        const year = dobDate.getFullYear();
        const formatedDobDate = `${year}-${month}-${day}`;

        const formData = new FormData();

        const updatedprofileData = {
            emailId: studentDetails.email,
            registrationId: studentDetails.registrationId,
            grade: studentDetails.grade,
            firstName: studentDetails.firstName,
            middleName: studentDetails.middleName,
            lastName: studentDetails.lastName,
            phoneNumber: studentDetails.phoneNumber,
            address: {
                addressLine1: studentDetails.street,
                city: studentDetails.city,
                postalCode: studentDetails.zipcode,
            },
            // dob: formatedDobDate,
            highSchoolGap: studentDetails.gpa,
        };

        const profileImageAndBackgroundData = {
            "profileImage": avatarImage,
            "imageColourCode": profilebackground,
            "backgroundImage": bannerBackground
        };

        formData.append('json', JSON.stringify(updatedprofileData))
        formData.append('file', selectedFile);
        formData.append('image', JSON.stringify(profileImageAndBackgroundData));

        axios
            .patch(UPDATE_PROFILE_URL, formData, { headers: CONTENT_MULTIPART_HEADERS })
            .then(() => {
                window.location.reload();
                navigate("/student/profile");
                setLoading(false);
                setShowUploader(false);
                fetchResume();
                
            })
            .catch((error) => {
                console.log("API Error:", error)
            })

    };




    const onUpload = e => {
        const file = e.files[0];
        setSelectedFile(file);
        setIsUploadEnabled(true);
        setResumeName(e.files[0].name);
    };

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);
        navigate(`/student/${item}`);
    };

    const handleResumeGenerator = () => {
        navigate("/student/resumebuilder")
    }

    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploadEnabled, setIsUploadEnabled] = useState(false);

    const handleCancel = (event) => {
        event.preventDefault();
        setSelectedFile(null);
        setShowUploader(false)
    };

    const isTabOpen = (index) => activeIndex === index;
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    // Function to handle background change
    const handleBackgroundChange = (selectedBackground, type) => {
        if (type === 'image') {
            // If selected background is an image URL
            setBannerBackground(`url(${selectedBackground})`);
        } else {
            // If selected background is a color
            setBannerBackground(selectedBackground);
        }
    };

    const handleBackgroundPickerChange = (color) => {
        setBannerBackground(`#${color}`);;
    };

    const handleProfileBackgroundChange = (color) => {
        setProfileBackground(color);
    }

    const handleProfileBackgroundPickerChange = (color) => {
        setProfileBackground(`#${color}`);
    };

    const handleProfileImageChange = (image) => {
        setAvatarImage(image);
    };

    const handleSave = () => {
        setHeaderBackground(bannerBackground);
        setTimeout(() => {
            setVisible(false);
            handleSubmit();
        }, 0);
    };

    const handleProfileEditSave = () => {
        setProfileImageBackground(profilebackground);
        setFinalAvatarImage(avatarImage);
        setProfileImageEdit(false);
        handleSubmit();
    };


    const profileEditHeader = (
        <div className="inline-flex align-items-left justify-content-left gap-2">
            <span className="font-bold white-space-nowrap">Edit Profile Image</span>
        </div>
    );
    const profileEditFooter = (
        <div>
            <Button label="Save" icon="pi pi-check" onClick={handleProfileEditSave} />
        </div>
    );

    const backgroundEditHeader = (
        <div className="inline-flex align-items-left justify-content-left gap-2">
            <span className="font-bold white-space-nowrap">Edit Background Image</span>
        </div>
    );

    const backgroundEditFooter = (
        <div>
            <Button label="Save" icon="pi pi-check" onClick={handleSave} />
        </div>
    );

    return (
        <div className="student-profile">
            <div className="spinner-section">
                {loading ? <Spinner /> : null}
            </div>

            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole="Student"
            />
            <div className="card desktop-profile-view">
                <div className="header" style={{ background: headerBackground }}>
                    <div className='profile-details'>
                        <div className='p-icon-name' style={{ background: profileImageBackground }}>
                            {finalAvatarImage && (
                                <img src={finalAvatarImage} alt="Avatar" style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'contain', padding: '15px' }} />
                            )}
                            <div class="profile-edit-icon" onClick={() => setProfileImageEdit(true)}>
                                <i class="pi pi-pencil"></i>
                            </div>
                        </div>
                        <div className="user-details">
                            <h1 className="name">{studentDetails.firstName} {studentDetails.lastName}</h1>
                            <h2 className="id">(ID:{studentDetails.registrationId})</h2>
                        </div>
                    </div>
                    <div className='edit-profile-button'>
                        <Button icon="pi pi-pencil" className="p-e-button" label="" onClick={() => setVisible(true)} />
                    </div>
                </div>

                <Dialog className="dialog-boxes" visible={profileImageEdit} modal header={profileEditHeader} footer={profileEditFooter} style={{ width: '50rem' }} onHide={() => setProfileImageEdit(false)}>
                    <div className="d-header" style={{ background: bannerBackground }}>
                        <div className='d-profile-details'>
                            <div className='d-p-icon-name'
                                style={{ background: profilebackground }}>
                                {avatarImage && (
                                    <img src={avatarImage} alt="Avatar" style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'contain', padding: '15px' }} />
                                )}
                            </div>
                            <div className="d-user-details">
                                <div className="d-name">{studentDetails.firstName} {studentDetails.lastName}</div>
                                <div className="d-id">(ID:{studentDetails.registrationId})</div>
                            </div>
                        </div>
                    </div>
                    <div className="background-options">
                        <h3>Update Profile Picture</h3>
                        <p>Select a Color for background:</p>
                        <div className='inline-flex align-items-center justify-content-left' >
                            <div>
                                {predefinedColors.map((color, index) => (
                                    <div className="inline-flex align-items-center justify-content-left"
                                        key={index}
                                        style={{ backgroundColor: color, width: '30px', height: '30px', margin: '5px', border: 'none', borderRadius: '50%' }}
                                        onClick={() => handleProfileBackgroundChange(color)}
                                    ></div>
                                ))}
                            </div>
                            <div className="color-picker">
                                <div ref={colorPickerRef} className="color-picker-wrapper" style={{ position: 'relative', display: 'inline-block' }}>
                                    <ColorPicker value={profileColorPicker} onChange={(e) => handleProfileBackgroundPickerChange(e.value)} style={{ width: '30px', height: '30px', marginBottom: '5px', border: 'none', borderRadius: '50%' }} />
                                    <CgColorPicker style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none', color: 'black' }} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>Select your avatar</p>
                            <div>
                            {profileAnimalImages.map((image, index) => (
                                <div className="inline-flex align-items-center justify-content-left" key={index}>
                                    <img
                                        src={image}
                                        alt={`Background ${index}`}
                                        style={{ width: '55px', height: '55px', margin: '5px', objectFit: 'contain', padding: '5px' }}
                                        onClick={() => handleProfileImageChange(image)}
                                    />
                                </div>
                            ))}
                            </div>
                            <div>
                            {profilePeopleImages.map((image, index) => (
                                <div className="inline-flex align-items-center justify-content-left" key={index}>
                                    <img
                                        src={image}
                                        alt={`Background ${index}`}
                                        style={{ width: '55px', height: '55px', margin: '5px', objectFit: 'contain', padding: '5px' }}
                                        onClick={() => handleProfileImageChange(image)}
                                    />
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog className="dialog-boxes" visible={visible} modal header={backgroundEditHeader} footer={backgroundEditFooter} style={{ width: '50rem' }} onHide={() => setVisible(false)}>
                    <div className="d-header" style={{ background: bannerBackground }}>
                        <div className='d-profile-details'>
                            <div className='d-p-icon-name'
                                style={{ background: profilebackground }}>
                                {avatarImage && (
                                    <img src={avatarImage} alt="Avatar" style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'contain', padding: '15px' }} />
                                )}
                            </div>
                            <div className="d-user-details">
                                <div className="d-name">{studentDetails.firstName} {studentDetails.lastName}</div>
                                <div className="d-id">(ID:{studentDetails.registrationId})</div>
                            </div>
                        </div>
                    </div>
                    <div className="background-options">
                        <h3>Update Background</h3>
                        <p>Choose a color:</p>
                        <div className='inline-flex align-items-center justify-content-left' >
                            <div>
                                {predefinedColors.map((color, index) => (
                                    <div className="inline-flex align-items-center justify-content-left"
                                        key={index}
                                        style={{ backgroundColor: color, width: '30px', height: '30px', margin: '5px', border: 'none', borderRadius: '50%' }}
                                        onClick={() => handleBackgroundChange(color, 'color')}
                                    ></div>
                                ))}
                            </div>
                            <div className="color-picker">
                                <div ref={colorPickerRef} className="color-picker-wrapper" style={{ position: 'relative', display: 'inline-block' }}>
                                    <ColorPicker value={backgroundColorPicker} onChange={(e) => handleBackgroundPickerChange(e.value)} style={{ width: '30px', height: '30px', marginBottom: '5px', border: 'none', borderRadius: '50%' }} />
                                    <CgColorPicker style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none', color: 'black' }} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>Or choose an image:</p>
                            {backgroundImages.map((image, index) => (
                                <div className="inline-flex align-items-center justify-content-left" key={index}>
                                    <img
                                        src={image}
                                        alt={`Background ${index}`}
                                        style={{ width: '120px', height: '90px', margin: '5px', objectFit: 'cover' }}
                                        onClick={() => handleBackgroundChange(image, 'image')}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </Dialog>

                <div className="forms-section">

                    <h2>Personal Information
                        {editMode ? (

                            ""
                        ) : (
                            <Button icon="pi pi-pencil" onClick={() => setEditMode(true)} className="edit-button" label="Edit" />
                        )}
                    </h2>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <InputText id="email" value={studentDetails.email} onChange={(e) => handleInputChange('email', e.target.value)} disabled={!editMode} readOnly />
                            </div>
                            <div className="form-group">
                                <label htmlFor="schoolName">School Name</label>
                                <InputText id="schoolName" value={studentDetails.schoolName} onChange={(e) => handleInputChange('schoolName', e.target.value)} disabled={!editMode} readOnly />
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="dob">Date of Birth</label>
                                <Calendar id="dob" type="date" value={studentDetails.dob} onChange={(e) => handleInputChange('dob', e.target.value)} disabled={!editMode} className="p-inputtext-sm w-full" />
                            </div> */}
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>Phone Number</label>
                                <InputMask
                                    mask="(999) 999-9999"
                                    name="phoneNumber"
                                    value={studentDetails.phoneNumber}
                                    onChange={(e) => { handleInputChange('phoneNumber', e.target.value) }}
                                    disabled={!editMode}
                                    className="p-inputtext-sm w-full"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="grade">Grade</label>
                                <Dropdown id="grade" value={studentDetails.grade} options={gradeOptions} onChange={(e) => handleInputChange('grade', e.value)} disabled={!editMode} className="grade-dropdown full-width" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="gpa">High School GPA</label>
                                <InputText id="gpa" type="number" value={studentDetails.gpa} onChange={(e) => handleInputChange('gpa', e.target.value)} disabled={!editMode} />
                            </div>
                        </div>

                        <h2 className="address-header">Address</h2>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="street">Street</label>
                                <InputText id="street" value={studentDetails.street} onChange={(e) => handleInputChange('street', e.target.value)} disabled={!editMode} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="city">City</label>
                                <InputText id="city" value={studentDetails.city} onChange={(e) => handleInputChange('city', e.target.value)} disabled={!editMode} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="zipcode">Zip Code</label>
                                <InputText id="zipcode" value={studentDetails.zipcode} onChange={(e) => handleInputChange('zipcode', e.target.value)} disabled={!editMode} />
                            </div>
                        </div>

                        {editMode && <Button type="submit" label="Save" onClick={handleSubmit} className="p-mt-2 save-button" />}
                    </form>
                </div>

                <div className="documents-section">
                    <h2>Documents</h2>
                    <p>You can generate your resume using the Laulama provided design or you can upload your own design of your resume.</p>

                    <div className="upload-section">
                        {getResume ? (
                            <div className='show-resume flex gap-2'>
                                <a href={`data:application/pdf;base64,${getResume}`} download={`${studentDetails.firstName} Resume.pdf`} style={{ textDecoration: "none", color: "#1067B8" }}>
                                    {`${studentDetails.firstName}_${studentDetails.lastName}_Resume.pdf`}
                                </a>
                                <FaRegTrashAlt style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => { setShowUploader(false); setGetResume(false) }} />
                            </div>
                        ) : (
                            !showUploader && (
                                <>
                                    <Button onClick={() => setShowUploader(true)}>
                                        Add My Resume
                                    </Button>
                                    <Button
                                        label="Generate My Resume"
                                        className="p-button-outlined generate-button"
                                        onClick={handleResumeGenerator}
                                    />
                                </>
                            )
                        )}
                        {showUploader && (
                            <div className="principal-page-left flex gap-4">
                                <FileUpload
                                    chooseLabel="Choose File"
                                    uploadLabel="Upload"
                                    value={''}
                                    onSelect={onUpload}
                                    mode="basic"
                                    accept=".pdf"
                                    className=''
                                />
                                <div className="flex gap-4">
                                    <Button onClick={handleCancel} className='gap-2 p-button-secondary'>
                                        <i className="pi pi-times"></i>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleSubmit} disabled={!isUploadEnabled} className='gap-2'>
                                        <i className="pi pi-upload"></i>
                                        Upload File
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="mobile-profile-view">
                <h2>My Profile</h2>
                <div>
                    <div>
                        <Accordion
                            activeIndex={activeIndex}
                            onTabChange={(e) => toggleAccordion(e.index)}
                        >
                            <AccordionTab header={
                                <div className="accordion-header">
                                    <div className="header-text">Personal Information</div>
                                    <div className="header-icon">
                                        <i className={`pi ${isTabOpen(0) ? "pi-minus" : "pi-plus"}`}></i>
                                    </div>
                                </div>
                            }>
                                <div className="mob-view-PI">
                                    <div className="edit-button">
                                        {editMode ? (
                                            ""
                                        ) : (
                                            <a onClick={() => setEditMode(true)} className="edit-a">Edit</a>
                                        )}
                                    </div>
                                    <div className="header">
                                        <div className="user-details">
                                            <div className="header" style={{ background: headerBackground }}>
                                                <div className='profile-details'>
                                                    <div className='p-icon-name' style={{ background: profileImageBackground }}>
                                                        {finalAvatarImage && (
                                                            <img src={finalAvatarImage} alt="Avatar" style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'contain', padding: '15px' }} />
                                                        )}
                                                        <div class="profile-edit-icon" onClick={() => setProfileImageEdit(true)}>
                                                            <i class="pi pi-pencil"></i>
                                                        </div>
                                                    </div>
                                                    <div className="user-details">
                                                        <h1 className="name">{studentDetails.firstName} {studentDetails.lastName}</h1>
                                                        <h2 className="id">(ID:{studentDetails.registrationId})</h2>
                                                    </div>
                                                </div>
                                                <div className='edit-profile-button'>
                                                    <Button icon="pi pi-pencil" className="p-e-button" label="" onClick={() => setVisible(true)} />
                                                </div>
                                            </div>

                                            <Dialog className="dialog-boxes" visible={profileImageEdit} modal header={profileEditHeader} footer={profileEditFooter} style={{ width: '50rem' }} onHide={() => setProfileImageEdit(false)}>
                                                <div className="d-header" style={{ background: bannerBackground }}>
                                                    <div className='d-profile-details'>
                                                        <div className='d-p-icon-name'
                                                            style={{ background: profilebackground }}>
                                                            {avatarImage && (
                                                                <img src={avatarImage} alt="Avatar" style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'contain', padding: '15px' }} />
                                                            )}
                                                        </div>
                                                        <div className="d-user-details">
                                                            <div className="d-name">{studentDetails.firstName} {studentDetails.lastName}</div>
                                                            <div className="d-id">(ID:{studentDetails.registrationId})</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="background-options">
                                                    <h3>Update Profile Picture</h3>
                                                    <p>Select a Color for background:</p>
                                                    <div className='inline-flex align-items-center justify-content-left' >
                                                        <div>
                                                            {predefinedColors.map((color, index) => (
                                                                <div className="inline-flex align-items-center justify-content-left"
                                                                    key={index}
                                                                    style={{ backgroundColor: color, width: '30px', height: '30px', margin: '5px', border: 'none', borderRadius: '50%' }}
                                                                    onClick={() => handleProfileBackgroundChange(color)}
                                                                ></div>
                                                            ))}
                                                        </div>
                                                        <div className="color-picker">
                                                            <div ref={colorPickerRef} className="color-picker-wrapper" style={{ position: 'relative', display: 'inline-block' }}>
                                                                <ColorPicker value={profileColorPicker} onChange={(e) => handleProfileBackgroundPickerChange(e.value)} style={{ width: '30px', height: '30px', marginBottom: '5px', border: 'none', borderRadius: '50%' }} />
                                                                <CgColorPicker style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none', color: 'black' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p>Select your avatar</p>
                                                        <div>
                                                            {profileAnimalImages.map((image, index) => (
                                                                <div className="inline-flex align-items-center justify-content-left" key={index}>
                                                                    <img
                                                                        src={image}
                                                                        alt={`Background ${index}`}
                                                                        style={{ width: '55px', height: '55px', margin: '5px', objectFit: 'contain', padding: '5px' }}
                                                                        onClick={() => handleProfileImageChange(image)}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div>
                                                            {profilePeopleImages.map((image, index) => (
                                                                <div className="inline-flex align-items-center justify-content-left" key={index}>
                                                                    <img
                                                                        src={image}
                                                                        alt={`Background ${index}`}
                                                                        style={{ width: '55px', height: '55px', margin: '5px', objectFit: 'contain', padding: '5px' }}
                                                                        onClick={() => handleProfileImageChange(image)}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Dialog>

                                            <Dialog className="dialog-boxes" visible={visible} modal header={backgroundEditHeader} footer={backgroundEditFooter} style={{ width: '50rem' }} onHide={() => setVisible(false)}>
                                                <div className="d-header" style={{ background: bannerBackground }}>
                                                    <div className='d-profile-details'>
                                                        <div className='d-p-icon-name'
                                                            style={{ background: profilebackground }}>
                                                            {avatarImage && (
                                                                <img src={avatarImage} alt="Avatar" style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'contain', padding: '15px' }} />
                                                            )}
                                                        </div>
                                                        <div className="d-user-details">
                                                            <div className="d-name">{studentDetails.firstName} {studentDetails.lastName}</div>
                                                            <div className="d-id">(ID:{studentDetails.registrationId})</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="background-options">
                                                    <h3>Update Background</h3>
                                                    <p>Choose a color:</p>
                                                    <div className='inline-flex align-items-center justify-content-left' >
                                                        <div>
                                                            {predefinedColors.map((color, index) => (
                                                                <div className="inline-flex align-items-center justify-content-left"
                                                                    key={index}
                                                                    style={{ backgroundColor: color, width: '30px', height: '30px', margin: '5px', border: 'none', borderRadius: '50%' }}
                                                                    onClick={() => handleBackgroundChange(color, 'color')}
                                                                ></div>
                                                            ))}
                                                        </div>
                                                        <div className="color-picker">
                                                            <div ref={colorPickerRef} className="color-picker-wrapper" style={{ position: 'relative', display: 'inline-block' }}>
                                                                <ColorPicker value={backgroundColorPicker} onChange={(e) => handleBackgroundPickerChange(e.value)} style={{ width: '30px', height: '30px', marginBottom: '5px', border: 'none', borderRadius: '50%' }} />
                                                                <CgColorPicker style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none', color: 'black' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p>Or choose an image:</p>
                                                        {backgroundImages.map((image, index) => (
                                                            <div className="inline-flex align-items-center justify-content-left" key={index}>
                                                                <img
                                                                    src={image}
                                                                    alt={`Background ${index}`}
                                                                    style={{ width: '120px', height: '90px', margin: '5px', objectFit: 'cover' }}
                                                                    onClick={() => handleBackgroundChange(image, 'image')}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Dialog>

                                        </div>
                                    </div>

                                    <div className="forms-section">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className="form-row">
                                                <div className="form-group">
                                                    <label htmlFor="email">Email</label>
                                                    <InputText id="email" value={studentDetails.email} onChange={(e) => handleInputChange('email', e.target.value)} disabled={!editMode} readOnly />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="schoolName">School Name</label>
                                                    <InputText id="schoolName" value={studentDetails.schoolName} onChange={(e) => handleInputChange('schoolName', e.target.value)} disabled={!editMode} readOnly />
                                                </div>
                                                {/* <div className="form-group">
                                                    <label htmlFor="dob">Date of Birth</label>
                                                    <Calendar id="dob" type="date" value={studentDetails.dob} onChange={(e) => handleInputChange('dob', e.target.value)} disabled={!editMode} className="p-inputtext-sm w-full" />
                                                </div> */}
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group">
                                                    <label>Phone Number</label>
                                                    <InputMask
                                                        mask="(999) 999-9999"
                                                        name="phoneNumber"
                                                        value={studentDetails.phoneNumber}
                                                        onChange={(e) => { handleInputChange('phoneNumber', e.target.value) }}
                                                        disabled={!editMode}
                                                        className="p-inputtext-sm w-full"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="grade">Grade</label>
                                                    <Dropdown id="grade" value={studentDetails.grade} options={gradeOptions} onChange={(e) => handleInputChange('grade', e.value)} disabled={!editMode} className="grade-dropdown full-width" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="gpa">High School GPA</label>
                                                    <InputText id="gpa" type="number" value={studentDetails.gpa} onChange={(e) => handleInputChange('gpa', e.target.value)} disabled={!editMode} />
                                                </div>
                                            </div>

                                            <h3 className="address-header m-0">Address</h3>
                                            <div className="form-row">
                                                <div className="form-group">
                                                    <label htmlFor="street">Street</label>
                                                    <InputText id="street" value={studentDetails.street} onChange={(e) => handleInputChange('street', e.target.value)} disabled={!editMode} />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="city">City</label>
                                                    <InputText id="city" value={studentDetails.city} onChange={(e) => handleInputChange('city', e.target.value)} disabled={!editMode} />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="zipcode">Zip Code</label>
                                                    <InputText id="zipcode" value={studentDetails.zipcode} onChange={(e) => handleInputChange('zipcode', e.target.value)} disabled={!editMode} />
                                                </div>
                                            </div>

                                            {editMode && <Button type="submit" label="Save" onClick={handleSubmit} className="p-mt-2 save-button" />}
                                        </form>
                                    </div>
                                </div>
                            </AccordionTab>

                            <AccordionTab header={
                                <div className="accordion-header">
                                    <div className="header-text">Documents</div>
                                    <div className="header-icon">
                                        <i className={`pi ${isTabOpen(1) ? "pi-minus" : "pi-plus"}`}></i>
                                    </div>
                                </div>
                            }>
                                <div className="mobile-view-doc">
                                    <div className="documents-section">
                                        <p>You can generate your resume using the Laulama provided design or you can upload your own design of your resume.</p>

                                        <div className="upload-section">
                                            {getResume ? (
                                                <div className='show-resume flex gap-2'>
                                                    <a href={`data:application/pdf;base64,${getResume}`} download={`${studentDetails.firstName} Resume.pdf`} style={{ textDecoration: "none", color: "#1067B8" }}>
                                                        {`${studentDetails.firstName}_${studentDetails.lastName}_Resume.pdf`}
                                                    </a>
                                                    <FaRegTrashAlt style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => { setShowUploader(false); setGetResume(false) }} />
                                                </div>
                                            ) : (
                                                !showUploader && (
                                                    <>
                                                        <Button
                                                            onClick={() => setShowUploader(true)}
                                                            label="Add My Resume"
                                                        />
                                                        <Button
                                                            label="Generate My Resume"
                                                            className="p-button-outlined generate-button"
                                                            onClick={handleResumeGenerator}
                                                        />
                                                    </>
                                                )
                                            )}
                                            {showUploader && (
                                                <div className="principal-page-left flex gap-4">
                                                    <FileUpload
                                                        chooseLabel="Choose File"
                                                        uploadLabel="Upload"
                                                        value={''}
                                                        onSelect={onUpload}
                                                        mode="basic"
                                                        accept=".pdf"
                                                        className=''
                                                    />

                                                    <Button onClick={handleSubmit} disabled={!isUploadEnabled} className='gap-2'>
                                                        <i className="pi pi-upload"></i>
                                                        Upload File
                                                    </Button>

                                                    <Button onClick={handleCancel} className='gap-2 p-button-secondary'>
                                                        <i className="pi pi-times"></i>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>

                            <AccordionTab header={
                                <div className="accordion-header">
                                    <div className="header-text">My PTP Goals</div>
                                    <div className="header-icon">
                                        <i className={`pi ${isTabOpen(1) ? "pi-minus" : "pi-plus"}`}></i>
                                    </div>
                                </div>
                            }>
                                <div className="">
                                    <MobilePTPGoals />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentProfile;