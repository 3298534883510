import React, { useState, useEffect } from "react";
import "./SAT-Scores.scss";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import Illustration from "../../../../../../Assets/Student/tableilustration.svg";
import { Image } from "primereact/image";
import { RadioButton } from "primereact/radiobutton";
import moment from "moment";
import { InputNumber } from "primereact/inputnumber";
import ErrorMessage from "../../../../../../Shared-Component/ErrorMessage/ErrorMessage";

const SAT_Scores = ({ getApiResponse }) => {
  const [toggleForm, setToggleForm] = useState(false);
  const [error, setError] = useState("");
  const [satNewData, setSatNewData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const satLocalStorageKey = "SAT_Scores";

  const satData = {
    date: "",
    evidenceBasedReadingAndWriting: "",
    math: "",
    essay_CR: "",
    essay_M: "",
    essay_W: "",
  };

  const [satformData, setSatFormData] = useState(satData);

  useEffect(() => {
    if (getApiResponse?.satScores?.scoresVersion.length > 0) {
      const response = getApiResponse?.satScores?.scoresVersion;
      const latestVersionNumber = ((response.length) - 1);
      const sat = getApiResponse?.satScores.scoresVersion[latestVersionNumber];
      if (sat?.scores.length > 0) {
        const satScores = sat.scores.map((item) => {
          var val = item.date;
          let formattedDate = "-";
          if (val && val !== "Invalid date" && val > 0) {
          const date = new Date(val);
          const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
          const year = date.getFullYear().toString().slice(2);
         formattedDate =  `${month}/${year}`;
          }
          return {
            date: formattedDate,
            evidenceBasedReadingAndWriting:
            item?.evidenceBasedReadingAndWriting,
            math: item?.math,
            essay_CR: item?.essay_CR,
            essay_M: item?.essay_M,
            essay_W: item?.essay_W,
          };
        });
        setSatNewData(satScores);
        localStorage.setItem(satLocalStorageKey, JSON.stringify(satScores));
      }
    }
  }, [getApiResponse]);

  useEffect(() => {
    const satStoredData = localStorage.getItem(satLocalStorageKey);
    if (satStoredData) {
      setSatNewData(JSON.parse(satStoredData));
    }
  }, []);

  const [errorEBRWs, setErrorEBRWs] = useState("");
  const [errorMathss, setErrorMathss] = useState("");
  const [errorEssayCRs, setErrorEssayCRs] = useState("");
  const [errorEssayMs, setErrorEssayMs] = useState("");
  const [errorEssayWs, setErrorEssayWs] = useState("");
  // const [errorSatDate, setErrorSatDate] = useState("");

  const handleSatChange = (event) => {
    const { name, value } = event.target;
    const intValue = parseInt(value, 10);

    switch (name) {
      // case "date":
      //   if (!value) {
      //     setErrorSatDate("Date field is required");
      //   } else {
      //     setErrorSatDate("");
      //   }
      //   break;
      case "evidenceBasedReadingAndWriting":
        if (intValue < 200 || intValue > 800) {
          setErrorEBRWs("The range must be 200-800");
        } else {
          setErrorEBRWs("");
        }
        break;
      case "math":
        if (intValue < 200 || intValue > 800) {
          setErrorMathss("The range must be 200-800");
        } else {
          setErrorMathss("");
        }
        break;
      case "essay_CR":
        if (intValue < 1 || intValue > 4) {
          setErrorEssayCRs("The range must be 1-4");
        } else {
          setErrorEssayCRs("");
        }
        break;
      case "essay_M":
        if (intValue < 1 || intValue > 4) {
          setErrorEssayMs("The range must be 1-4");
        } else {
          setErrorEssayMs("");
        }
        break;
      case "essay_W":
        if (intValue < 1 || intValue > 4) {
          setErrorEssayWs("The range must be 1-4");
        } else {
          setErrorEssayWs("");
        }
        break;

      default:
        break;
    }

    setSatFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSatSubmit = (e) => {
    e.preventDefault();

    const fieldLabelMapss = {
      // date: "Date",
      evidenceBasedReadingAndWriting: "EB R/W",
      math: "Math",
      essay_CR: "Essay CR",
      essay_M: "Essay M",
      essay_W: "Essay W",
    };
    const eeemessage = "This field is required";
    const errors = Object.entries(fieldLabelMapss).reduce(
      (acc, [field, label]) => {
        if (!satformData[field]) {
          acc[field] = eeemessage;
        }
        return acc;
      },
      {}
    );

    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([field, message]) => {
        switch (field) {
          // case "date":
          //   setErrorSatDate(message);
          //   break;
          case "evidenceBasedReadingAndWriting":
            setErrorEBRWs(message);
            break;
          case "math":
            setErrorMathss(message);
            break;
          case "essay_CR":
            setErrorEssayCRs(message);
            break;
          case "essay_M":
            setErrorEssayMs(message);
            break;
          case "essay_W":
            setErrorEssayWs(message);
            break;
          default:
        }
      });
      return;
    }

    if (
      // !satformData.date ||
      !satformData.evidenceBasedReadingAndWriting ||
      !satformData.math ||
      !satformData.essay_CR ||
      !satformData.essay_M ||
      !satformData.essay_W
    ) {
      setError("");
    } else if (
      // errorSatDate ||
      errorEBRWs ||
      errorMathss ||
      errorEssayCRs ||
      errorEssayMs ||
      errorEssayWs
    ) {
      setError("");
    } else {
      var selected_sat_date = moment.utc(satformData.date).format("MM/YYYY");
      const newSatTableData = {
        date: selected_sat_date,
        evidenceBasedReadingAndWriting:
          satformData.evidenceBasedReadingAndWriting,
        math: satformData.math,
        essay_CR: satformData.essay_CR,
        essay_M: satformData.essay_M,
        essay_W: satformData.essay_W,
      };

      if (editIndex !== null) {
        const updatedData = [...satNewData];
        var selected_date = moment.utc(satformData.date).format("MM/YYYY");
        const editedSatFormData = {
          date: selected_date,
          evidenceBasedReadingAndWriting: satformData.evidenceBasedReadingAndWriting,
          math: satformData.math,
          essay_CR: satformData.essay_CR,
          essay_M: satformData.essay_M,
          essay_W: satformData.essay_W,
        };
        updatedData[editIndex] = editedSatFormData;
        setSatNewData(updatedData);
        setEditIndex(null);
        setSatFormData(satData);
        localStorage.setItem(satLocalStorageKey, JSON.stringify(updatedData));
      } else {
        const updatedSatFormData = [...satNewData, newSatTableData];

        setSatFormData(satData);

        localStorage.setItem(
          satLocalStorageKey,
          JSON.stringify(updatedSatFormData)
        );
      }
      const satStoredData = localStorage.getItem(satLocalStorageKey);
      if (satStoredData) {
        setSatNewData(JSON.parse(satStoredData));
      }

      handleClick();
    }
  };

  const handleClick = () => {
    setEditIndex(null);
    setSatFormData(satData);
    setToggleForm(!toggleForm);
  };

  const handleEdit = (rowData, index) => {
    setToggleForm(true);
    const dateValue = rowData.date;
    const [month, year] = dateValue.split('/');
    const formattedDate = new Date(`${month}/01/${year}`);
    setSatFormData({ ...rowData, date: formattedDate });
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    const updatedData = [...satNewData];
    updatedData.splice(index, 1);
    setSatNewData(updatedData);
    setEditIndex(null);
    localStorage.setItem(satLocalStorageKey, JSON.stringify(updatedData));
    setSatFormData(satData);
  };

  const calculateTotal = (rowData) => {
    const { evidenceBasedReadingAndWriting, math} = rowData;
    const total = evidenceBasedReadingAndWriting + math;
    return total;
};

  return (
    <div>
      <div className="extra-activities-section">
        {toggleForm ? (
          <div className="extra-heading">
            <form onSubmit={handleSatSubmit}>
              {error && <ErrorMessage message={error} isSuccess={true} />}
              <div className="grid act-container">
                <div className="act-label col-4 flex flex-column gap-2">
                  <label>Date(MM/YYYY)</label>
                  <Calendar
                    name="date"
                    view="month" 
                    dateFormat="mm/yy"
                    value={satformData.date}
                    onChange={handleSatChange}
                    showIcon
                    className={`w-full md:w-18rem 
                    // $
                    // {
                    //   errorSatDate ? "error" : ""
                    // }`
                  }
                  />
                  <span className="date-optional">*Optional</span>
                  {/* {errorSatDate && (
                    <div>
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorSatDate}
                      </span>
                    </div>
                  )} */}
                </div>
                <div className="form-content col-8 flex flex-column gap-2 mt-5">
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act w-full md:w-14rem mr-1">
                      EB R/W
                    </label>
                    <InputNumber
                      name="evidenceBasedReadingAndWriting"
                      value={satformData.evidenceBasedReadingAndWriting}
                      onValueChange={handleSatChange}
                      keyfilter="num"
                      placeholder="Enter your points: 200-800"
                      className={`w-full md:w-16rem ${
                        errorEBRWs ||
                        (error && !satformData.evidenceBasedReadingAndWriting)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorEBRWs && (
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorEBRWs}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act md:w-14rem">
                      Math
                    </label>
                    <InputNumber
                      name="math"
                      value={satformData.math}
                      onValueChange={handleSatChange}
                      keyfilter="num"
                      placeholder="Enter your points: 200-800"
                      className={`w-full md:w-16rem ${
                        errorMathss || (error && !satformData.math)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorMathss && (
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorMathss}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act md:w-14rem">
                      Essay-CR
                    </label>
                    <InputNumber
                      name="essay_CR"
                      value={satformData.essay_CR}
                      onValueChange={handleSatChange}
                      keyfilter="num"
                      placeholder="Enter your points: 1-4"
                      className={`w-full md:w-16rem ${
                        errorEssayCRs || (error && !satformData.essay_CR)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorEssayCRs && (
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorEssayCRs}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act md:w-14rem">
                      Essay-M
                    </label>
                    <InputNumber
                      name="essay_M"
                      value={satformData.essay_M}
                      onValueChange={handleSatChange}
                      keyfilter="num"
                      placeholder="Enter your points: 1-4"
                      className={`w-full md:w-16rem ${
                        errorEssayMs || (error && !satformData.essay_M)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorEssayMs && (
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorEssayMs}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 my-2">
                    <label className="dropdown-label-act md:w-14rem">
                      Essay-W
                    </label>
                    <InputNumber
                      name="essay_W"
                      value={satformData.essay_W}
                      onValueChange={handleSatChange}
                      keyfilter="num"
                      placeholder="Enter your points: 1-4"
                      className={`w-full md:w-16rem ${
                        errorEssayWs || (error && !satformData.essay_W)
                          ? "error"
                          : ""
                      }`}
                    />
                    {errorEssayWs && (
                      <span
                        className="error-message flex justify-content-center align-items-center text-base"
                        style={{ color: "#E04A4A" }}
                      >
                        {errorEssayWs}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <footer className="Extra-activities-form-footer gap-4">
                <Button
                  className="scores-button"
                  label="Cancel"
                  severity="info"
                  onClick={handleClick}
                />
                <Button
                  type="submit"
                  value="Submit"
                  label={editIndex !== null ? "Update SAT Score" : "Add SAT Score"}
                  severity="secondary"
                />
              </footer>
            </form>
          </div>
        ) : (
          <div>
            <div
              className="flex flex-wrap justify-content-end gap-1 pt-4 pb-4"
              onClick={handleClick}
            >
              <div className="addextra-text">+ Add SAT Score</div>
            </div>

            {satNewData.length === 0 ? (
              <div className="flex flex-column justify-content-center align-items-center">
                <Image src={Illustration} alt="Image" className="extra-image" />
                <p className="ex-p">
                  You will see information here when you add your SAT Scores
                </p>
                <Button className='m-3' label="Add SAT Score" severity="secondary" onClick={handleClick}/>
              </div>
            ) : (
              <div className="extra-activities-tables pt-4 pb-4">
                <DataTable
                  value={satNewData}
                  tableStyle={{ minWidth: "50rem" }}
                  className="datatable-container"
                >
                  <Column field="date" header="Date"   body={(rowData) => rowData.date && rowData.date !== "Invalid date" ? rowData.date : "-"}/>
                  <Column field="total" header="Total" body={(rowData) => calculateTotal(rowData)}></Column>
                  <Column field="evidenceBasedReadingAndWriting" header="EB R/W" />
                  <Column field="math" header="Math" />
                  <Column field="essay_CR" header="Essay-CR" />
                  <Column field="essay_M" header="Essay-M" />
                  <Column field="essay_W" header="Essay-W" />
                  <Column
                    header="Actions"
                    body={(rowData, column) => (
                      <div className="actions">
                        <div>
                          <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-success mr-1"
                            onClick={() => handleEdit(rowData, column.rowIndex)}
                          />
                          <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-danger ml-1"
                            onClick={() => handleDelete(column.rowIndex)}
                          />
                        </div>
                      </div>
                    )}
                  />
                </DataTable>

                <ul className='hide-on-large-screens'>
                  {satNewData.map((item, index) => (
                    <li key={item.id}>
                      <div>
                        <p className='activity-heading flex justify-content-around align-items-center'>
                          Date <span>{item.date}</span>
                        </p>
                      </div>
                      <div className='px-6 py-4'>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Total</strong> {calculateTotal(item)}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>EB R/W</strong> {item.evidenceBasedReadingAndWriting}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Math</strong> {item.math}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Essay-CR</strong> {item.essay_CR}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Essay-M</strong> {item.essay_M}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Essay-W</strong> {item.essay_W}
                        </div>
                        <div className='flex justify-content-between align-items-center'>
                          <strong>Actions</strong>
                          <div className="actions ">
                            <Button
                              icon="pi pi-pencil"
                              className="p-button-rounded p-button-success mr-1"
                              onClick={() => handleEdit(item, index)}
                            />
                            <Button
                              icon="pi pi-trash"
                              className="p-button-rounded p-button-danger ml-1"
                              onClick={() => handleDelete(index)}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>

              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SAT_Scores;
