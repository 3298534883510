import React, { useState, useEffect } from "react";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Link } from "react-router-dom";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import "./HighSchoolGRsection.scss";
import axios from 'axios';
import { CONTENT_HEADERS, GET_HIGH_SCHOOL_CREDITS, UPDATE_HIGH_SCHOOL_PROGRESS } from '../../../../../../Utils/StudentService';
import Spinner from '../../../../../../Shared-Component/Spinner/Gif-Loader';

const HighSchoolGRsection = ({ getCredits, triggerAPI, onStatusChange }) => {

    const [showGraduation, setShowGraduation] = useState(window.innerWidth <= 1008);
    const [showMore, setShowMore] = useState(false);
    const [allZero, setAllZero] = useState();
    const [loading, setLoading] = useState(false);
    const [credits, setCredits] = useState(getCredits);
    const [highSchoolProgressId, setHighSchoolProgressId] = useState('');
    const [creditsCompletedId, setCreditsCompletedId] = useState('');
    const [creditsPendingId, setCreditsPendingId] = useState('');
    const [totalCreditId, setTotalCreditId] = useState('');
    const [newValue, setNewValue] = useState('');

    useEffect(() => {
    }, [showMore]);
    const toggleShowMore = () => {
        setShowMore((prevShowMore) => !prevShowMore);
    };

    useEffect(() => {
        const handleResize = () => {
            setShowGraduation(window.innerWidth <= 1008);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const formatDataForTreeTable = (data) => {
        let keyCounter = 0;
        const generateKey = () => keyCounter++;

        let totalCredits = 0;
        let completedCredits = 0;
        let pendingCredits = 0;

        const processCourses = (courses) => {
            return courses.map((course) => ({
                key: course.categoryName,
                data: {
                    area: course.course,
                    status: course.status,
                    creditValue: course.creditValue,
                    course: course.area,
                },
            }));
        };

        const processElectiveCategories = (categories) => {
            return categories
                .filter(category => category.categoryName !== null && category.electiveCategories && category.electiveCategories.length > 0)
                .map(category => ({
                    key: generateKey(),
                    data: {
                        name: category.categoryName,
                        enrolled: category.enrolled,
                        completed: category.completed,
                    },
                    children: category.electiveCategories.flatMap(electiveCategory =>
                        electiveCategory.courses ? processCourses(electiveCategory.courses) : []
                    ),
                }));
        };

        const formattedData = data ? Object.entries(data)
            .filter(([key]) => !['creditsCompleted', 'creditsPending', 'totalGraduationCredits', 'highSchoolProgressId'].includes(key))
            .map(([key, value]) => {
                const formattedName = key.replace(/([A-Z])/g, ' $1').trim();
                let children = [];

                if (formattedName === 'Elective' && value.electiveCategories) {
                    children = processElectiveCategories(value.electiveCategories);
                } else if (value.courses) {
                    children = processCourses(value.courses);
                }

                totalCredits += value.totalCredits || 0;
                completedCredits += value.completedCredits || 0;
                pendingCredits += value.pendingCredits || 0;

                return {
                    key: formattedName,
                    data: {
                        name: formattedName,
                        totalCredits: value.totalCredits,
                        completedCredits: value.completedCredits,
                        pendingCredits: value.pendingCredits,
                        newValue: `${value.completedCredits}/${value.totalCredits}`,
                    },
                    children: children,
                };
            }) : [];

        const totalRow = {
            key: 'total',
            data: {
                name: 'Total',
                totalCredits: totalCredits,
                completedCredits: completedCredits,
                pendingCredits: pendingCredits,
                newValue: `${completedCredits}/${totalCredits}`,
            },
            children: [],
        };

        formattedData.push(totalRow);

        return formattedData;
    };


    const [nodes, setNodes] = useState([]);

    useEffect(() => {
        const data = credits ? credits : getCredits;
        const formattedData = formatDataForTreeTable(data);
        setNodes(formattedData);
        setHighSchoolProgressId(data.highSchoolProgressId)
        setCreditsCompletedId(getCredits.creditsCompleted)
        setCreditsPendingId(getCredits.creditsPending)
        setTotalCreditId(getCredits.totalGraduationCredits)
        setNewValue(getCredits.creditsCompleted + '/' + getCredits.totalCredits)
    }, [credits, getCredits]);

    useEffect(() => { }, [nodes]);

    const getCreditsScore = () => {
        setLoading(true);
        axios
            .get(GET_HIGH_SCHOOL_CREDITS, { headers: CONTENT_HEADERS })
            .then((response) => {
                setCredits(response.data);
                setHighSchoolProgressId(response.data.highSchoolProgressId)
                setLoading(false);
            })
            .catch((error) => console.log("Error getting high school progress data", error))
    }

    useEffect(() => {
        if (triggerAPI) {
            setLoading(true);
            setTimeout(() => {
                getCreditsScore();
            }, 5000);
        }
    }, [triggerAPI]);


    const handleSaveButtonClicks = (rowData) => {
        const payload = {
            subjects: [
                {
                    category: rowData.data.course,
                    courses: [],
                    electiveCategories: []
                }
            ],
            electiveCourses: []
        };

        const subElectives = ["English Elective", "Social Elective", "Science Elective", "Math Elective", "PE Elective"];
        const validAreas = ["English", "Social Studies", "Math", "Science", "PE", "Health", "PTP"];
        const validElectiveAreas = ["Early College Electives", "JROTC Electives"];

        if (validAreas.includes(rowData.data.course)) {
            payload.subjects[0].courses.push({
                course: rowData.data.area,
                status: "Completed"
            });
        } else if (subElectives.includes(rowData.data.course)) {
            payload.subjects[0].electiveCategories.push({
                courseCategory: rowData.data.course,
                courses: [
                    {
                        course: rowData.data.area,
                        status: "Completed"
                    }
                ]
            });
        } else if (rowData.data.course === "Electives") {
            payload.subjects[0].electiveCategories.push({
                category: rowData.data.course,
                courses: [
                    {
                        course: rowData.course,
                        status: "Completed"
                    }
                ]
            });
        } else if (!validAreas.includes(rowData.data.course)) {
            let courseCategory = !validElectiveAreas.includes(rowData.data.course) ? rowData.data.course : "Uncategorized";

            payload.electiveCourses.push({
                courseCategory: courseCategory,
                courses: [
                    {
                        course: rowData.data.area,
                        status: "Completed"
                    }
                ]
            });
            payload.subjects = [];
        }

        if (payload.subjects[0]?.electiveCategories.length === 0) {
            payload.subjects[0].electiveCategories = [];
        }

        if (payload.subjects[0]?.electiveCategories.length > 0) {
            payload.subjects[0].category = "";
            payload.subjects[0]?.courses.forEach(course => {
                course.course = "";
                course.status = "";
            });
        }
        setLoading(true);
        axios
            .put(UPDATE_HIGH_SCHOOL_PROGRESS + highSchoolProgressId, payload, { headers: CONTENT_HEADERS })
            .then(() => {
                getCreditsScore();
                onStatusChange();
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            })
    };

    const handleUndoButtonClicks = (rowData) => {
        const payload = {
            subjects: [
                {
                    category: rowData.course,
                    courses: [],
                    electiveCategories: []
                }
            ],
            electiveCourses: []
        };

        const subElectives = ["English Elective", "Social Elective", "Science Elective", "Math Elective", "PE Elective"];
        const validAreas = ["English", "Social Studies", "Math", "Science", "PE", "Health", "PTP"];
        const validElectiveAreas = ["Early College Electives", "JROTC Electives"];

        if (validAreas.includes(rowData.course)) {
            payload.subjects[0].courses.push({
                course: rowData.area,
                status: "In Progress"
            });
        } else if (subElectives.includes(rowData.course)) {
            payload.subjects[0].electiveCategories.push({
                courseCategory: rowData.course,
                courses: [
                    {
                        course: rowData.area,
                        status: "In Progress"
                    }
                ]
            });
        } else if (rowData.course === "Electives") {
            payload.subjects[0].electiveCategories.push({
                category: rowData.data.course,
                courses: [
                    {
                        course: rowData.course,
                        status: "In Progress"
                    }
                ]
            });
        } else if (!validAreas.includes(rowData.course)) {
            let courseCategory = !validElectiveAreas.includes(rowData.course) ? rowData.course : "Uncategorized";

            payload.electiveCourses.push({
                courseCategory: courseCategory,
                courses: [
                    {
                        course: rowData.area,
                        status: "In Progress"
                    }
                ]
            });
            payload.subjects = [];
        }

        if (payload.subjects[0]?.electiveCategories.length === 0) {
            payload.subjects[0].electiveCategories = [];
        }

        if (payload.subjects[0]?.electiveCategories.length > 0) {
            payload.subjects[0].category = "";
            payload.subjects[0]?.courses.forEach(course => {
                course.course = "";
                course.status = "";
            });
        }

        setLoading(true);
        axios
            .put(UPDATE_HIGH_SCHOOL_PROGRESS + highSchoolProgressId, payload, { headers: CONTENT_HEADERS })
            .then(() => {
                getCreditsScore();
                onStatusChange();
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            })
    }

    const renderCourseDetails = (nodeDatas) => {

        const nodeData = nodeDatas?.data;

        if (nodeData?.courses !== null || undefined) {
            if (nodeData?.area?.length > 0) {
                return (
                    <div className="gr-section-table">
                        <div
                            style={{
                                position: 'absolute',
                                display: 'flex',
                                width: '-webkit-fill-available',
                                justifyContent: 'space-between'
                            }}
                            className="col hs-datatable"
                        >
                            <span className="col-5">{nodeData.area}</span>

                            <span className="col-3"><strong>Credit:</strong> {nodeData.creditValue}</span>

                            <span className="col-4" style={{ cursor: (nodeData.status === "In Progress" || nodeData.status === "Completed") ? "pointer" : "not-allowed" }}>
                                {
                                    nodeData.status === "In Progress" ? (
                                        <span onClick={() => handleSaveButtonClicks(nodeDatas)} style={{ color: "#1575E5" }}>
                                            Mark as Complete
                                        </span>
                                    ) : nodeData.status === "Completed" ? (
                                        <span>
                                            <span style={{ color: "gray", marginRight: "10px", cursor: "not-allowed" }}>
                                                Completed
                                            </span>
                                            <span style={{ color: "gray", marginRight: "10px" }}>|</span>
                                            <span onClick={() => handleUndoButtonClicks(nodeData)} style={{ color: "#1575E5" }}>
                                                Undo
                                            </span>
                                        </span>
                                    ) : nodeData.status
                                }
                            </span>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                width: '-webkit-fill-available',
                                justifyContent: 'space-between'
                            }}
                            className="col hide-on-large-screens"
                        >
                            <span className="col-5">{nodeData.area}</span>

                            <span className="col-4" style={{ cursor: (nodeData.status === "In Progress" || nodeData.status === "Completed") ? "pointer" : "not-allowed" }}>
                                {
                                    nodeData.status === "In Progress" ? (
                                        <span onClick={() => handleSaveButtonClicks(nodeDatas)} style={{ color: "#1575E5" }}>
                                            Mark as Complete
                                        </span>
                                    ) : nodeData.status === "Completed" ? (
                                        <span>
                                            <span style={{ color: "gray", marginRight: "10px", cursor: "not-allowed" }}>
                                                Completed
                                            </span>
                                            <span style={{ color: "gray", marginRight: "10px" }}>|</span>
                                            <span onClick={() => handleUndoButtonClicks(nodeData)} style={{ color: "#1575E5" }}>
                                                Undo
                                            </span>
                                        </span>
                                    ) : nodeData.status
                                }
                            </span>
                        </div>

                    </div>
                );
            }
        }
    };

    const nameTemplate = (node) => {
        const { name, enrolled, completed } = node.data;

        return (
            <>
                <div className='col-12'>
                    {name}
                    {renderCourseDetails(node)}
                </div>

                <div className='mobile-screen-hide-text'>
                    {enrolled !== undefined && completed !== undefined && (
                        <div className="mobile-screen-hide-text flex absolute" style={{
                            width: "-webkit-fill-available",
                            justifyContent: "space-around",
                        }}>
                            <span className=" "><strong>Enrolled:</strong> {enrolled}</span>
                            <span className=" "><strong>Completed:</strong> {completed}</span>
                        </div>
                    )}
                </div>
            </>
        );
    };
    return (

        <div className="Student-GR-card-container">
            <>
                {showGraduation ? (
                    <div>
                        <div className="GR-Tabview">
                            <div className="gr-section">
                                <div className="circular-progress-bar" style={{ width: 300, }}>
                                    <CircularProgressbarWithChildren value={creditsCompletedId}>
                                        <div>
                                            <div style={{ color: "#2F2E41", fontSize: "24px", textAlign: "center" }} className="circular-credits"><span style={{ fontSize: "32px", fontWeight: "bold" }}>{creditsCompletedId}</span> /24</div>
                                            <div style={{ color: "#2F2E41", fontSize: "24px", textAlign: "center" }} className="circular-credits">Credits</div>
                                        </div>
                                    </CircularProgressbarWithChildren>
                                </div>

                                <div className="creditValue-section">
                                    <p>Credits Completed = {creditsCompletedId}</p>
                                    <p>Credits Pending = {creditsPendingId}</p>
                                    <p>Total Credits for Graduation = {totalCreditId}</p>
                                </div>
                            </div>

                            {showMore && (
                                <div className="progressbars-section">
                                    <TreeTable value={nodes}>
                                        <Column field="area" className='full-width-column' header="Area" expander body={nameTemplate} />
                                        <Column field="newValue" header="Completed / Total Credits" />
                                    </TreeTable>
                                </div>
                            )}
                        </div>

                        <div className="view-button">
                            {showMore ? (
                                <Link onClick={toggleShowMore} style={{ color: '#1067B8', fontSize: '16px' }}>View Less</Link>
                            ) : (
                                <Link onClick={toggleShowMore} style={{ color: '#1067B8', fontSize: '16px' }}>View More</Link>
                            )}
                        </div>
                    </div>

                ) : (
                    // Content for monitor/desktop devices
                    < div className="GR-view">

                        <div className="circular-bar col-4">
                            <div className="circular-progress-bar">
                                <CircularProgressbarWithChildren value={creditsCompletedId}>
                                    <div>
                                        <div style={{ color: "#2F2E41", fontSize: "24px", textAlign: "center" }} className="circular-credits"><span style={{ fontSize: "32px", fontWeight: "bold" }}>{creditsCompletedId}</span> /24</div>
                                        <div style={{ color: "#2F2E41", fontSize: "24px", textAlign: "center" }} className="circular-credits">Credits</div>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div>
                                <p>Credits Completed = {creditsCompletedId}</p>
                                <p>Credits Pending = {creditsPendingId}</p>
                                <p>Total Credits for Graduation = {totalCreditId}</p>
                            </div>
                        </div>
                        <div className="progressbars-section col-8">
                            <div className="spinner-section">
                                {loading ? <Spinner /> : null}
                            </div>

                            <TreeTable value={nodes}>
                                <Column field="area" className='full-width-column' header="Area" expander body={nameTemplate} />
                                <Column field="totalCredits" header="Credits for Graduation" />
                                <Column field="completedCredits" header="Completed" />
                                <Column field="pendingCredits" header="Enrolled" />
                            </TreeTable>
                        </div>
                    </div>
                )}
            </>
        </div >

    );
}

export default HighSchoolGRsection;
