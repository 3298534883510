import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import { Card } from 'primereact/card';
import Waitingimg from '../../Assets/Wait-teachers-ilustration.svg';
import '../../Shared-Component/PieChart/PieChart.scss';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const PieChart = ({ pieChartData, isDataAvailable, treeTableData, academiesData }) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [showDivider, setShowDivider] = useState(false);
    const [selectedOptionName, setSelectedOptionName] = useState("");
    const [data, setData] = useState(false);
    const [transformedData, setTransformedData] = useState([]);
    const [transformedAcademiesData, setTransformedAcademiesData] = useState([]);

    const careerPlans = [
        {
            name: "Educational Goal",
            labels: [
                "High School Diploma",
                "Technical or vocational certificate",
                "Graduate degree (MD, MA, JD, PhD, MBA)",
                "Two-year college degree (AA/AS)",
                "Four-year college degree (BA/BS)",
                "I'm not sure",
            ],
            data: Object.values(pieChartData?.educationalGoalResponse || {}),
        },
        {
            name: "Cumulative GPA Goal",
            labels: [
                "Cum Laude – 3.0 to 3.5",
                "Magna Cum Laude – 3.5+ to 3.8",
                "Summa Cum Laude – 3.8+",
                "Valedictorian – 4.0+ and met requirements of one Honors Recognition Certificate ",
                "I'm not sure",
            ],
            data: Object.values(pieChartData?.cumulativeGPAGoalResponse || {}),
        },
        {
            name: "Certificate Goal",
            labels: ["Academic Honors", "STEM Honors", "CTE Honors", "I'm not sure"],
            data: Object.values(pieChartData?.certificateGoalResponse || {}),
        },
        {
            name: "After HS Plan",
            labels: [
                "Go to a vocational or trade school",
                "Go to a four-year college/University*",
                "Find a Job",
                "Go to a two-year college/Community College*",
                "Join the military",
                "I'm not sure",
            ],
            data: Object.values(pieChartData?.highSchoolPlanResponse || {}),
        },
        {
            name: "Career Goals",
            labels: [
                "Healthcare and Medicine",
                "Technology and IT",
                "Business and Finance",
                "Engineering",
                "Arts and Creative Industries",
                "Education and Academia",
                "Science and Research",
                "Social Services",
                "Law and Legal Services",
                "Architecture and Design",
                "Hospitality and Tourism",
                "Agriculture and Environmental Sciences",
                "Physical and Sports Sciences",
                "Aviation and Aerospace",
                "Culinary Arts",
                "Entertainment and Performing Arts",
                "Language and Linguistics",
                "Automotive and Mechanics",
                "Social Media and Digital Marketing",
                "Military",
            ],
            data: Object.values(treeTableData?.careerGoalResponse || {}),
        },
        {
            name: "CTE Pathway",
            labels: [
                "Agriculture, Food & Natural Resources",
                "Business Management Finance & Marketing",
                "Health Services",
                "Culinary Arts",
                "Sustainable Hospitality & Tourism",
                "Education",
                "Building & Construction",
                "Media & Entertainment",
                "Engineering Technology",
                "Transportation Services",
                "Information Technology & Digital Transformation",
                "Manufacturing",
            ],
            data: Object.values(academiesData?.academiesResponse || {}),
        },
    ];

    const [selectedCareerPlan, setSelectedCareerPlan] = useState(careerPlans[0]);

    const chartDataMap = careerPlans.reduce((allgoals, plan) => {
        allgoals[plan.name] = {
            labels: plan.labels,
            datasets: [
                {
                    data: plan.data,
                    backgroundColor: ['#3B8A6E', '#91BF82', '#FC5145', '#EBB448', '#FFB6B6', '#6C63FF', '#FFD700', '#F943BC', '#7FFF00'],

                    borderWidth: [0],
                }
            ]
        };
        return allgoals;
    }, []);

    useEffect(() => {
        if (treeTableData && Object.keys(treeTableData).length > 0) {
            const allZeros = Object.values(treeTableData).every(obj =>
                Object.values(obj).every(val =>
                    typeof val === 'object' && Object.values(val).every(innerVal => innerVal === 0)
                )
            );
            if (allZeros) {
                setTransformedData([]);
            } else {
                let totalMale = 0;
                let totalFemale = 0;
                let total = 0;

                Object.entries(treeTableData).forEach(([careerArea, data]) => {
                    if (careerArea !== 'totalFemale' && careerArea !== 'totalMale' && careerArea !== 'total') {
                        totalMale += data.male || 0;
                        totalFemale += data.female || 0;
                        total += data.total || 0;
                    }
                });

                const totalData = {
                    key: 'total',
                    data: {
                        name: 'Total',
                        female: totalFemale,
                        male: totalMale,
                        total: total
                    },
                    children: []
                };
                console.log(totalData)

                const transformed = Object.entries(treeTableData)
                    .filter(([key]) => key !== 'totalFemale' && key !== 'totalMale' && key !== 'total')
                    .map(([careerArea, data]) => {
                        const roles = Object.entries(data)
                            .filter(([key]) => key !== 'male' && key !== 'female' && key !== 'total')
                            .map(([role, roleData]) => ({
                                key: `${careerArea}-${role}`,
                                data: {
                                    name: role,
                                    female: roleData.female,
                                    male: roleData.male,
                                    total: roleData.total
                                }
                            }));

                        return {
                            key: careerArea,
                            data: {
                                name: careerArea,
                                female: data.female,
                                male: data.male,
                                total: data.total
                            },
                            children: roles
                        };
                    });

                transformed.push(totalData);
                console.log("Transformed career Data:", transformed);
                setTransformedData(transformed);
            }
        }
    }, [treeTableData]);


    useEffect(() => {
        if (academiesData) {
            const allZeros = Object.values(academiesData).every(obj =>
                Object.values(obj).every(val => val === 0)
            );
            if (allZeros) {
                setTransformedAcademiesData([]);
            } else {
                let totalMale = 0;
                let totalFemale = 0;
                let total = 0;

                Object.entries(academiesData).forEach(([academyName, academyDetails]) => {
                    totalMale += academyDetails.male || 0;
                    totalFemale += academyDetails.feMale || 0;
                    total += academyDetails.total || 0;
                });

                const totalData = {
                    academyName: "Total",
                    male: totalMale,
                    feMale: totalFemale,
                    total: total
                };

                const transformedData = Object.entries(academiesData)
                    .filter(([academyName]) => !['maleTotal', 'femaleTotal', 'total'].includes(academyName))
                    .map(([academyName, academyDetails]) => ({
                        academyName,
                        male: academyDetails.male,
                        feMale: academyDetails.feMale,
                        total: academyDetails.total
                    }));

                const transformedWithTotal = [...transformedData, totalData];
                setTransformedAcademiesData(transformedWithTotal);
                console.log("Transformed Academies Data:", transformedWithTotal);
            }
        }
    }, [academiesData]);


    useEffect(() => {
        const handleResize = () => {
            if (selectedCareerPlan) {
                setChartData(chartDataMap[selectedCareerPlan.name]);
                if (window.innerWidth < 768) {
                    setChartOptions({
                        plugins: {
                            legend: {
                                position: 'bottom',
                                labels: {
                                    usePointStyle: true,
                                    padding: 25,
                                    borderWidth: 2,
                                    borderColor: 'rgba(0, 0, 0, 0.2)',
                                },
                            },
                        },
                    });

                    setShowDivider(true);
                    setSelectedOptionName(selectedCareerPlan.name);
                } else {
                    setChartOptions({
                        plugins: {
                            legend: {
                                position: 'right',
                                labels: {
                                    usePointStyle: true,
                                    padding: 25,
                                    borderWidth: 2,
                                    borderColor: 'rgba(0, 0, 0, 0.2)',
                                },
                            },
                        },
                    });

                    setShowDivider(true);
                    setSelectedOptionName(selectedCareerPlan.name);
                }
            } else {
                setShowDivider(false);
                setSelectedOptionName("");
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [selectedCareerPlan]);

    useEffect(() => {
        if (selectedCareerPlan) {
            const selectedPlan = careerPlans.find(plan => plan.name === selectedCareerPlan.name);
            const hasData = selectedPlan.data.some(value => value !== 0);
            setData(hasData);
        }
    }, [selectedCareerPlan, careerPlans]);
    

    return (
        <div className='pie-chart-container h-full'>
            <Card className='pie-card-section' >
                <div className='dropdown-section flex justify-content-between align-items-center flex-auto px-4 py-4'>
                    <div>
                        <h3><b>{isDataAvailable ? selectedOptionName : ""}</b></h3>
                    </div>
                    <div>
                        {isDataAvailable ?
                            <Dropdown
                                value={selectedCareerPlan}
                                onChange={(e) => setSelectedCareerPlan(e.value)}
                                options={careerPlans}
                                optionLabel="name"
                                placeholder="Select One"
                                className="sm:w-15rem"
                                disabled={!isDataAvailable}
                            />
                            :
                            <Dropdown
                                value={!selectedCareerPlan}
                                onChange={(e) => setSelectedCareerPlan(e.value)}
                                options={careerPlans}
                                optionLabel="name"
                                placeholder="Select One"
                                className="sm:w-15rem"
                                disabled={!isDataAvailable}
                            />
                        }
                    </div>
                </div>

                {showDivider && <Divider />}
                {selectedCareerPlan && isDataAvailable ? (
                    <div>
                        {data ? (
                            <div className='pie-chart-section' style={{ height: '67vh' }}>
                                <div className="card flex justify-content-center align-items-center pie" >
                                    <Chart
                                        type="pie"
                                        data={chartDataMap[selectedCareerPlan.name]}
                                        options={chartOptions}
                                        className="pie-chart"
                                    />
                                </div>
                            </div>
                        ) : (
                            selectedCareerPlan.name !== "Career Goals" && selectedCareerPlan.name !== "CTE Pathway" && (
                                <div className='pie-chart-section' style={{ height: '67vh', display: "grid", justifyContent: "center" }}>
                                    <div className='card flex align-items-center justify-content-center'>
                                        <p>No data available for {selectedCareerPlan.name}</p>
                                    </div>
                                    <div className='wait-img flex align-items-center justify-content-center p-3 '>
                                        <img src={Waitingimg} alt='Loginimage' />
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                ) : (
                    <div className='p-6 wait-section'>
                        <div className='wait-img flex align-items-center justify-content-center p-3 '>
                            <img src={Waitingimg} alt='Loginimage' />
                        </div>
                        <div className='wait-content flex align-items-center justify-content-center w-auto'>
                            <p>Once students fill out their 10-year plan, you will start seeing information here. </p>
                        </div>
                    </div>
                )}

                <div>
                    {selectedCareerPlan.name === "Career Goals" && (
                        <div className="p-4">
                            {transformedData.length === 0 && (
                                <div className='pie-chart-section' style={{ height: '67vh', display: "grid", justifyContent: "center" }}>
                                    <div className='card flex align-items-center justify-content-center'>
                                        <p>No data available for {selectedCareerPlan.name}</p>
                                    </div>
                                    <div className='wait-img flex align-items-center justify-content-center p-3'>
                                        <img src={Waitingimg} alt='Waiting image' />
                                    </div>
                                </div>
                            )}
                            {transformedData.length > 0 && (
                                <div className="card table">
                                    <TreeTable value={transformedData} showGridlines className="tree-table" >
                                        <Column field="name" header="Career Areas" expander></Column>
                                        <Column field="female" header="Female"></Column>
                                        <Column field="male" header="Male"></Column>
                                        <Column field="total" header="Students"></Column>
                                    </TreeTable>
                                </div>
                            )}

                        </div>
                    )}

                </div>


                <div>
                    {selectedCareerPlan.name === "CTE Pathway" && (
                        <div className="p-4">
                            {transformedAcademiesData.length === 0 && (
                                <div className='pie-chart-section' style={{ height: '67vh', display: "grid", justifyContent: "center" }}>
                                    <div className='card flex align-items-center justify-content-center'>
                                        <p>No data available for {selectedCareerPlan.name}</p>
                                    </div>
                                    <div className='wait-img flex align-items-center justify-content-center p-3'>
                                        <img src={Waitingimg} alt='Waiting image' />
                                    </div>
                                </div>
                            )}
                            {transformedAcademiesData.length > 0 && (
                                <div className="card table">
                                    <DataTable value={transformedAcademiesData} showGridlines tableStyle={{ minWidth: '50rem' }}>
                                        <Column field="academyName" header="Pathway Name"></Column>
                                        <Column field="feMale" header="Female"></Column>
                                        <Column field="male" header="Male"></Column>
                                        <Column field="total" header="Students"></Column>
                                    </DataTable>
                                </div>
                            )}

                        </div>
                    )}
                </div>

            </Card>
        </div>
    );
}

export default PieChart;

