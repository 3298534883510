import React, { useEffect, useState } from 'react';
import '../Career-Goals-Form/CareerGoalsForm.scss';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import stethoscope from '../../../../../Assets/Student/Icons/stethoscope-solid.svg';
import microchip from '../../../../../Assets/Student/Icons/microchip-solid.svg';
import briefcaseblank from '../../../../../Assets/Student/Icons/briefcase-blank-solid.svg';
import gears from '../../../../../Assets/Student/Icons/gears-solid.svg';
import palette from '../../../../../Assets/Student/Icons/palette-solid.svg';
import schoolflag from '../../../../../Assets/Student/Icons/school-flag-solid.svg';
import atomsimple from '../../../../../Assets/Student/Icons/atom-simple-solid.svg';
import handholdingheart from '../../../../../Assets/Student/Icons/hand-holding-heart-solid.svg';
import scalebalanced from '../../../../../Assets/Student/Icons/scale-balanced-solid.svg';
import archway from '../../../../../Assets/Student/Icons/archway-solid.svg';
import bedfront from '../../../../../Assets/Student/Icons/bed-front-solid.svg';
import earthamericas from '../../../../../Assets/Student/Icons/earth-americas-solid.svg';
import baseball from '../../../../../Assets/Student/Icons/baseball-solid.svg';
import plane from '../../../../../Assets/Student/Icons/plane-solid.svg';
import hatchef from '../../../../../Assets/Student/Icons/hat-chef-solid.svg';
import maskstheter from '../../../../../Assets/Student/Icons/masks-theater-solid.svg';
import language from '../../../../../Assets/Student/Icons/language-solid.svg';
import car from '../../../../../Assets/Student/Icons/car-solid.svg';
import badgecheck from '../../../../../Assets/Student/Icons/badge-check-solid.svg';
import personmilitaryrifle from '../../../../../Assets/Student/Icons/person-military-rifle-solid.svg';
import goalsRadioData from '../../../../../LandingPage/Home/goalsData.json';
import axios from 'axios';
import { CONTENT_HEADERS, CAREER_GOALS_URL, GET_CAREER_GOALS_URL } from '../../../../../Utils/StudentService';
import careersData from '../careerData.json';
import CareerGoalModal from '../../../../../Shared-Component/CareerGoal-Modal/careergoal-modal';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Spinner from '../../../../../Shared-Component/Spinner/Gif-Loader';

const goalsData = [
    {
        label: 'Healthcare and Medicine',
        icon: <img src={stethoscope} alt='stethoscope' className="icon-img" />,
    },
    {
        label: 'Technology and IT',
        icon: <img src={microchip} alt='microchip' className="icon-img" />,
    },
    {
        label: 'Business and Finance',
        icon: <img src={briefcaseblank} alt='briefcaseblank' className='icon-img' />
    },
    {
        label: 'Engineering',
        icon: <img src={gears} alt='gears' className='icon-img' />
    },
    {
        label: 'Arts and Creative Industries',
        icon: <img src={palette} alt="palette" className="icon-img" />,
    },
    {
        label: 'Education and Academia',
        icon: <img src={schoolflag} alt="schoolflag" className="icon-img" />,
    },
    {
        label: 'Science and Research',
        icon: <img src={atomsimple} alt="atomsimple" className="icon-img" />,
    },
    {
        label: 'Social Services',
        icon: <img src={handholdingheart} alt="handholdingheart" className="icon-img" />,
    },
    {
        label: 'Law and Legal Services',
        icon: <img src={scalebalanced} alt="scalebalanced" className="icon-img" />,
    },
    {
        label: 'Architecture and Design',
        icon: <img src={archway} alt="archway" className="icon-img" />,
    },
    {
        label: 'Hospitality and Tourism',
        icon: <img src={bedfront} alt="bedfront" className="icon-img" />,
    },
    {
        label: 'Agriculture and Environmental Sciences',
        icon: <img src={earthamericas} alt="earthamericas" className="icon-img" />,
    },
    {
        label: 'Physical and Sports Sciences',
        icon: <img src={baseball} alt="baseball" className="icon-img" />,
    },
    {
        label: 'Aviation and Aerospace',
        icon: <img src={plane} alt="plane" className="icon-img" />,
    },
    {
        label: 'Culinary Arts',
        icon: <img src={hatchef} alt="hatchef" className="icon-img" />,
    },
    {
        label: 'Entertainment and Performing Arts',
        icon: <img src={maskstheter} alt="hatchef" className="icon-img" />,
    },
    {
        label: 'Language and Linguistics',
        icon: <img src={language} alt="language" className="icon-img" />,
    },
    {
        label: 'Automotive and Mechanics',
        icon: <img src={car} alt="car" className="icon-img" />,
    },
    {
        label: 'Social Media and Digital Marketing',
        icon: <img src={badgecheck} alt="badgecheck" className="icon-img" />,
    },
    {
        label: 'Military',
        icon: <img src={personmilitaryrifle} alt="personmilitaryrifle" className="icon-img" />,
    },
]

const CareerGoalsForm = ({ onNextButtonClick, handleBackButtonClick, onCareerGoalUpdate, careerGoalsState }) => {
    const navigate = useNavigate();
    const [selectedGoals, setSelectedGoals] = useState([]);
    const [showAllGoals, setShowAllGoals] = useState(false);
    const [notDecided, setNotDecided] = useState(false);
    const [goalRadioValues, setGoalRadioValues] = useState(
        goalsData.reduce((empty, goal) => {
            empty[goal.label] = [];
            return empty;

        }, {})

    );

    const [careerGoalId, SetCareerGoalId] = useState([]);
    const [teacherNotes, setTeacherNotes] = useState("");
    const [teacherName, setTeacherName] = useState('');
    const [lastUpdatedOn, setLastUpdatedOn] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    useEffect(() => {
        fetchCareergoals();
    }, []);


    const fetchCareergoals = () => {
        const userId = localStorage.getItem('userId');
        axios
            .get(GET_CAREER_GOALS_URL + userId, { headers: CONTENT_HEADERS })
            .then((res) => {
                const responseData = res.data;
                const latestTeacherNotes = responseData?.teacherNotes?.versions?.[responseData?.teacherNotes?.versions?.length - 1]?.notes;
                const latestTeacherName = responseData?.teacherNotes?.versions?.[responseData?.teacherNotes?.versions?.length - 1]?.teacherName;
                const updatedOn = responseData?.updatedOn ? new Date(responseData?.updatedOn).toLocaleDateString() : null;
                const createdOn = responseData?.createdOn ? new Date(responseData?.createdOn).toLocaleDateString() : null;

                setTeacherNotes(latestTeacherNotes);
                const teacherName = latestTeacherName;
                setTeacherName(teacherName);
                const lastUpdatedOn = updatedOn || createdOn;
                setLastUpdatedOn(lastUpdatedOn);
                
                const { careerGoalsId, riasecResults, youScienceResults, careerGoalCategories, notDecided } = responseData;
                const careerGoalCategory = careerGoalCategories?.[careerGoalCategories?.length - 1];
                const latestNotDecidedCategory = careerGoalCategory?.notDecided === true;
                setNotDecided(latestNotDecidedCategory);
                
                SetCareerGoalId(careerGoalsId);
                if (riasecResults && riasecResults.length > 0) {
                    const latestRiasecResult = riasecResults[riasecResults.length - 1].result;
                    setRiasecResults(latestRiasecResult);
                } else {
                    console.log("No RIASEC results available.");
                }
                if (youScienceResults && youScienceResults.length > 0) {
                    const latestYouScience = youScienceResults[youScienceResults.length - 1].youScience;

                    const selectedAptitudes = {};
                    const coreDrivers = [];
                    let strongestTalent = '';

                    latestYouScience.forEach((result) => {
                        const { talentCategory, talentName, strongestTalent: isStrongestTalent } = result;
                        selectedAptitudes[talentCategory] = talentName;
                        coreDrivers.push(talentCategory);
                        if (isStrongestTalent) {
                            strongestTalent = talentCategory;
                        }
                    });

                    setCoreDriver(coreDrivers);
                    setSelectedAptitudes(selectedAptitudes);
                    setStrongestTalent(strongestTalent);
                } else {
                    console.log("No You Science results available.");
                }

                if (careerGoalCategories && careerGoalCategories?.length > 0) {
                    const latestCategory = careerGoalCategories[careerGoalCategories.length - 1];
                    const categories = latestCategory.categories;
                    const careerMessage = latestCategory.careerMessage;

                    setpersonalvalues(careerMessage);
                    setpersonalvalue(careerMessage);

                    const goalRadioValues = categories && categories.reduce((acc, category) => {
                        acc[category.categoryName] = category.goalPathway[0];
                        return acc;
                    }, {});
                    setGoalRadioValues(goalRadioValues);

                    const selectedGoals = categories && categories.map((category) => category.categoryName);
                    setSelectedGoals(selectedGoals);
                }  else if (notDecided) {
                    console.log("Checkbox checked.");
                } else {
                    console.log("No career goals available.");
                }
            })
            .catch((error) => {
                console.error("Error fetching career goals:", error);
            });
    };

    const handleCardClick = (goal) => {
        if (!selectedGoals || selectedGoals.length < 3) {
            setSelectedGoals(selectedGoals ? [...selectedGoals, goal] : [goal]);
            setNotDecided(false);
        } else {

            alert('You can select up to three goals.');

        }

    };
    const handleRemoveCard = (goal) => {

        const updatedSelection = selectedGoals.filter((item) => item !== goal);

        setSelectedGoals(updatedSelection);
        const updatedRadioValues = { ...goalRadioValues };
        delete updatedRadioValues[goal];
        setGoalRadioValues(updatedRadioValues);
        if (updatedSelection.length === 0) {

            // setNotDecided(true);

        }

    };

    const handleRadioChange = (goal, value) => {
        const updatedRadioValues = {
            ...goalRadioValues,
            [goal]: value,
        };

        setGoalRadioValues(updatedRadioValues);
    };

    const handleCheckboxChange = (event) => {
        setNotDecided(event.checked);
    };

    const renderGoalOptions = (goal) => {
        const goalData = goalsRadioData.find((item) => item.label === goal);
        const careerData = careersData.find((item) => item.bannerText === goal);

        return (
            <>
              <div key={`panel-${goal}`} className="goal-panel mb-5">
                <Card className='ml-4 mr-5'>
                    <div className="flex align-items-center justify-content-between">
                        <p>{goal}</p>
                        {careerData && (
                            <CareerGoalModal
                                // headerimage={careerData.headerimage}
                                headerimage={require(`../../../../../Assets/Images/Careers images/headerimages/${careerData.headerimage}`)}
                                bannerText={careerData.bannerText}
                                descriptionText={careerData.descriptionText}
                                salaryRange={careerData.salaryRange}
                                salaryDescription={careerData.salaryDescription}
                                salarySubText={careerData.salarySubText}
                                educationTitle={careerData.educationTitle}
                                educationDescription={careerData.educationDescription}
                                coursesSection={careerData.coursesSection}
                                careersSection={careerData.careersSection}

                                cardTitle={careerData.cardTitle}

                                cardLinks={careerData.cardLinks}
                                cardText={careerData.cardText}
                                bannerImage={require(`../../../../../Assets/Images/Careers images/BannerImages/${careerData.bannerImage}`)}
                                
                                header={careerData.header}
                               
                                educationImage={require(`../../../../../Assets/Images/Careers images/EducationImages/${careerData.educationImage}`)}
                                tabsData={careerData.tabsData}
                           

                            ></CareerGoalModal>

                        )}
                    </div>
                    {goalData && goalData.radioValues && (
                        <div className='flex flex-wrap gap-2 radio-btn'>
                            {goalData.radioValues.map((goalPathway) => (
                                <div key={goalPathway}>
                                    <RadioButton
                                        id={goalPathway}
                                        name={`radio-${goal}`}
                                        value={goalPathway}
                                        onChange={(e) => {
                                            handleRadioChange(goal, e.value);
                                        }}
                                        checked={goalRadioValues && goalRadioValues[goal] === goalPathway}
                                        className='mr-2'
                                    />
                                    <label className='radio-label-text' htmlFor={goalPathway}>{goalPathway}</label>

                                </div>
                            ))}
                        </div>
                    )}
                </Card>
            </div>
            </>
        );
    };

    const toggleShowAllGoals = () => {
        setShowAllGoals(!showAllGoals);
    };

    const [coreDriverSelected, setCoreDriverSelected] = useState('');
    const [coreDrivers, setCoreDriver] = useState([]);
    const [aptitudeSelected, setAptitudeSelected] = useState('');
    const [riasecResults, setRiasecResults] = useState([]);
    const [results, setResults] = useState([]);
    const [personalvalue, setpersonalvalue] = useState('');


    const handleSubmit = () => {
        setLoading(true);
        const careerGoalCategories = selectedGoals && selectedGoals.map((categoryName) => ({
            categoryName,
            goalPathway: Array.isArray(goalRadioValues?.[categoryName])
                ? goalRadioValues?.[categoryName]
                : [goalRadioValues?.[categoryName]],
        }));

        const updatedCareerGoalCategories = selectedGoals && selectedGoals.map((categoryName) => ({
            categoryName,
            goalPathway: Array.isArray(goalRadioValues?.[categoryName])
                ? goalRadioValues?.[categoryName]
                : [goalRadioValues?.[categoryName]],
        }));

        const youSciences = coreDriver.filter(driver => selectedAptitudes[driver] || strongestTalent === driver).map(driver => ({
            talentCategory: driver,
            talentName: selectedAptitudes[driver] || "",
            strongestTalent: strongestTalent === driver,
        }));

        const formData = {
            youSciences,
            careerGoalCategories,
            notDecided,
            riasecResults: results,
            careerMessage: personalvalue,
        };

        const updatedFormData = {
            youSciences,
            careerGoalCategories: updatedCareerGoalCategories,
            notDecided,
            riasecResults: results,
            careerMessage: personalvalue,
        };

        if (!careerGoalId) {
            axios
                .post(CAREER_GOALS_URL, formData, { headers: CONTENT_HEADERS })
                .then((response) => {
                    if (window.innerWidth > 1008) {
                        onCareerGoalUpdate(formData);
                        if ((tabState && buttonState) || (tabState === null && buttonState && !careerGoalsState)) {
                            onNextButtonClick();
                        }
                    }
                    else if (response.status === 200) {                   
                        navigate('/student/academicgoals');
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            } else {
                axios
                .put(CAREER_GOALS_URL, updatedFormData, { headers: CONTENT_HEADERS })
                .then((response) => {
                    setLoading(false);
                    if (window.innerWidth > 1008) {
                        onCareerGoalUpdate(updatedFormData);
                        if ((tabState && buttonState) || (tabState === null && buttonState && !careerGoalsState)) {
                            onNextButtonClick();
                        }
                    }
                    else if (response.status === 200) {                   
                        navigate('/student/academicgoals');
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                });
        }

        setIsDisabled(true);

    };

    const [tabState, setTabState] = useState(null);
    const [buttonState, setButtonState] = useState(false);

    useEffect(() => {
        if (!careerGoalsState && !tabState) {
            setButtonState(true);
        }

        if (careerGoalsState) {
            handleSubmit();
            setTabState(true);
        }
    }, [careerGoalsState]);

    const handleBackClick = () => {
        if (window.innerWidth > 1008) {
            handleBackButtonClick();
        }
        else {
            navigate('/student/personalStatement');
        }
    }




    const [personalvalues, setpersonalvalues] = useState('');

    const coreDriver = ['Visual Comparison Speed', 'Numerical Reasoning', 'Spatial Visualization', 'Sequential Reasoning', 'Idea Generation', 'Inductive Reasoning'];

    const options = {
        'Visual Comparison Speed': ["Visual Scanner", "List Checker", "Double Checker"],
        'Numerical Reasoning': ["Numerical Detective", "Numerical Predictor", "Numerical Checker"],
        'Spatial Visualization': ["3D Visualizer", "Space Planner", "Abstract Thinker"],
        'Sequential Reasoning': ["Sequential Thinker", "Collaborative Planner", "Process Supporter"],
        'Idea Generation': ["Brainstormer", "Idea Contributor", "Concentrated Focuser"],
        'Inductive Reasoning': ["Diagnostic Problem Solver", "Investigator", "Fact Checker"]
    };

    const [aptitudeOptions, setAptitudeOptions] = useState([]);

    const [strongestTalent, setStrongestTalent] = useState("");
    const [selectedAptitudes, setSelectedAptitudes] = useState({});

    const handleCoreDriverChange = (event) => {
        setCoreDriverSelected(event.value);
        setAptitudeSelected("");
    };

    const handleAptitudeChange = (driver, e) => {
        setSelectedAptitudes(prevState => ({
            ...prevState,
            [driver]: e.value
        }));
    };

    const handleStrongestTalentChange = (event) => {
        setStrongestTalent(event.value);
    }



    const RIASECResultsChange = (e) => {
        let _results = [...results];

        if (e.checked) {
            if (_results.length < 3) {
                _results.push(e.value);
            } else {
                e.checked = false;
            }
        } else {
            _results = _results.filter(result => result !== e.value);
        }

        setResults(_results);

    }

    useEffect(() => {
        setResults(riasecResults);
    }, [riasecResults]);

    const renderGoalButtons = () => {

        const goalsToShow = showAllGoals ? goalsData : goalsData.slice(0, 9);

        return (
            <div className="career-goals-section">
                <div className='desktop-career'>
                    <div className='m-5'>
                        <div className=' results-section mb-6 '>
                            <div className='mb-3 heading mt-7'>You Science Results</div>
                            <div className='mb-3 paragraph'>Please provide your results of You Science Exam. Learn more of how to do it  <a href="/student/help/personalStatement" style={{ color: "#1575E5", cursor: "pointer" }}>You Science</a></div>
                            <div className="flex flex-wrap  mb-3 gap-3 md:gap-4 xl:gap-8">
                                <div className="flex flex-column w-full md:w-4">
                                    <label className='mb-2 sub-heading'>Core Drivers</label>
                                    <Dropdown
                                        value={coreDriverSelected}
                                        options={coreDriver.map(driver => ({ label: driver, value: driver }))}
                                        onChange={handleCoreDriverChange}
                                        placeholder="Select your core driver"
                                    />
                                </div>
                                <div className="flex flex-column  w-full md:w-4 ">
                                    <label className='mb-2 sub-heading'>Aptitude</label>
                                    <Dropdown
                                        value={aptitudeSelected}
                                        options={aptitudeOptions.map(option => ({ label: option, value: option }))}
                                        onChange={handleAptitudeChange}
                                        placeholder="Select your aptitude"
                                        className="w-full"
                                    />

                                </div>
                            </div>
                        </div>
                        <div className='riasec-section mb-6'>
                            <div className='heading mb-3'>RIASEC Results</div>
                            <div className='paragraph mb-3'>Please provide your RIASEC result, you can select 3 maximum. Learn more of how to do it <a href="/student/help/personalStatement" style={{ color: "#1575E5", cursor: "pointer" }}> Riasec Results </a></div>
                            <div className="card flex flex-wrap  gap-8 mb-3">
                                <div className="flex align-items-center">
                                    <Checkbox inputId="results1" name="RIASEC Results" value="Realistic" onChange={RIASECResultsChange} checked={results.includes('Realistic')} />
                                    <label htmlFor="results1" className="ml-2 res">Realistic</label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox inputId="results2" name="RIASEC Results" value="Investigative" onChange={RIASECResultsChange} checked={results.includes('Investigative')} />
                                    <label htmlFor="results2" className="ml-2 res">Investigative</label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox inputId="results3" name="RIASEC Results" value="Artistic" onChange={RIASECResultsChange} checked={results.includes('Artistic')} />
                                    <label htmlFor="results3" className="ml-2 res">Artistic</label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox inputId="results4" name="RIASEC Results" value="Social" onChange={RIASECResultsChange} checked={results.includes('Social')} />
                                    <label htmlFor="results4" className="ml-2 res">Social</label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox inputId="results5" name="RIASEC Results" value="Enterprising" onChange={RIASECResultsChange} checked={results.includes('Enterprising')} />
                                    <label htmlFor="results5" className="ml-2 res">Enterprising</label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox inputId="results6" name="RIASEC Results" value="Conventional" onChange={RIASECResultsChange} checked={results.includes('Conventional')} />
                                    <label htmlFor="results6" className="ml-2 res">Conventional</label>
                                </div>
                            </div>
                        </div>
                        <div className='res-career-section'>
                            <div className='heading mb-3'>Career Selection</div>
                            <div className='paragraph mb-3'>Explore diverse career options thoughtfully. Identify passions and values. Choose a fulfilling career goal.</div>
                            <div className="check mb-3">
                                <Checkbox
                                    onChange={handleCheckboxChange}
                                    checked={notDecided}
                                    disabled={selectedGoals?.length > 0}
                                    className='mr-2 mb-1 align-items-center'
                                />
                                <label className='text-lg'>I have not decided my career path</label>
                            </div>
                        </div>
                    </div>
                    <div className='career-card-section w-auto flex-wrap'>
                        {goalsToShow.map((goal) => (
                            <div key={goal.label} className='flex justify-content-center place-items-center'>
                                <button
                                    className={`  ${selectedGoals && selectedGoals.includes(goal.label) ? 'selected' : 'unselected'}`}
                                    onClick={() => {

                                        if (selectedGoals && selectedGoals.includes(goal.label)) {
                                            handleRemoveCard(goal.label);
                                        } else {
                                            handleCardClick(goal.label);
                                        }
                                    }}
                                >
                                    {goal.label}
                                    {goal.icon}
                                </button>
                            </div>
                        ))}

                        {!showAllGoals && (
                            <div key="view-more" className='view'>
                                <button onClick={toggleShowAllGoals}>View More</button>
                            </div>
                        )}


                        {showAllGoals && (
                            <div className="view">
                                <button onClick={toggleShowAllGoals}>View Less</button>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        );
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const goalsToShow = showAllGoals ? goalsData : goalsData.slice(0, 9);

    const accordionItems = [
        {
            title: "You Science Results",
            content: (
                <div className="accordion-content">
                    <>
                        <div className='mb-3 paragraph'>Provide your results of You Science Exam one aptitude for each core drive, see the pie chart, and the largest piece represents your strongest ability please mark  your strongest talent provided on You Science Results. Learn more about how to do it  <a href="/student/help/personalStatement" style={{ color: "#1575E5", cursor: "pointer" }}>You Science</a></div>
                        <div className='flex  drops-sections'>
                            <div className='p-3 border-right-2 border-200 dropdown-section'>
                                <div className='mb-3 paragraph'><b>Your Talents</b></div>
                                <div className="w-full">
                                    {coreDriver.map((driver, index) => (
                                        <div className="flex mb-3 driver-sec" key={index}>
                                            <label className='flex justify-content-start align-items-center' style={{ width: "60%" }}>{driver}</label>
                                            <Dropdown
                                                value={selectedAptitudes[driver] || ""}
                                                options={options[driver]?.map(option => ({ label: option, value: option })) || []}
                                                onChange={(e) => handleAptitudeChange(driver, e)}
                                                placeholder={`Select an aptitude`}
                                                className="w-full"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='p-3 radio-section'>
                                <div className='mb-3 paragraph flex align-items-center justify-content-start'><b>My Strongest Talent</b></div>
                                <div className="flex flex-column gap-1 ml-8">
                                    {coreDriver.map((driver, index) => (
                                        <div key={index} className="flex align-items-center radio-secs" style={{ margin: "0.75rem" }}>
                                            <RadioButton
                                                inputId={driver}
                                                name="strongestTalent"
                                                value={driver}
                                                onChange={handleStrongestTalentChange}
                                                checked={strongestTalent === driver}
                                                disabled={!selectedAptitudes[driver]}
                                            />
                                            <div>
                                                {windowWidth > 900 ? (
                                                    <label htmlFor={driver} className="ml-2"></label>
                                                ) : (
                                                    <>
                                                        <label htmlFor={driver} className="ml-2">{driver}</label>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>


                    </>
                </div>
            )
        },
        {
            title: "RIASEC Results",
            content: (
                <div className="accordion-content">
                    <>
                        <div className='paragraph mb-3'>Please provide your RIASEC result, you can select 3 maximum. Learn more of how to do it <a href="/student/help/personalStatement" style={{ color: "#1575E5", cursor: "pointer" }}> Riasec Results </a></div>
                        <div className="card flex flex-wrap  gap-6 mb-3">
                            <div className="flex align-items-center">
                                <Checkbox inputId="results1" name="RIASEC Results" value="Realistic" onChange={RIASECResultsChange} checked={results.includes('Realistic')} />
                                <label htmlFor="results1" className="ml-2 res">Realistic</label>
                            </div>
                            <div className="flex align-items-center">
                                <Checkbox inputId="results2" name="RIASEC Results" value="Investigative" onChange={RIASECResultsChange} checked={results.includes('Investigative')} />
                                <label htmlFor="results2" className="ml-2 res">Investigative</label>
                            </div>
                            <div className="flex align-items-center">
                                <Checkbox inputId="results3" name="RIASEC Results" value="Artistic" onChange={RIASECResultsChange} checked={results.includes('Artistic')} />
                                <label htmlFor="results3" className="ml-2 res">Artistic</label>
                            </div>
                            <div className="flex align-items-center">
                                <Checkbox inputId="results4" name="RIASEC Results" value="Social" onChange={RIASECResultsChange} checked={results.includes('Social')} />
                                <label htmlFor="results4" className="ml-2 res">Social</label>
                            </div>
                            <div className="flex align-items-center">
                                <Checkbox inputId="results5" name="RIASEC Results" value="Enterprising" onChange={RIASECResultsChange} checked={results.includes('Enterprising')} />
                                <label htmlFor="results5" className="ml-2 res">Enterprising</label>
                            </div>
                            <div className="flex align-items-center">
                                <Checkbox inputId="results6" name="RIASEC Results" value="Conventional" onChange={RIASECResultsChange} checked={results.includes('Conventional')} />
                                <label htmlFor="results6" className="ml-2 res">Conventional</label>
                            </div>
                        </div>
                    </>
                </div>
            )
        },
        {
            title: "Career Selection",
            content: (
                <div className="accordion-content">
                    <>
                        <div className='paragraph mb-3'>Explore diverse career options thoughtfully. Identify passions and values. Choose a fulfilling career goal.</div>
                        <div className="check mb-3">
                            <Checkbox
                                onChange={handleCheckboxChange}
                                checked={notDecided}
                                disabled={selectedGoals?.length > 0}
                                className='mr-2 mb-1 align-items-center'
                            />
                            <label className='text-lg'>I have not decided my career path</label>
                        </div>

                        <div className='career-card-section w-auto flex-wrap'>
                            {goalsToShow.map((goal) => (
                                <div key={goal.label} className='flex justify-content-center place-items-center'>
                                    <button
                                        className={`  ${selectedGoals && selectedGoals.includes(goal.label) ? 'selected' : 'unselected'}`}
                                        onClick={() => {

                                            if (selectedGoals && selectedGoals.includes(goal.label)) {
                                                handleRemoveCard(goal.label);
                                            } else {
                                                handleCardClick(goal.label);
                                            }
                                        }}
                                    >
                                        {goal.label}
                                        {goal.icon}
                                    </button>
                                </div>
                            ))}

                            {!showAllGoals && (
                                <div key="view-more" className='view'>
                                    <button onClick={toggleShowAllGoals}>View More</button>
                                </div>
                            )}


                            {showAllGoals && (
                                <div className="view">
                                    <button onClick={toggleShowAllGoals}>View Less</button>
                                </div>
                            )}
                        </div>

                        <div className="goals-container justify-content-center ">
                            <div className="flex-auto justify-content-center">
                                <p className='ml-4 align-text text-lg'></p>
                                {renderGoalButtons()}
                            </div>
                        </div>
                        <div className='ml-3 text-box gap-2 align-text'>
                        </div>
                        {selectedGoals && selectedGoals.map((goal) => renderGoalOptions(goal))}
                        <div className='m-5'>
                            <div className='career-detail-sec'>
                                <div className='heading'>Why did you choose that career?</div>
                                <div className='paragraph'>Example: I want to pursue a career as an Elementary/Secondary School Education Administrator because I’m interested in work that’s practical, hands-on, and allows me to interact with and help others. I also enjoy working with children.</div>
                                <div className="">
                                    <InputTextarea className="flex align-items-center justify-content-center w-full h-8rem mt-3" keyfilter={/^[^<>*!]+$/} placeholder="Please describe the reason for your career selection" value={personalvalue} onChange={(e) => setpersonalvalue(e.target.value)} rows={5} cols={30} />
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            )
        },
        {
            title: "Teacher Notes",
            content: (
                <div className="accordion-content">
                    {teacherNotes ? (
                        <div className='teacher-notes-section m-5'>
                            <p><b>{lastUpdatedOn} - </b>{teacherNotes} - ({teacherName})</p>
                        </div>
                    ) : (
                        <div className='teacher-notes-section m-5'>
                            <p>There are no teacher notes.</p>
                        </div>
                    )}
                </div>
            )
        }
    ];

    return (
        <div className='careergoals-section'>
            <div className='desktop-career'>
                <div className="goals-container justify-content-center ">
                    <div className="flex-auto justify-content-center">
                        <p className='ml-4 align-text text-lg'></p>
                        {renderGoalButtons()}
                    </div>
                </div>
                {selectedGoals && selectedGoals.map((goal) => renderGoalOptions(goal))}
                {/* <div className="grid ml-3 mt-4 align-text career-marks">
                    <div className='col-3 m-2 text-box gap-2'>
                        <label className='text-lg'>YourScience Aptitude Result:</label>
                        <InputText
                            placeholder='Enter here'
                            value={youScienceAptitudeResult}
                            onChange={(e) => setScienceResult(e.target.value)}
                        />
                    </div>
                    <div className='col-3 m-2 text-box gap-2'>
                        <label className='text-lg'>RIASEC Interest Code:</label>
                        <InputText
                            placeholder='Enter here'
                            value={riasecInterestCode}
                            onChange={(e) => setInterestCode(e.target.value)}
                        />
                    </div>
                </div> */}
                <div className='m-5'>
                    <div className='career-detail-sec'>
                        <div className='heading'>Why did you choose that career?</div>
                        <div className='paragraph'>Example: I want to pursue a career as an Elementary/Secondary School Education Administrator because I’m interested in work that’s practical, hands-on, and allows me to interact with and help others. I also enjoy working with children.</div>
                        <div className="">
                            <InputTextarea className="flex align-items-center justify-content-center w-full h-8rem mt-3" keyfilter={/^[^<>*!]+$/} placeholder="Please describe the reason for your career selection" value={personalvalue} onChange={(e) => setpersonalvalue(e.target.value)} rows={5} cols={30} />
                        </div>
                    </div>
                </div>

                {teacherNotes && (
                    <div className='teacher-notes-section m-5'>
                        <h3 className='head'>Teacher Notes</h3>
                        <p><b>{lastUpdatedOn} - </b>{teacherNotes} - ({teacherName})</p>
                    </div>
                )}
            </div>

            <div className='mobile-career m-5'>
                <Accordion activeIndex={activeIndex} onTabChange={(e) => toggleAccordion(e.index)}>
                    {accordionItems.map((item, index) => (
                         item.title === "Teacher Notes" && !teacherNotes ? null : (
                        <AccordionTab key={index} header={
                            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
                                <div className="header-text">{item.title}</div>
                                <div className="header-icon">
                                    <i className={`pi ${activeIndex === index ? "pi-minus" : "pi-plus"}`}></i>
                                </div>
                            </div>
                        }>
                            <div className="accordion-content">
                                {item.content}
                            </div>
                        </AccordionTab>
                         )
                    ))}
                </Accordion>
            </div>

            <div className="spinner-section">
                {loading ? <Spinner /> : null}
            </div>
            <footer className="footer-section m-5">
                <Button label="Back" severity="secondary" onClick={handleBackClick} />
                <Button
                    label="Save and Continue"
                    onClick={handleSubmit}
                    disabled={isDisabled} 
                />
            </footer>
        </div>
    );
};

export default CareerGoalsForm;



