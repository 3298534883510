import React, { useState, useEffect, useRef } from 'react';
import NavBar from '../../../Shared-Component/StackNavBar/StackNavBar';
import { useNavigate } from 'react-router-dom';
import '../Dashboard/Dashboard.scss'
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import Illustration from '../../../Assets/Loginimage.svg';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import { CONTENT_HEADERS, GET_STUDENT_URL, CREATE_EXCEL_URL, CREATE_EXCEL_URL_HEADERS, GET_GROUP_URL, ASSIGN_GROUP_URL, SEND_EMAIL_URL, SEND_BULK_EMAIL_URL, GET_TOTAL_NUMBER_OF_USERS_URL, DELETE_USER_URL } from '../../../Utils/AdminService';
import { Dialog } from 'primereact/dialog';
import CustomFilter from "../../../Shared-Component/ViewLists/CustomFilter/CustomFilter";
import { MultiSelect } from 'primereact/multiselect';
import { faEnvelope, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayPanel } from 'primereact/overlaypanel';
import Spinner from '../../../Shared-Component/Spinner/Gif-Loader';
import FilterChips from '../Principals/FilterChips';
import { Toast } from 'primereact/toast';

function Students() {
    const userRole = "Admin";
    const navigate = useNavigate();

    const [activeMenuItem, setActiveMenuItem] = useState("students");

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [isUploadEnabled, setIsUploadEnabled] = useState(false);

    const [selectedRows, setSelectedRows] = useState(null);

    const [selectDialog, setSelectedDialog] = useState(false);

    const [formData, setFormData] = useState({
        group: '',
        teacher: ''
    });
    const [filteredLists, setFilteredLists] = useState([]);
    const [checkedItems, setCheckedItems] = useState({});
    const [groupCheckboxes, setGroupCheckboxes] = useState([]);
    const [gradeCheckboxes, setGradeCheckboxes] = useState([]);

    const [isSendInvitationEnabled, setIsSendInvitationEnabled] = useState(false);

    const [schoolDetails, setSchoolDetails] = useState();
    const [name, setName] = useState();

    const [openDialogBox, setOpenDialog] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);

    const [showBulkInvitationDialog, setShowBulkInvitationDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [selectedRowIds, setSelectedRowIds] = useState([]);

    const [loading, setLoading] = useState(false);
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });

    const toast = useRef(null);

    useEffect(() => {
        const schoolId = localStorage.getItem('schoolId');
        fetchStudents("Student", schoolId);
        fetchGroups();
    }, []);

    useEffect(() => {
        fetchDetails();
    }, []);

    useEffect(() => {
        setFilteredLists(fileData);

        const uniqueGroups = [...new Set(fileData.flatMap(item => item.groups.map(group => group.groupName)))];
        const checkboxes = uniqueGroups.map(group => ({
            label: group,
            value: group,
        }));
        setGroupCheckboxes(checkboxes);
        const uniqueGrades = [...new Set(fileData.flatMap(item => item.groups.map(group => group.grade)))];
        const gradeCheckboxes = uniqueGrades.map(grade => ({
            label: grade,
            value: grade,
        }));
        setGradeCheckboxes(gradeCheckboxes);
    }, [fileData]);

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case "dashboard":
                navigate("/admin/dashboard");
                break;
            case "principals":
                navigate("/admin/principals");
                break;
            case "teachers":
                navigate("/admin/teachers");
                break;
            case "groups":
                navigate("/admin/groups");
                break;
            case "students":
                navigate("/admin/students");
                break;
            default:
                break;
        }
    };

    const fetchDetails = () => {
        const schoolId = localStorage.getItem('schoolId');

        axios
            .get(GET_TOTAL_NUMBER_OF_USERS_URL + schoolId, { headers: CONTENT_HEADERS })
            .then((response) => {
                localStorage.setItem('schoolId', response.data.schools.schoolId);
                setSchoolDetails(response.data);
                setName(`${response.data.userDetails.firstName} ${response.data.userDetails.lastName}`);
            })
            .catch((error) => {
                console.log(error);
                if (toast.current) {
                    toast.current.clear();
                    toast.current.show([{ sticky: true, severity: 'error', summary: 'Error', detail: error.response.data.message }]);
                }
            });
    }

    const handleFileUpload = (event) => {
        const file = event.files[0];
        setSelectedFile(file);
        setIsUploadEnabled(true);
    };

    const handleUpload = () => {
        if (!selectedFile) {
            console.error("No file selected");
            setFileData([]);
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile);

        const schoolId = localStorage.getItem('schoolId');

        axios
            .post(CREATE_EXCEL_URL, formData, { headers: CREATE_EXCEL_URL_HEADERS })
            .then((response) => {
                fetchStudents("Student", schoolId);
                // setFileData(response.data);
            })
            .catch((error) => {
                console.log(error);
                if (toast.current) {
                    toast.current.clear();
                    toast.current.show([{ life: 10000, severity: 'error', summary: 'Error', detail: error.response.data.message }]);
                }
            });
    };

    const handleAddNewClick = () => {
        navigate("/admin/students/create");
    };

    const fetchStudents = (groupName, schoolId) => {
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });


        axios
            .get(`${GET_STUDENT_URL}?groupName=${groupName}&schoolId=${schoolId}`)
            .then((response) => {
                setFileData(response.data);
                setLoading(false);
                setOpacityStyle({ opacity: 1, background: 'transparent' });
                setDatass(response.data);
            })
            .catch((error) => {
                setLoading(true);
                console.error("API Error:", error);
                if (toast.current) {
                    toast.current.clear();
                    toast.current.show([{ sticky: true, severity: 'error', summary: 'Error', detail: error.response.data.message }]);
                }
            });
    };

    const [groupOptions, setGroupOptions] = useState([]);


    const fetchGroups = () => {
        axios
            .get(GET_GROUP_URL, { headers: CONTENT_HEADERS })
            .then((response) => {
                setGroupOptions(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (event.target.name === "group") {
            const selectedGroupIds = groupOptions
                .filter((group) => event.value.includes(group.groupName))
                .map((group) => group.groupId);

            const assignedTeachers = groupOptions
                .filter((group) => selectedGroupIds.includes(group.groupId))
                .flatMap((group) => group.assignedTeacher || [])
                .join(', ');

            setFormData((prevData) => ({
                ...prevData,
                teacher: assignedTeachers,
                groupIds: selectedGroupIds,
            }));
        }
    };

    const actionBodyTemplate = (rowData) => {
        const isEditDisabled = rowData.status === "Completed";

        return (
            <React.Fragment>
                <Button
                    rounded
                    outlined
                    severity="danger"
                    className="action-button"
                    onClick={() => handleInvitation(rowData)}
                    disabled={isEditDisabled}
                    style={{ display: isEditDisabled ? "none" : "inline-block" }}
                >
                    <FontAwesomeIcon icon={faEnvelope} />
                </Button>

                <Button
                    rounded
                    outlined
                    onClick={() => handleEditClick(rowData)}
                    className="action-button"
                >
                    <FontAwesomeIcon icon={faPenToSquare} />
                </Button>

                <Button
                    rounded
                    outlined
                    onClick={() => handleDeleteClick(rowData)}
                    className="action-button"
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
            </React.Fragment>
        );
    };

    const handleInvitation = (selectedRowId) => {
        setSelectedRowId(selectedRowId.userId);
        setOpenDialog(true);
        setSelectedUser(selectedRowId.firstName)
    }

    const hideOpenDialog = () => {
        setOpenDialog(false);
    }

    const handleEditClick = (rowData) => {
        navigate('/admin/students/create', { state: { rowData } });
    };

    const handleDeleteClick = (rowData) => {
        setSelectedRowId(rowData.userId);
        setShowDeleteDialog(true);
    }

    const handleDeleteAction = () => {

        axios
            .delete(DELETE_USER_URL + selectedRowId, { headers: CONTENT_HEADERS })
            .then((response) => {
                setShowDeleteDialog(false);
                window.location.reload(true);
            }).catch((error) => {
                console.log("API Error:", error)
                if (toast.current) {
                    toast.current.clear();
                    toast.current.show([{ sticky: true, severity: 'error', summary: 'Error', detail: error.response.data.message }]);
                }
            })
    }

    const openDialog = () => {
        setSelectedDialog(true);
        document.body.style.overflow = 'hidden';
        const hasAssignedGroup = selectedRows.some((rowData) => rowData.groups && rowData.groups.length > 0);

        if (hasAssignedGroup) {
            const assignedGroupNames = selectedRows
                .filter((rowData) => rowData.groups && rowData.groups.length > 0)
                .flatMap((rowData) => rowData.groups.map((group) => group.groupName));

            setFormData((prevData) => ({
                ...prevData,
                group: assignedGroupNames,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                group: [],
            }));
        }
    };

    const handleAssignedGroup = (rowData) => {
        if (!selectedRows || !selectedRows.length) {
            console.log("No rows selected");
            return;
        }

        const userIds = selectedRows.map((rowData) => rowData.userId);

        const apiPayloads = {
            userId: userIds,
            groupId: formData.groupIds,
        };

        axios
            .put(ASSIGN_GROUP_URL, apiPayloads, { headers: CONTENT_HEADERS })
            .then(() => {
                setSelectedDialog(false);
                window.location.reload(true);
            })
            .catch((error) => {
                console.log(error);
                if (toast.current) {
                    toast.current.clear();
                    toast.current.show([{ sticky: true, severity: 'error', summary: 'Error', detail: error.response.data.message }]);
                }
            });
    };

    const hideDialog = () => {
        // setSubmitted(false);
        setSelectedDialog(false);
        document.body.style.overflow = '';
    };

    const productDialogFooter = (
        <React.Fragment>
            <Button label="Save and Close" outlined onClick={handleAssignedGroup} />
        </React.Fragment>
    );

    const [selectedField, setSelectedField] = useState([]);
    const [selectedGradeFilter, setSelectedGradeFilter] = useState([]);
    const [selectedGroupFilter, setSelectedGroupFilter] = useState([]);
    const [selectedStatusFilter, setSelectedStatusFilter] = useState([]);
    const [isEmptyFilterResult, setIsEmptyFilterResult] = useState(false);

    const applyFilter = (field) => {
        const selectedFieldValues = Object.keys(checkedItems).filter(
            (key) => checkedItems[key]
        );

        setSelectedField(selectedFieldValues);

        const selectedGroups = selectedGroupFilter.filter(
            (key) => checkedItems[key]
        );
        const selectedStatus = selectedStatusFilter.filter(
            (key) => checkedItems[key]
        );
        const selectedGrade = selectedGradeFilter.filter(
            (key) => checkedItems[key]
        );

        if (selectedFieldValues.length === 0 &&
            selectedGroups.length === 0 &&
            selectedStatus.length === 0 &&
            selectedGrade.length === 0) {
            setFilteredLists(fileData);
            const overlayElement = document.querySelector(".p-column-filter-overlay");
            if (overlayElement) {
                overlayElement.style.display = "none";
            }
            return;
        }

        const updatedFilteredLists = fileData.filter((item) => {
            const groupsFilter = selectedGroups.length === 0 || selectedGroups.some((group) =>
                item.groups.some((groupObj) => groupObj.groupName === group)
            );

            const gradeFilter = selectedGrade.length === 0 || selectedGrade.some((grade) =>
                item.groups.some((groupObj) => groupObj.grade === grade)
            );

            // const statusFilter = selectedStatus.length === 0 || selectedStatus.some((status) =>
            //     item.status.includes(status)
            // );
            const statusFilter = selectedStatus.length === 0 || (item.status && selectedStatus.some((status) =>
                item.status.includes(status)
            ));

            return groupsFilter && gradeFilter && statusFilter;
        });

        if (updatedFilteredLists.length === 0) {
            setFilteredLists(fileData);
            setIsEmptyFilterResult(true);
            alert("No results found. Please adjust your filters.");
            window.location.reload();
        } else {
            setIsEmptyFilterResult(false);
        }

        setFilteredLists(updatedFilteredLists);

        const overlayElement = document.querySelector(".p-column-filter-overlay");
        if (overlayElement) {
            overlayElement.style.display = "none";
        }
    };

    const [selectedFilters, setSelectedFilters] = useState([]);

    const handleCheckboxChange = (item, filterCategory) => {
        const updatedCheckedItems = {
            ...checkedItems,
            [item]: !checkedItems[item],
        };

        setCheckedItems(updatedCheckedItems);

        if (filterCategory === "groups.groupName") {
            setSelectedGroupFilter([...selectedGroupFilter, item]);
        } else if (filterCategory === "groups.grade") {
            setSelectedGradeFilter([...selectedGradeFilter, item]);
        } else if (filterCategory === "status") {
            setSelectedStatusFilter([...selectedStatusFilter, item]);
        }

        const selectedFieldValues = Object.keys(updatedCheckedItems).filter(
            (key) => updatedCheckedItems[key]
        );

        setSelectedFilters(selectedFieldValues);
    };

    const handleClearAllFilters = () => {

        setCheckedItems({});
        setSelectedField([])

        setSelectedFilters([]);

        setDatass(fileData);
        setFilteredLists(fileData);

        const overlayElement = document.querySelector('.p-column-filter-overlay');
        if (overlayElement) {
            overlayElement.style.display = 'none';
        }

    };

    const handleSendInvitation = () => {
        axios
            .get(SEND_EMAIL_URL + selectedRowId, { headers: CONTENT_HEADERS })
            .then((res) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log("API Error:", error)
                if (toast.current) {
                    toast.current.clear();
                    toast.current.show([{ sticky: true, severity: 'error', summary: 'Error', detail: error.response.data.message }]);
                }
            })
    }

    const showDialog = () => {
        setShowBulkInvitationDialog(false);
        setShowDeleteDialog(false);
    }

    const handleBulkSendInvitations = (selectedRowIds) => {
        setShowBulkInvitationDialog(true);
        setSelectedRowIds(selectedRowIds);
    }

    const openDialogFooter = (
        <React.Fragment>
            <Button label="No" severity="secondary" icon="pi pi-times" outlined onClick={hideOpenDialog} />
            <Button label="Send" icon="pi pi-check" severity="danger" onClick={handleSendInvitation} />
        </React.Fragment>
    );


    const handleBulkSendInvitation = () => {
        const hasCompletedStatus = selectedRowIds.some((userId) => {
            const user = filteredLists.find((item) => item.userId === userId);
            return user && user.status === "Completed";
        });

        if (hasCompletedStatus) {
            alert("You cannot send invitations to users with 'Completed' status.");
            return;
        }

        const usersToSendInvitation = selectedRowIds.filter((userId) => {
            const user = filteredLists.find((item) => item.userId === userId);
            return user && user.status !== "Completed";
        });

        if (usersToSendInvitation.length === 0) {
            alert("Please select users with 'Uninvited' or 'Pending' status.");
            return;
        }

        axios
            .post(SEND_BULK_EMAIL_URL, selectedRowIds, { headers: CONTENT_HEADERS })
            .then((res) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log("API Error:", error)
                if (toast.current) {
                    toast.current.clear();
                    toast.current.show([{ sticky: true, severity: 'error', summary: 'Error', detail: error.response.data.message }]);
                }
            })
    }

    const bulkInvitationDialogFooter = (
        <React.Fragment>
            <Button label="No" severity="secondary" icon="pi pi-times" outlined onClick={showDialog} />
            <Button label="Send" icon="pi pi-check" severity="danger"
                onClick={() => handleBulkSendInvitation()}
            />
        </React.Fragment>
    );

    const deleteDialogFooter = (
        <React.Fragment>
            <Button label="No" severity="secondary" icon="pi pi-times" outlined onClick={showDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger"
                onClick={handleDeleteAction}
            />
        </React.Fragment>
    );

    const [showHeader, setShowHeader] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');

    const [datass, setDatass] = useState([]);

    const [displayedGroups, setDisplayedGroups] = useState([]);

    const overlayPanelRef = useRef(null);

    const [overlayPanelVisible, setOverlayPanelVisible] = useState(false);

    const showListofGroups = (event, rowData) => {
        const groupNames = rowData.groups.map(group => group.groupName);

        setDisplayedGroups(groupNames);
        overlayPanelRef.current.toggle(event);
        setOverlayPanelVisible(true);
        document.body.style.overflow = 'hidden';
    };

    const hideOverlayPanel = () => {
        overlayPanelRef.current.hide();
        setOverlayPanelVisible(false);
        document.body.style.overflow = '';
    };

    return (
        <div className='admin-student-container'>
            <div className="spinner-section">
                {loading ? <Spinner /> : null}
            </div>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
                name={name}
            />
            <div className="toast-overlay">
                <Toast ref={toast} className="toast" />
            </div>
            <div className="flex justify-content-between align-items-center mx-6 admin-section">
                <h2>Students</h2>

                <span className="p-input-icon-right">
                    <div className="flex align-items-center justify-content-end">
                        <InputText placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                        <i className="pi pi-search" style={{ position: "absolute", marginRight: "20px" }} />
                    </div>
                </span>
            </div>
            <Card className="student-page-card mx-6 my-4">
                <div className="student-page">
                    <div className="student-page-head  w-full  p-0 m-0">
                        <div className="student-page-left flex justify-content-center mx-6">
                            <InputText
                                type="text"
                                value={selectedFile ? selectedFile.name : ""}
                                readOnly
                                placeholder="Browse a file"
                                className="student-input -mr-3"
                            />
                            <FileUpload
                                chooseLabel="Select File"
                                uploadLabel="Upload"
                                value={""}
                                onSelect={handleFileUpload}
                                mode="basic"
                                accept=".xlsx"
                            />
                            <div className="ml-3">
                                <Button
                                    onClick={handleUpload}
                                    disabled={!isUploadEnabled}
                                    className="gap-2"
                                >
                                    <i className="pi pi-upload"></i>
                                    Upload File
                                </Button>
                            </div>
                        </div>
                        <div className="student-page-right flex justify-content-center mx-6 gap-3">
                            <Button onClick={handleAddNewClick}>+ Add New</Button>
                        </div>
                    </div>
                    <hr className="my-4" />
                </div>

                <div className="p-d-flex p-flex-wrap mx-6">
                    {filteredLists.length > 0 ? (
                        <div className="card m-0 p-0 table-section">
                            {selectedField.length > 0 ?
                                (
                                    <div className='pb-3'>
                                        <FilterChips
                                            selectedFilters={selectedField}

                                            onRemoveFilter={(removedFilter) => {
                                                setCheckedItems((prevCheckedItems) => {
                                                    const updatedCheckedItems = { ...prevCheckedItems };
                                                    delete updatedCheckedItems[removedFilter];

                                                    return updatedCheckedItems;
                                                });
                                            }}

                                            handleClearAllFilters={handleClearAllFilters}
                                        />
                                    </div>
                                ) : ''
                            }
                            <Card className="flex flex-column justify-content-center items-center ">
                                <DataTable
                                    removableSort
                                    selection={selectedRows}
                                    onSelectionChange={(e) => {
                                        setSelectedRows(e.value);
                                        setSelectedRowIds(e.value.map(row => row.userId))
                                        if (e.value.length > 0) {
                                            setIsSendInvitationEnabled(true);
                                            setShowHeader(true);
                                        } else {
                                            setIsSendInvitationEnabled(false);
                                            setShowHeader(false);
                                        }
                                    }}
                                    value={datass.filter((list) =>
                                        (list.firstName?.toLowerCase().includes(searchQuery?.toLowerCase() || '')) ||
                                        (list.emailId?.toLowerCase().includes(searchQuery?.toLowerCase() || '')) ||
                                        (list.registrationId?.toLowerCase().includes(searchQuery?.toLowerCase() || ''))
                                    )
                                        .filter((list) => filteredLists.includes(list))}
                                    selectionMode="checkbox"
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    tableStyle={{ minWidth: "50rem" }}
                                    columnResizeMode="expand"
                                    resizableColumns
                                    showGridlines
                                    className={
                                        `${showHeader ? 'hide-header' : 'show-header'}`
                                    }
                                >
                                    <Column
                                        selectionMode="multiple"
                                        headerStyle={{ width: "3rem" }}
                                    ></Column>
                                    <Column
                                        field="registrationId"
                                        header={showHeader ? (
                                            <div className='flex align-items-center justify-content-center px-3'>
                                                {showHeader && (
                                                    <Button
                                                        className='gap-2 flex align-items-center cursor-pointer'
                                                        onClick={() => handleBulkSendInvitations(selectedRowIds)}
                                                        disabled={!isSendInvitationEnabled}
                                                    >
                                                        <i className="pi pi-envelope"></i> Send Invitation
                                                    </Button>
                                                )}
                                            </div>
                                        ) : "School ID"}
                                    />
                                    <Column field="firstName"
                                        sortable={!showHeader}
                                        header={showHeader ? (
                                            <div className='flex align-items-center justify-content-center px-1'>
                                                {showHeader && (
                                                    <></>
                                                )}
                                            </div>
                                        ) : "Name"}
                                        body={rowData => `${rowData.firstName} ${rowData.lastName}`}
                                    />
                                    <Column field="emailId"
                                        header={showHeader ? (
                                            <div className='flex align-items-center justify-content-center px-0 mx-0  w-full'>
                                                {showHeader && (
                                                    <Button
                                                        className='gap-2 flex align-items-center cursor-pointer'
                                                        onClick={openDialog}
                                                        disabled={!selectedRows || !selectedRows.length}
                                                    >
                                                        <i className="pi pi-users"></i> Assign Group
                                                    </Button>
                                                )}
                                            </div>
                                        ) : "Email"}
                                        />
                                    <Column field="phoneNumber" header="Phone Number" />
                                    {/* <Column field="schools.schoolName" header="School" /> */}
                                    <Column
                                        field="groups.grade"
                                        header="Grade"
                                        filter
                                        filterClear
                                        filterApply={
                                            <CustomFilter
                                                checkboxes={gradeCheckboxes}
                                                checkedItems={checkedItems}
                                                onCheckboxChange={(item) => handleCheckboxChange(item, "groups.grade")}
                                                onApplyFilter={() => applyFilter("groups.grade")}
                                            />
                                        }
                                        body={
                                            (rowData) => {
                                                const grade = rowData.groups.map(group => group.grade);
                                                const displayGrade = grade.length > 1 ? grade.slice(0, 1).join(", ") + "" : grade.length === 1 ? grade[0] : "-";
                                                return displayGrade;
                                            }
                                        }
                                    />
                                    {/* <Column field="userType" header="Role" />	 */}
                                    <Column
                                        field="groups.groupName"
                                        header="Group"
                                        filter
                                        filterClear
                                        filterApply={
                                            <CustomFilter
                                                checkboxes={groupCheckboxes}
                                                checkedItems={checkedItems}
                                                onCheckboxChange={(item) => handleCheckboxChange(item, "groups.groupName")}
                                                onApplyFilter={() => applyFilter("groups.groupName")}
                                            />
                                        }
                                        body={
                                            (rowData) => {
                                                const groupNames = rowData.groups.map(group => group.groupName);
                                                const displayGroupNames = groupNames.length > 1 ? groupNames.slice(0, 1).join(", ") + "..." : groupNames.join(", ");

                                                return <span
                                                    onClick={(e) => showListofGroups(e, rowData)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {displayGroupNames}
                                                </span>;
                                            }
                                        }
                                    />
                                    <Column
                                        field="status"
                                        header="Status"
                                        filter
                                        filterClear
                                        filterApply={
                                            <CustomFilter
                                                checkboxes={[
                                                    { label: "Uninvited", value: "Uninvited" },
                                                    { label: "Pending", value: "Pending" },
                                                    { label: "Completed", value: "Completed" },
                                                ]}
                                                checkedItems={checkedItems}
                                                onCheckboxChange={(item) => handleCheckboxChange(item, "status")}
                                                onApplyFilter={() => applyFilter("status")}
                                            />
                                        }
                                    />
                                    <Column
                                        header="Actions"
                                        body={actionBodyTemplate}
                                        exportable={false}
                                    ></Column>
                                </DataTable>
                                <OverlayPanel
                                    ref={overlayPanelRef}
                                    appendTo={document.body}
                                    onHide={hideOverlayPanel}
                                >
                                    <ul className="profile-menu-list">
                                        {displayedGroups.map((group, index) => (
                                            <li key={index}>{group}</li>
                                        ))}
                                    </ul>
                                </OverlayPanel>
                            </Card>
                        </div>
                    ) : (
                        <Card className="flex flex-column justify-content-center items-center mt-2 m-4">
                            <div className="flex flex-column justify-content-center align-items-center">
                                <Image src={Illustration} alt="Image" width="350px" />
                                <p className="m-5">
                                    To see information here, please start adding a new student
                                </p>
                            </div>
                        </Card>
                    )}
                </div>
            </Card>

            <Dialog
                visible={selectDialog}
                style={{ width: "32rem", borderRadius: "12px" }}
                breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                modal
                className="p-fluid"
                footer={productDialogFooter}
                onHide={hideDialog}
                header="Assign Student(s) to Group"
            >
                <div className="dialog-content grid justify-content-center row">
                    {/* <div className="col-3 flex justify-content-center align-items-center">
                        <i className="pi pi-users text-8xl"></i>
                    </div> */}
                    <div className="col-9">
                        {/* <h2 className="text-center">Assign Student(s) to Group</h2> */}
                        <p className="text-center">Please select the assigned group</p>
                    </div>
                </div>
                <div className="field flex justify-content-center align-items-center">
                    <div className="w-full mx-3">
                        <MultiSelect
                            name="group"
                            options={groupOptions.map((group) => ({
                                label: group.groupName,
                                value: group.groupName,
                            }))}
                            value={formData.group}
                            onChange={handleChange}
                            placeholder="Select group"
                            className="col-12 "
                        />

                        <div className='text-center mt-4'>{formData.teacher}</div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={openDialogBox} style={{ width: '32rem', fontSize: '1.5rem' }}
                breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                header="Send Invitation" modal footer={openDialogFooter}
                onHide={hideOpenDialog}
            >
                <div className="confirmation-content flex text-center">
                    {selectedUser && (
                        <span style={{ fontSize: '1.2rem' }}>
                            Are you sure you want to Send an invitation to <b>{selectedUser}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog
                visible={showBulkInvitationDialog}
                style={{ width: '30rem', fontSize: '1.5rem' }}
                onHide={showDialog}
                footer={bulkInvitationDialogFooter}
                header="Send Invitation"
            >
                <div className="confirmation-content flex text-center">
                    <span style={{ fontSize: '1.2rem' }}>
                        Are you sure you want to send an invitations to selected users?
                    </span>
                </div>
            </Dialog>

            <Dialog
                visible={showDeleteDialog}
                style={{ width: '30rem', fontSize: '1.5rem' }}
                onHide={showDialog}
                footer={deleteDialogFooter}
                header="Delete User"
            >
                <div className="confirmation-content flex text-center">
                    <span style={{ fontSize: '1.2rem' }}>
                        Are you sure you want to delete the user?
                    </span>
                </div>
            </Dialog>

        </div>
    );
}

export default Students;
