import React, { useEffect, useState } from 'react';
import './LaulamaBanner.scss';
import { Button } from 'primereact/button';
import Bannerimage from '../../../Assets/Home/Hands_header.jpg';
import Bannerimage2 from '../../../Assets/Home/Personal_Pathway.png';
import { useNavigate } from 'react-router-dom';

const bannerData = [
    {
        image: Bannerimage,
        heading: 'Empowering Schools & Students',
        content: 'We are working to innovate our underlying improvement system to create and systematize educational breakthroughs that transform schools for its leaders, educators, students and community.'
    },
    {
        image: Bannerimage2,
        heading: 'Personal Pathway',
        content: 'We are working with schools to move the education system forward through the creation of individualized education plans for ALL students.'
    }
];

const LaulamaBanner = () => {

    const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % bannerData.length);
        }, 6000);

        return () => clearInterval(interval);
    }, []);

    const currentBanner = bannerData[currentBannerIndex];

    const handleOurPurpose = () => {
        navigate("/aboutus")
    }

    const handlePersonalPathway = () => {
        navigate("/login")
    }

    return (
        <div className='banner-section'>
            <div className='banner-img'>
                <img src={currentBanner.image} alt='Bannerimage' />

                <div className='banner-content'>
                    <div className='content-head'>
                        {currentBanner.heading}
                    </div>

                    <div className='content-para'>
                        <p>{currentBanner.content}</p>
                    </div>

                    <div className='banner-button'>
                        <div className='banner-button'>
                            {currentBannerIndex === 0 && (
                                <Button label="Read our purpose" className="p-button-sm" onClick={handleOurPurpose}/>
                            )}
                            
                            {currentBannerIndex === 1 && (
                                <Button label="Go to personal pathway" className="p-button-sm" onClick={handlePersonalPathway} />
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </div>


    )
}

export default LaulamaBanner