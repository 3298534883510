import axios from 'axios';
import { Avatar } from 'primereact/avatar';
import React, { useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import maleAvatar from "../../Assets/Profile edit/Profile image/Default/Man.png";
import femaleAvatar from "../../Assets/Profile edit/Profile image/Default/Woman.png";
import { AUTHORIZATION_HEADERS, GET_RESUME_URL, STUDENT_INFO } from '../../Utils/StudentService';
import Spinner from '../Spinner/Gif-Loader';
import NavBar from '../StackNavBar/StackNavBar';
import coverImageDefault from '../../Assets/Profile edit/Background/Laulama.png';


const ViewProfile = () => {

    const [activeMenuItem, setActiveMenuItem] = useState("");
    const [userRole, setUserRole] = useState('');

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [getResume, setGetResume] = useState(null);
    const [headerBackground, setHeaderBackground] = useState(null); // Default background color



    useEffect(() => {
        const currentUrl = window.location.pathname;

        if (currentUrl.includes('/principal')) {
            handlePrincipalMenuClick(activeMenuItem);
            setUserRole("Principal")
        } else if (currentUrl.includes('/teacher')) {
            handleTeacherMenuClick(activeMenuItem);
            setUserRole("Teacher")
        }

    }, [activeMenuItem]);

    const handlePrincipalMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/principal/dashboard');
                break;
            case 'groups':
                navigate('/principal/groups');
                break;
            case 'students':
                navigate('/principal/students');
                break;
            default:
                break;
        }
    }

    const handleTeacherMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/teacher/dashboard');
                break;
            case 'students':
                navigate('/teacher/studentsList');
                break;
            default:
                break;
        }
    };


    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);
    };

    const [studentDetails, setStudentDetails] = useState({
        email: '',
        phone: '',
        dob: '',
        schoolName: '',
        grade: null,
        gpa: '',
        street: '',
        city: '',
        zipcode: ''
    });

    useEffect(() => {
        fetchDetails();
        fetchResume();
    }, []);

    const fetchDetails = () => {
        setLoading(true);
        axios
            .get(STUDENT_INFO + localStorage?.rowStudentId, { headers: AUTHORIZATION_HEADERS })

            .then(response => {
                const data = response.data;
                const dobTimestamp = data?.dob;
                let date;

                if (dobTimestamp && dobTimestamp !== 0) {
                    date = new Date(dobTimestamp);
                    console.log(date);
                } else {
                    date = null;
                }

                const formattedDob = date ? date.toLocaleDateString('en-US') : null;

                setStudentDetails({
                    email: data?.emailId,
                    phoneNumber: data?.phoneNumber,
                    dob: formattedDob,
                    schoolName: data?.schools?.schoolName,
                    grade: data?.groups[0]?.grade,
                    gpa: data?.highSchoolGap,
                    street: data?.address?.addressLine1,
                    city: data?.address?.city,
                    zipcode: data?.address?.postalCode,
                    firstName: data?.firstName,
                    lastName: data?.lastName,
                    registrationId: data?.registrationId,
                    gender: data?.gender,
                    profileAvatar: data?.profileImage,
                    profileAvatarBackground: data?.imageColourCode,
                });
                setLoading(false);
                if(data?.backgroundImage === null || data?.backgroundImage === undefined || data?.backgroundImage === ""){
                    setHeaderBackground(`url(${coverImageDefault})`);
                } else {
                setHeaderBackground(data?.backgroundImage);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    const fetchResume = () => {
        const userId = localStorage.getItem('rowStudentId');

        axios
            .get(GET_RESUME_URL + userId, { headers: AUTHORIZATION_HEADERS })
            .then((response) => {
                setGetResume(response.data);
            })
    }

    const getAvatarForGender = () => {
        if (studentDetails?.profileAvatar) {
            return <img src={studentDetails?.profileAvatar} alt="Profile Avatar" width={130} height={130}
                style={{
                    background: studentDetails?.profileAvatarBackground || '#B9C4E1',
                    borderRadius: '50%',
                    padding: '12px',
                }} />;
        } else {
            if (studentDetails?.gender === "Male" || studentDetails?.gender === "M") {
                return <img src={maleAvatar} alt="Male Avatar" width={130} height={130} style={{ background: '#B9C4E1', borderRadius: '50%', padding: '12px' }} />;
            } else if (studentDetails?.gender === "Female" || studentDetails?.gender === "F") {
                return <img src={femaleAvatar} alt="Female Avatar" width={130} height={130} style={{ background: '#B9C4E1', borderRadius: '50%', padding: '12px' }} />;
            } else {
                return <Avatar icon="pi pi-user" size="large" shape="circle" />;
            }
        }
    };

    return (
        <div style={{ marginTop: "150px" }}>
            <div className="spinner-section">
                {loading ? <Spinner /> : null}
            </div>

            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
            />

            <div className="backBtn pl-5">
                <div className=''>
                    <Link
                        to={'/' + sessionStorage.userType + "/studentinfo"}
                        style={{ textDecoration: "none", color: "#1575e5" }}
                        className='flex align-items-center'
                    >
                        <FaChevronLeft />
                        Back
                    </Link>
                </div>

            </div>
            <div className="student-profile pt-3">


                <div className="card">
                    <div className="header" style={{ background: headerBackground }}>
                        {getAvatarForGender()}
                        <div className="user-details">
                            <h1 className="name">{studentDetails.firstName} {studentDetails.lastName}</h1>
                            <h2 className="id">(ID:{studentDetails.registrationId})</h2>
                        </div>
                    </div>

                    <div className="forms-section">

                        <h2>Personal Information</h2>

                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <div>{studentDetails.email}</div>
                            </div>
                            <div className="form-group">
                                <label>Phone Number</label>
                                <div>{studentDetails.phoneNumber}</div>

                            </div>

                            {/* <div className="form-group">
                                <label htmlFor="dob">Date of Birth</label>
                                <div>{studentDetails.dob}</div>
                            </div> */}
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="schoolName">School Name</label>
                                <div>{studentDetails.schoolName}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="grade">Grade</label>
                                <div>{studentDetails.grade}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="gpa">High School GPA</label>
                                <div>{studentDetails.gpa}</div>
                            </div>
                        </div>

                        <h2 className="address-header">Address</h2>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="street">Street</label>
                                <div>{studentDetails.street}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="city">City</label>
                                <div>{studentDetails.city}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="zipcode">Zip Code</label>
                                <div>{studentDetails.zipcode}</div>
                            </div>
                        </div>
                    </div>

                    <div className="documents-section">
                        <h2>Documents</h2>
                        {/* <p>You can generate your resume using the provided design or you can upload your own.</p> */}

                        <div className="upload-section">
                            {getResume !== "resume not found !" ? (
                                <div className='show-resume flex gap-2'>
                                    <a href={`data:application/pdf;base64,${getResume}`} download={`${studentDetails.firstName} Resume.pdf`} style={{ textDecoration: "none", color: "#1067B8" }}>
                                        {`${studentDetails.firstName}_${studentDetails.lastName}_Resume.pdf`}
                                    </a>
                                </div>
                            ) : (
                                <div style={{ color: "gray" }}>
                                    Resume not uploaded.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewProfile;